import React from 'react';
import SubHeader from 'components/SubHeader';
import { ROUTES } from 'shared/constants/routes';

const Header = () => (
  <SubHeader
    title={ROUTES.CATALOG.TITLE}
    links={[
      {
        title: 'Home',
        to: '/',
      },
    ]}
    currentPageTitle={ROUTES.CATALOG.TITLE}
  />
);

export default Header;
