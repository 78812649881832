import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CloudinaryWidget from 'components/CloudinaryWidget';
import TextField from 'components/TextField';
import Switch from 'components/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { updateSection } from 'actions/webPages';
import ReviewsClone from 'pages/home/Reviews';
import {
  Card,
  CardTitle,
  Row,
  AddSectionButton,
  AddSectionHeader,
  SectionNavbar,
  TwoBoxWrapper,
} from 'styles/pages/web-pages';

const Reviews = ({ data }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.profile?.token);
  const [bannerData, setBannerData] = useState(null);

  useEffect(() => {
    if (data && data?.find((d) => d?.name === 'User Reviews')) {
      const d = data?.find((d) => d?.name === 'User Reviews');
      setBannerData(() => d);
    }
  }, [data]);

  const { components, navVisibility, name } = bannerData || {};
  const { Title = '', BackgroundColor = '', Cards = [] } = components || {};

  return (
    <Card>
      <div>
        <ReviewsClone data={bannerData || {}} />
      </div>

      <SectionNavbar>
        <CardTitle style={{ border: 'none' }}>{name}</CardTitle>
        <Switch
          handleChange={(e) =>
            setBannerData({
              ...bannerData,
              navVisibility: e.target.checked,
            })
          }
          checked={navVisibility || false}
        />
      </SectionNavbar>

      <TwoBoxWrapper>
        <TextField
          label='Title'
          size='small'
          value={Title}
          onChange={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                Title: v,
              },
            })
          }
        />
        <TextField
          label='Background Color'
          size='small'
          value={BackgroundColor}
          onChange={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                BackgroundColor: v,
              },
            })
          }
        />
      </TwoBoxWrapper>

      <CardTitle
        style={{
          marginTop: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        Cards Settings
        <AddIcon
          style={{ cursor: 'pointer' }}
          onClick={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                Cards: [
                  ...bannerData.components.Cards,
                  {
                    CardTitle: '',
                    CardImage: '',
                    CardSubTitle: '',
                    CardDescription: '',
                  },
                ],
              },
            })
          }
        />
      </CardTitle>
      {Cards?.map((r, i) => (
        <Row key={`card${i}`}>
          <TextField
            label='Name'
            size='small'
            value={r?.CardTitle}
            onChange={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Cards: bannerData.components.Cards?.map((b, j) =>
                    i === j ? { ...b, CardTitle: v } : b
                  ),
                },
              })
            }
          />
          <TextField
            label='Company Name'
            size='small'
            value={r?.CardSubTitle}
            onChange={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Cards: bannerData.components.Cards?.map((b, j) =>
                    i === j ? { ...b, CardSubTitle: v } : b
                  ),
                },
              })
            }
          />

          <TextField
            label='User Review'
            size='small'
            value={r?.CardDescription}
            onChange={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Cards: bannerData.components.Cards?.map((b, j) =>
                    i === j ? { ...b, CardDescription: v } : b
                  ),
                },
              })
            }
          />

          <CloudinaryWidget
            label='User Picture'
            value={r?.CardImage}
            setValue={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Cards: bannerData.components.Cards?.map((b, j) =>
                    i === j ? { ...b, CardImage: v } : b
                  ),
                },
              })
            }
            folderPath='Shirtly/Web_Pages/LandingPage/UserReviews'
          />
          <DeleteIcon
            style={{
              color: 'red',
              cursor: 'pointer',
            }}
            onClick={() =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Cards: bannerData.components.Cards?.filter((b, j) => i !== j),
                },
              })
            }
          />
        </Row>
      ))}

      <AddSectionHeader style={{ marginTop: 20 }}>
        <AddSectionButton
          title='Update'
          containerStyle={{
            backgroundColor: '#1594db',
            color: '#fff',
            fontWeight: 'bold',
          }}
          onClick={async () => {
            const payload = {
              ...bannerData,
              page: 'LandingPage',
            };

            await dispatch(updateSection({ data: payload, token }));
          }}
        />
      </AddSectionHeader>
    </Card>
  );
};

export default Reviews;
