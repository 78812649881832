import styled from 'styled-components';
import COLORS from 'shared/constants/colors';

const Container = styled.div`
  padding: 0 20px;
`;
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 15px;
  max-width: 500px;
  width: 100%;
`;
const Clear = styled.div`
  background-color: #1594db;
  color: #ffffff;
  border-radius: 3px;
  padding: 9px 13px;
`;
const FiltersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  flex-wrap: wrap;
  grid-gap: 15px;
`;
const Filter = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 15px;
  flex-wrap: wrap;
  h4 {
    margin: 0;
  }
  > div {
    display: flex;
    grid-gap: 15px;
    flex-wrap: wrap;
  }
`;
const FilterButton = styled.div`
  background-color: ${COLORS.WHITE};
  border-color: #c2c2c2;
  border: 1px solid #c2c2c2;
  padding: 8px 12px;
  transition: all 0.5s;
  border-radius: 3px;
  font-size: 14px;
  cursor: pointer;
  text-transform: capitalize;
  &.active,
  &:hover {
    background-color: ${COLORS.BLUE};
    color: ${COLORS.WHITE};
    border-color: ${COLORS.BLUE};
  }
`;

export {
  Container,
  SearchWrapper,
  Clear,
  FiltersWrapper,
  Filter,
  FilterButton,
};
