import styled from 'styled-components';
import Radio from '@material-ui/core/Radio';

const StyledRadio = styled(Radio)`
  &.MuiRadio-colorPrimary.Mui-checked {
    color: #1594db;
  }
`;

export { StyledRadio };
