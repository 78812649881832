import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import Tabs from 'components/Tabs';
import { UPDATE_MARKETPLACE, UPDATE_PAGE_INFO } from 'actions/sync-products';
import { updateField } from 'actions/common';
import ShopifyLogo from 'assets/images/shopify-logo.png';
import WooCommerceLogo from 'assets/images/woo-logo.png';
import {
  getShopifyProducts,
  getWooProducts,
  syncedProducts,
  getShopifyMoreProducts,
  getShopifyProductsCount,
  fetchSyncedProductsCount,
  getWooMoreProducts,
} from 'actions/products';
import ErrorModal from 'pages/my-stores/sync-products/ErrorModal';
import Products from 'pages/my-stores/sync-products/Products';
import { StyledTab } from 'styles/pages/my-stores/sync-products';

const ProductTabs = () => {
  const dispatch = useDispatch();
  const stores = useSelector((state) => state?.stores?.data);
  const email = useSelector((state) => state?.auth?.profile?.emailid);
  const syncProductsInfo = useSelector((state) => state?.syncProducts);
  const [products, setProducts] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [syncedProductsCount, setSyncedProductsCount] = useState(0);
  const [totalProductsCount, setTotalProductsCount] = useState(0);
  const [error, setError] = useState(false);

  useEffect(async () => {
    if (stores?.length > 0 && !products?.length) {
      const index = _.findIndex(stores, {
        MarketplaceType: syncProductsInfo?.marketPlaceType,
      });

      setSelectedTab(() => (index >= 0 ? index : 0));
      if (index >= 0 && syncProductsInfo?.pageInfo) {
        handleTabChange(null, index, true, syncProductsInfo?.pageInfo);
      } else {
        handleTabChange(null, index >= 0 ? index : 0);
      }
    }
  }, [dispatch, stores]);

  const fetchMoreProducts = async (id) => {
    const { MarketplaceType, ShopID } = stores[selectedTab] || {};
    dispatch(updateField(UPDATE_PAGE_INFO, { pageInfo: id }));

    let newProducts =
      MarketplaceType === 'Shopify'
        ? await dispatch(
            getShopifyMoreProducts({
              page_info: id,
              shopName: ShopID,
              user: email,
            })
          )
        : await dispatch(
            getWooMoreProducts({
              page_info: id,
              shopName: ShopID,
              user: email,
            })
          );

    const syncedP = await dispatch(
      syncedProducts({
        shopName: ShopID,
        emailid: email,
      })
    );

    if (!!syncedP?.length) {
      newProducts = newProducts?.map((p) => ({
        ...p,
        synced: !!syncedP?.find((s) => p?.id === s?.id),
      }));
    }

    setProducts(() => newProducts);
  };

  const handleTabChange = async (event, newValue, fetchMore = false) => {
    const { MarketplaceType, ShopID } = stores[newValue] || {};

    dispatch(
      updateField(UPDATE_MARKETPLACE, { marketPlaceType: MarketplaceType })
    );

    if (MarketplaceType === 'Shopify') {
      const count = await dispatch(
        getShopifyProductsCount({
          user: email,
          shopName: ShopID,
        })
      );

      if (count?.errors) {
        setError(true);
        return;
      }

      const syncedPCount = await dispatch(
        fetchSyncedProductsCount({
          user: email,
          shopName: ShopID,
        })
      );

      let products = fetchMore
        ? await dispatch(
            getShopifyMoreProducts({
              page_info: syncProductsInfo?.pageInfo,
              shopName: ShopID,
              user: email,
            })
          )
        : await dispatch(
            getShopifyProducts({
              user: email,
              shopName: ShopID,
            })
          );

      const syncedP = await dispatch(
        syncedProducts({
          shopName: ShopID,
          emailid: email,
        })
      );

      if (!!syncedP?.length && !!products?.length) {
        products = products?.map((p) => ({
          ...p,
          synced: !!syncedP?.find((s) => p?.id === s?.id),
        }));
      }

      setTotalProductsCount(() => count?.count);
      setSyncedProductsCount(() => syncedPCount?.count);

      setProducts(() => products);
    } else if (MarketplaceType === 'WooCommerce') {
      const syncedPCount = await dispatch(
        fetchSyncedProductsCount({
          user: email,
          shopName: ShopID,
        })
      );

      let products = fetchMore
        ? await dispatch(
            getWooMoreProducts({
              page_info: syncProductsInfo?.pageInfo,
              shopName: ShopID,
              user: email,
            })
          )
        : await dispatch(
            getWooProducts({
              user: email,
              shopName: ShopID,
            })
          );

      const syncedP = await dispatch(
        syncedProducts({
          shopName: ShopID,
          emailid: email,
        })
      );

      if (!!syncedP?.length) {
        products = products?.map((p) => ({
          ...p,
          synced: !!syncedP?.find((s) => p?.id === s?.id),
        }));
      }

      setSyncedProductsCount(() => syncedPCount?.count);
      setProducts(() => products);
    } else {
      setProducts(() => []);
    }

    setSelectedTab(newValue);
  };

  const getLabel = ({ MarketplaceType, ShopName }) =>
    MarketplaceType === 'Shopify' || MarketplaceType === 'WooCommerce' ? (
      <StyledTab>
        <img
          src={MarketplaceType === 'Shopify' ? ShopifyLogo : WooCommerceLogo}
          alt='shopify-logo'
        />
        <div>
          <div>{MarketplaceType}</div>
          <div style={{ fontSize: 12 }}>{ShopName}</div>
        </div>
      </StyledTab>
    ) : null;

  const tabs = stores?.map((s) => ({
    title: getLabel(s),
    children: (
      <Products
        products={products}
        totalProductsCount={totalProductsCount}
        syncedProductCount={syncedProductsCount}
        marketPlaceType={stores[selectedTab]?.MarketplaceType}
        shopName={stores[selectedTab]?.ShopID}
        fetchMoreProducts={async (id) => {
          fetchMoreProducts(id);
        }}
      />
    ),
  }));

  return (
    <>
      <Tabs
        tabs={tabs}
        onTabChange={(index) => handleTabChange(null, index)}
        parentActiveIndex={selectedTab}
        parentState={true}
      />
      {error && <ErrorModal open={error} setOpen={() => setError(false)} />}
    </>
  );
};

export default ProductTabs;
