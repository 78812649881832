import styled from 'styled-components';

export const Container = styled.div``;

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1000px;
`;

export const NewsCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 70px;
`;

export const Card = styled.div`
  cursor: pointer;
`;

export const CardImage = styled.img`
  width: 70%;
  display: block;
  margin: 0 auto;
`;

export const CardTitle = styled.h2`
  text-decoration: underline;
  color: #333333;
  margin: 5px 0;
  font-size: 16px;
`;
export const CardSubTitle = styled.h3`
  color: #98989b;
  margin: 0;
  font-size: 14px;
`;

export const Title = styled.h1`
  color: rgb(21, 148, 219);
  text-align: center;
  font-size: 20px;
  margin: 20px;
`;
