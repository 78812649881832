import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { searchIpArtFile, getIpUsers } from 'actions/user';
import SearchBar from 'components/SearchBar';
import TextField from '@material-ui/core/TextField';
import { Filter } from 'styles/pages/ip-program';

const IpProgramFilter = ({ reloadPage, setArtFiles, user, setUser }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    const getUsers = async () => {
      const data = await dispatch(getIpUsers());
      setUsers(data?.users);
    };

    if (!users) getUsers();
  }, [dispatch, users]);

  return (
    <Filter>
      <Autocomplete
        options={users}
        getOptionLabel={(option) => option.User}
        value={user}
        onChange={async (event, newValue) => {
          setUser(newValue);
        }}
        renderInput={(params) => <TextField {...params} label='User' />}
      />

      <SearchBar
        placeholder='Copyright / Trademark Number'
        fetchAllOrders={reloadPage}
        searchById={async (patentNumber) => {
          const payload = {
            emailId: auth?.profile?.emailid,
            patentNumber,
          };
          const response = await dispatch(searchIpArtFile(payload));

          setArtFiles(response);
        }}
      />
    </Filter>
  );
};

export default IpProgramFilter;
