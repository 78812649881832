import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { columns } from 'pages/sales-report/table/columns';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: '#1594DB',
    fontSize: 14,
    fontFamily: 'Inter600',
  },
}))(TableCell);

const OrderTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => (
          <StyledTableCell
            key={`${column.id}thead${index}`}
            align={column.align}
            style={{ minWidth: column.minWidth }}>
            {column.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default OrderTableHead;
