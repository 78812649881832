import styled from 'styled-components';
import COLORS from 'shared/constants/colors';
import { Link as ReactRouterLink } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

const Title = styled.h2`
  font-size: 36px;
  font-family: 'Inter800';
  margin: 0;
`;
const Container = styled.div`
  margin: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  button {
    background-color: #1594db;
    color: #fff;
    font-family: 'Inter600';
    text-transform: capitalize;
    box-shadow: none;
    border-radius: 6px;
    height: 48px;
    font-size: 16px;
    :hover {
      background-color: #1594db;
      color: #fff;
    }
  }
`;
const Wrapper = styled.div``;
const Link = styled(ReactRouterLink)`
  text-decoration: none;
  color: inherit;
  font-size: 13px;
`;
const CurrentPageTitle = styled.p`
  color: ${COLORS.DARK_BLUE};
  font-weight: 700;
  font-size: 13px;
`;

const Breadcrumb = styled(Breadcrumbs)`
  line-height: 0 !important;
`;

const Subtitle = styled.p`
  margin-top: 8px;
  font-family: 'Inter600';
`;

export {
  Container,
  Wrapper,
  Title,
  Breadcrumb,
  Link,
  CurrentPageTitle,
  Subtitle,
};
