import styled from 'styled-components';
import COLORS from 'shared/constants/colors';

const Container = styled.div`
  padding: 0 15px;
  background-color: ${COLORS.WHITE};
  color: inherit;
  padding: 15px 20px 20px 20px;
`;
const Row1 = styled.div`
  display: flex;
  margin-bottom: 15px;
  @media (max-width: 960px) {
    flex-direction: column;
    text-align: center;
  }
  h3 {
    font-size: 34px;
    font-weight: 100;
    margin: 5px 30px 15px 15px;
  }
`;
const Row2 = styled.div`
  padding: 0 15px;
  display: grid;
  grid-gap: 30px;
  max-width: 1070px;
  margin: 0 auto;

  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Description = styled.div`
  border-left: 2px solid #a7a9ac;
  padding-left: 30px;
  @media (max-width: 960px) {
    border: none;
  }
  p {
    margin: 0 0 3px 0;
    line-height: 16px;
  }
`;
const Store = styled.div`
  background-color: ${COLORS.GREEN};
  color: ${COLORS.WHITE};
  padding: 30px;
  border-radius: 15px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  h2 {
    margin-bottom: 20px;
    margin-top: 0;
    font-weight: 600;
    font-size: 24px;
  }
  button {
    &:hover {
      background-color: #18a689 !important;
    }
  }
`;
const Img = styled.img`
  margin-bottom: 20px;
  border: 6px solid #ffffff;
  border-radius: 50%;
  max-width: 125px;
  cursor: pointer;
`;
const Footer = styled.div`
  display: flex;
  grid-gap: 15px;
  justify-content: flex-end;
  margin-top: 30px;
  button {
    border-radius: 6px;
    font-size: 16px;
    padding: 10px 24px;
    margin: 0;
    text-transform: capitalize;
    cursor: pointer;
    font-family: 'Inter500';
    :first-child {
      color: #555;
      background-color: #efefef;
    }
    :nth-child(2) {
      color: #ffffff;
      background-color: #1594db;
    }
  }
`;
const ModalContainer = styled.div`
  padding: 20px;
  max-width: 480px;
  text-align: center;
  h2 {
    font-family: 'Inter700';
    color: #1594db;
    text-align: center;
  }
  p {
    font-family: 'Inter400';
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
  }
`;

export {
  Container,
  Row1,
  Row2,
  Description,
  Store,
  Img,
  ModalContainer,
  Footer,
};
