import styled from 'styled-components';
import COLORS from 'shared/constants/colors';

const Container = styled.div`
  padding: 0 15px;
  background-color: ${COLORS.LIGHT_GREY};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 0.25em;
  transition: all ease-out 0.25s;
  overflow: hidden;
  max-width: 100%;
  font-size: 1.15em;
  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    align-items: start;
  }
`;
const Colors = styled.div`
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(51px, 60px));
  margin-bottom: 1em;
  width: 100%;

  > div {
    width: 51px;
    height: 44px;
  }
`;

const ErrorHeading = styled.h1`
  color: ${COLORS.RED};
  text-align: center;
  margin: 50px;
`;

export { Container, Wrapper, Colors, ErrorHeading };
