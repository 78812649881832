import styled from 'styled-components';

const Container = styled.div`
  max-width: 823px;
  padding: 12px;
  width: 80vw;
`;

const Heading = styled.div`
  font-size: 14px;
  text-align: center;
  margin: 32px 0;
  color: #000;
`;

const BasicOrder = styled.div`
  text-align: center;
  padding: 12px;
  max-width: 438px;
  width: 100%;
  margin: 0 auto;
  /* border: 1px solid #eee; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* border-radius: 4px; */
  /* box-shadow: 20px 20px 40px #d9d9d9, -20px -20px 25px #fff; */
  align-items: center;
  transition: all ease-out 0.25s;
  cursor: pointer;
  /* h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 5px 0 10px;
  } */

  /* p {
    font-size: 15px;
    margin-top: 0;
  } */
`;
const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    max-width: 180px;
  }
`;
const CreateOrderButton = styled.div`
  button {
    background-color: #1594db;
    width: 300px;
    color: #fff;
    font-size: 16px;
    box-shadow: none;
    height: 48px;
    padding: 0;
    text-transform: capitalize;
    font-family: 'Inter500';
    max-width: 165px;
    box-shadow: none;
    :hover {
      background-color: #1594db;
      box-shadow: none;
    }
  }
  @media (max-width: 650px) {
    width: 100%;
    max-width: 100%;
    button {
      width: 100%;
      max-width: 100%;
    }
  }
`;
const BulkOrder = styled.p`
  text-align: center;
  font-size: 14px;
  font-family: 'Inter500';
  color: #000;
`;
export {
  Heading,
  Container,
  BasicOrder,
  CreateOrderButton,
  ImageWrapper,
  BulkOrder,
};
