import styled from 'styled-components';
import CloseIcon from 'assets/svg/CloseIcon';

const Container = styled.div`
  height: auto;
  width: 100vw;
  max-width: 100%;
  transition: all ease-out 0.25s;
  position: relative;
  padding: 12px;
  background: #ffffff;
  background-clip: padding-box;
  @media (min-width: 768px) {
    width: 80vw;
  }
`;
const Header = styled.div`
  position: relative;
  h2 {
    font-size: 30px;
    font-weight: 100;
    font-family: 'Inter800';
    margin-top: 12px;
  }

  h3 {
    font-size: 20px;
    font-weight: 100;
    font-family: 'Inter800';
    margin-top: 12px;
  }
`;
const CloseIconStyle = styled(CloseIcon)`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;
const SearchWrapper = styled.div`
  display: flex;
  grid-gap: 15px;
  margin-bottom: 25px;
`;
const ArtworksLibrary = styled.div`
  margin-top: 20px;
  min-height: 150px;
  max-height: ${(props) => (props?.scroll ? 'calc(100vh - 352px)' : 'auto')};
  overflow-y: auto;
  > div {
    display: grid;
    align-items: start;
    transition: all ease-out 0.25s;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    @media (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (min-width: 1100px) {
      grid-template-columns: repeat(8, 1fr);
    }
  }
`;

const Procedure = styled.span`
  color: rgb(34, 128, 193);
  text-decoration: underline;
  cursor: pointer;
`;

const ShirtlyCharges = styled.span`
  color: rgb(34, 128, 193);
  float: right;
`;

const Support = styled.a`
  color: rgb(34, 128, 193);
  text-decoration: underline;
  cursor: pointer;
`;

const FontPickerWrapper = styled.div`
  width: 100%;
  #font-picker {
    width: 100%;
    border: 1px solid #ccc;
    box-shadow: none;
    border-radius: 5px;
    & > button {
      background: transparent;
      height: 55px;
      font-size: 20px;
    }
    & > ul {
      z-index: 9;
      background: white;
      top: 58px;
      overflow: auto;
      li {
        height: auto;
        border-bottom: 1px solid #ccc;
        font-size: 22px;

        button {
          padding: 10px;
        }
      }
    }
  }
`;

const HexColorPickerWrapper = styled.div`
  margin: 30px 0px;
  .react-colorful {
    display: block;
    margin: 0 auto;
    .react-colorful__saturation {
      height: 185px;
    }
  }
`;

const ChipsTitle = styled.h4``;
const Chips = styled.div``;
const ChipsWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 10px;
`;

const chipStyle = {
  backgroundColor: '#1594db',
  color: '#fff',
  height: 26,
  marginRight: 5,
};

const LayersContainer = styled.div`
  width: 400px;
  padding: 20px;
`;

const LayersWrapper = styled.div`
  border-top: 1px solid #ccc;
`;

const Layer = styled.div`
  display: flex;

  img {
    background-image: url(https://thumbs.dreamstime.com/b/imitation-transparent-background-seamless-vector-illustration-69028332.jpg);
    background-size: 400%;
    background-repeat: no-repeat;
  }
`;

const LayerActions = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  svg {
    cursor: pointer;
    font-size: 16px;
  }
`;

const TextLayer = styled.div`
  background-image: url(https://thumbs.dreamstime.com/b/imitation-transparent-background-seamless-vector-illustration-69028332.jpg);
  background-size: 400%;
  background-repeat: no-repeat;
  min-width: 60px;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LayerCenter = styled.div`
  display: flex;
  flex: 1;
  margin: 0 10px;
  flex-direction: column;
  align-items: flex-start;
`;

const AlignIcons = styled.div`
  display: flex;
  grid-gap: 10px;

  svg {
    border: 1px solid #ccc;
    padding: 5px;
    color: #a1a1a1;
    font-size: 26px;
  }
`;

const UpperLayer = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-top: 10px;
  border-left: 2px solid transparent;
  cursor: pointer;
`;

const Rotate = styled.div`
  display: flex;
  grid-gap: 20px;
  justify-content: center;
  align-items: center;

  .MuiSlider-root,
  .MuiSlider-thumb {
    color: #1594db;
  }
`;

const OutOfBondWarning = styled.div`
  background: orange;
  padding: 5px 0;
  text-align: center;
  color: white;
  font-weight: bold;
`;

const OutOfBondRed = styled.div`
  background: red;
  padding: 5px 0;
  text-align: center;
  color: white;
  font-weight: bold;
`;

const PreviewText = styled.div`
  background-image: url(https://thumbs.dreamstime.com/b/imitation-transparent-background-seamless-vector-illustration-69028332.jpg);
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 10px;
  width: 100%;
  background-size: 100%;
`;

const PaginationWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
`;

const CustomGraphicWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export {
  Container,
  Header,
  CloseIconStyle,
  SearchWrapper,
  ArtworksLibrary,
  Procedure,
  Support,
  ShirtlyCharges,
  FontPickerWrapper,
  HexColorPickerWrapper,
  ChipsWrapper,
  ChipsTitle,
  Chips,
  chipStyle,
  LayersContainer,
  LayersWrapper,
  Layer,
  LayerActions,
  TextLayer,
  LayerCenter,
  AlignIcons,
  UpperLayer,
  Rotate,
  OutOfBondWarning,
  OutOfBondRed,
  PreviewText,
  PaginationWrapper,
  CustomGraphicWrapper,
};
