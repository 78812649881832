import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import AddDashboardSubSectionDialog from 'pages/web-pages/AddDashboardSubSectionDialog';
import {
  updateDashboardSection,
  deleteDashboardSection,
} from 'actions/webPages';
import {
  DashboardSectionContainer,
  Section,
  SectionHeader,
  SectionBody,
  AddSubSection,
  SubSectionWrapper,
  StyledPaper,
} from 'styles/pages/web-pages';

const DashboardSection = ({ sections, refresh, isAdmin = true }) => {
  const dispatch = useDispatch();
  const [selectedSection, setSelectedSection] = useState(false);
  const [editSubSection, setEditSubSection] = useState(null);

  return sections?.length ? (
    <>
      <DashboardSectionContainer>
        {sections.map((section) => (
          <StyledPaper key={section._id} isAdmin={isAdmin}>
            <Section isAdmin={isAdmin}>
              <SectionHeader isAdmin={isAdmin}>
                <h2>{section?.sectionName}</h2>
                {isAdmin && (
                  <DeleteIcon
                    onClick={async () => {
                      await dispatch(
                        deleteDashboardSection({
                          sectionName: section?.sectionName,
                        })
                      );
                      refresh();
                    }}
                  />
                )}
              </SectionHeader>
              <SectionBody>
                {section?.subSectionsData?.map((subSection, i) => (
                  <Tooltip title={subSection?.subSectionName} arrow={true}>
                    <SubSectionWrapper
                      onClick={() => {
                        if (!isAdmin) {
                          window.open(subSection?.subSectionLink);
                        }
                      }}
                    >
                      <Paper key={subSection?.subSectionLink} elevation={8}>
                        {isAdmin && (
                          <EditIcon
                            className="edit-icon"
                            onClick={() => {
                              setEditSubSection({ ...subSection, index: i });
                              setSelectedSection(section);
                            }}
                          />
                        )}
                        {isAdmin && (
                          <DeleteIcon
                            className="delete-icon"
                            onClick={async () => {
                              const payload = {
                                sectionName: section?.sectionName,
                                subSectionsData:
                                  section?.subSectionsData?.filter(
                                    (_, j) => i !== j
                                  ),
                              };
                              await dispatch(updateDashboardSection(payload));
                              refresh();
                            }}
                          />
                        )}
                        {subSection?.subSectionImage && (
                          <div
                            className="img"
                            style={{
                              backgroundImage: `url(${subSection?.subSectionImage})`,
                            }}
                          ></div>
                        )}
                        <p>{subSection?.subSectionName}</p>
                      </Paper>
                    </SubSectionWrapper>
                  </Tooltip>
                ))}
                {isAdmin && (
                  <AddSubSection onClick={() => setSelectedSection(section)}>
                    <AddIcon />
                  </AddSubSection>
                )}
              </SectionBody>
            </Section>
          </StyledPaper>
        ))}
      </DashboardSectionContainer>
      {!!selectedSection && (
        <AddDashboardSubSectionDialog
          data={editSubSection}
          open={!!selectedSection}
          setOpen={() => setSelectedSection(false)}
          addSubSection={async (data) => {
            const subSectionsData = selectedSection?.subSectionsData || [];

            if (data?.index >= 0) {
              const i = data.index;
              delete data.index;
              subSectionsData[i] = data;
            } else {
              subSectionsData.push(data);
            }

            const payload = {
              sectionName: selectedSection?.sectionName,
              subSectionsData,
            };
            await dispatch(updateDashboardSection(payload));
            setSelectedSection(false);
            setEditSubSection(null);
            refresh();
          }}
        />
      )}
    </>
  ) : null;
};

export default DashboardSection;
