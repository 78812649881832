import React from 'react';
import ProductPricing from 'pages/web-pages/ProductPricing';
import Banner from 'pages/web-pages/Banner';
import WhatsNew from 'pages/web-pages/WhatsNew';
import HowItWorks from 'pages/web-pages/HowItWorks';
import KeyFeatures from 'pages/web-pages/KeyFeatures';
import Fulfillment from 'pages/web-pages/Fulfillment';
import Header from 'pages/web-pages/Header';
import Reviews from 'pages/web-pages/Reviews';
import Footer from 'pages/web-pages/Footer';
import {
  AddSectionContainer,
  AddSectionHeader,
  AddSectionButton,
} from 'styles/pages/web-pages';

const AddSection = ({ data }) => (
  <AddSectionContainer>
    <AddSectionHeader>
      <AddSectionButton
        title='Add Section'
        containerStyle={{
          backgroundColor: '#1594db',
          color: '#fff',
          fontWeight: 'bold',
          opacity: 0.5,
        }}
      />
    </AddSectionHeader>
    <Header data={data} />
    <Banner data={data} />
    <ProductPricing data={data} />
    <WhatsNew data={data} />
    <HowItWorks data={data} />
    <KeyFeatures data={data} />
    <Fulfillment data={data} />
    {/* <Profit data={data} /> */}
    <Reviews data={data} />
    <Footer data={data} />
  </AddSectionContainer>
);

export default AddSection;
