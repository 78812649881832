import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { useDispatch } from 'react-redux';
import { getWebPage } from 'actions/webPages';
import Header from 'pages/home/Header';
import Footer from 'pages/home/Footer';
import { Container } from 'styles/pages/home';

const Terms = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);

  useEffect(() => {
    const getWebPageData = async () => {
      const data = await dispatch(getWebPage({ page: 'LandingPage' }));
      setData(() => data);
    };

    getWebPageData();
  }, []);

  return (
    <Container>
      <Header
        showNavMenu={false}
        data={data?.find((d) => d?.name === 'Header')}
      />
      <Paper elevation={3} style={{ margin: '20px 20%', padding: 20 }}>
        <h1
          style={{
            textAlign: 'center',
            color: '#1594db',
            fontFamily: 'Inter800',
          }}
        >
          Terms & Services / Privacy Policy
        </h1>
        Last Modified: Feb 22, 2021
        <br />
        <br />
        Acceptance of the Terms of Use
        <br />
        <br />
        These terms of use are entered into by and between You and Shirtly
        (“Company,” “we” or “us”). The following terms and conditions, together
        with any documents they expressly incorporated by reference
        (collectively, these “Terms of Use”), govern your access to and use of,
        including any content, functionality and services offered on or through
        www.shirtly.com (the “Website”), whether as a guest or a registered
        user.
        <br />
        <br />
        Please read the Terms of Use carefully before you start to use the
        Website. By using the Website or by clicking to accept or agree to the
        Terms of Use when this option is made available to you, you accept and
        agree to be bound and abide by these Terms of Use and our Privacy
        Policy, found at www.shirtly.com incorporated herein by reference. If
        you do not want to agree to these Terms of Use or the Privacy Policy,
        you must not access or use the Website.
        <br />
        <br />
        This Website is offered and available to users who are 18 years of age
        or older. By using this Website, you represent and warrant that you are
        of legal age to form a binding contract with the Company and meet all of
        the foregoing eligibility requirements. If you do not meet all of these
        requirements, you must not access or use the Website.
        <br />
        <br />
        Changes to the Terms of Use
        <br />
        <br />
        We may revise and update these Terms of Use from time to time in our
        sole discretion. All changes are effective immediately when we post
        them, and apply to all access to and use of the Website thereafter.
        However, any changes to the dispute resolution provisions set forth in
        Governing Law and Jurisdiction will not apply to any disputes for which
        the parties have actual notice on or prior to the date the change is
        posted on the Website.
        <br />
        <br />
        Your continued use of the Website following the posting of revised Terms
        of Use means that you accept and agree to the changes. You are expected
        to check this page frequently so you are aware of any changes, as they
        are binding on you.
        <br />
        <br />
        Accessing the Website and Account Security
        <br />
        <br />
        We reserve the right to withdraw or amend this Website, and any service
        or material we provide on the Website, in our sole discretion without
        notice. We will not be liable if for any reason all or any part of the
        Website is unavailable at any time or for any period. From time to time,
        we may restrict access to some parts of the Website, or the entire
        Website, to users, including registered users.
        <br />
        <br />
        You are responsible for:
        <br />
        <br />
        Making all arrangements necessary for you to have access to the Website.
        <br />
        <br />
        Ensuring that all persons who access the Website through your internet
        connection are aware of these Terms of Use and comply with them.
        <br />
        <br />
        To access the Website or some of the resources it offers, you may be
        asked to provide certain registration details or other information. It
        is a condition of your use of the Website that all the information you
        provide on the Website is correct, current, and complete. You agree that
        all information you provide to register with this Website or otherwise,
        including but not limited to through the use of any interactive features
        on the Website, is governed by our Privacy Policy, and you consent to
        all actions we take with respect to your information consistent with our
        Privacy Policy.
        <br />
        <br />
        If you choose, or are provided with, a user name, password or any other
        piece of information as part of our security procedures, you must treat
        such information as confidential, and you must not disclose it to any
        other person or entity. You also acknowledge that your account is
        personal to you and agree not to provide any other person with access to
        this Website or portions of it using your user name, password or other
        security information. You agree to notify us immediately of any
        unauthorized access to or use of your user name or password or any other
        breach of security. You also agree to ensure that you exit from your
        account at the end of each session. You should use particular caution
        when accessing your account from a public or shared computer so that
        others are not able to view or record your password or other personal
        information.
        <br />
        <br />
        We have the right to disable any user name, password or other
        identifier, whether chosen by you or provided by us, at any time in our
        sole discretion for any or no reason, including if, in our opinion, you
        have violated any provision of these Terms of Use.
        <br />
        <br />
        Intellectual Property Rights
        <br />
        <br />
        The Website and its entire contents, features and functionality
        (including but not limited to all information, software, text, displays,
        images, video and audio, and the design, selection and arrangement
        thereof), are owned by the Company, its licensors or other providers of
        such material and are protected by United States and international
        copyright, trademark, patent, trade secret and other intellectual
        property or proprietary rights laws.
        <br />
        <br />
        These Terms of Use permit you to use the Website for your personal
        fulfillment services only. You must not reproduce, distribute, modify,
        create derivative works of, publicly display, publicly perform,
        republish, download, store or transmit any of the material on our
        Website, except as follows:
        <br />
        <br />
        Your computer may temporarily store copies of such materials in RAM
        incidental to your accessing and viewing those materials.
        <br />
        <br />
        You may store files that are automatically cached by your Web browser
        for display enhancement purposes.
        <br />
        <br />
        You may print or download one copy of a reasonable number of pages of
        the Website for your own personal, non-commercial use and not for
        further reproduction, publication or distribution.
        <br />
        <br />
        If we provide desktop, mobile or other applications for download, you
        may download a single copy to your computer or mobile device solely for
        your own personal, non-commercial use, provided you agree to be bound by
        our End User License Agreement for such applications.
        <br />
        <br />
        You must not:
        <br />
        <br />
        Modify copies of any materials from this site.
        <br />
        <br />
        Use any illustrations, photographs, video or audio sequences or any
        Delete or alter any copyright, trademark, or other proprietary rights
        notices from copies of materials from this site.
        <br />
        <br />
        All images, artwork, and fonts accessed through the Website’s art and
        font libraries are owned by us. They are available for your use only in
        conjunction with the Website’s fulfillment services. You do not accrete
        any rights to any images, artwork, or fonts accessed from a Website
        library, or any derivations thereof.
        <br />
        <br />
        If you print, copy, modify, download or otherwise use or provide any
        other person with access to any part of the Website in breach of the
        Terms of Use, your right to use the Website will cease immediately and
        you must, at our option, return or destroy any copies of the materials
        you have made. No right, title, or interest in or to the Website or any
        content on the Website is transferred to you, and all rights not
        expressly granted are reserved by the Company. Any use of the Website
        not expressly permitted by these Terms of Use is a breach of these Terms
        of Use and may violate copyright, trademark, and other laws.
        <br />
        <br />
        Trademarks
        <br />
        <br />
        The Company name, the term Shirtly, the Company logo, and all related
        names, logos, product and service names, designs and slogans are
        trademarks of the Company or its affiliates or licensors. You must not
        use such marks without the prior written permission of the Company. All
        other names, logos, product and service names, designs and slogans on
        this Website are the trademarks of their respective owners.
        <br />
        <br />
        Prohibited Uses
        <br />
        <br />
        You may use the Website only for lawful purposes and in accordance with
        these Terms of Use. You agree not to use the Website:
        <br />
        <br />
        To sell merchandise that infringes upon third party intellectual
        property rights (such as copyright, trademark, trade dress, and right
        <br />
        of publicity). <br />
        In any way that violates any applicable federal, state, local or
        international law or regulation (including, without limitation, any laws
        regarding the export of data or software to and from the U.S. or other
        countries).
        <br />
        <br />
        For the purpose of exploiting, harming, or attempting to exploit or harm
        minors in any way by exposing them to inappropriate content, asking for
        personally identifiable information or otherwise.
        <br />
        <br />
        To send, knowingly receive, upload, download, use or re-use any material
        which does not comply with the Content Standards set out in these Terms
        of Use.
        <br />
        <br />
        To transmit, or procure the sending of, any advertising or promotional
        material without our prior written consent, including any “junk mail”,
        “chain letter” or “spam” or any other similar solicitation.
        <br />
        <br />
        To impersonate or attempt to impersonate the Company, a Company
        employee, another user or any other person or entity (including, without
        limitation, by using e-mail addresses or screen names associated with
        any of the foregoing).
        <br />
        <br />
        To engage in any other conduct that restricts or inhibits anyone’s use
        or enjoyment of the Website, or which, as determined by us, may harm the
        Company or users of the Website or expose them to liability.
        <br />
        <br />
        Additionally, you agree not to:
        <br />
        <br />
        Use the Website in any manner that could disable, overburden, damage, or
        impair the site or interfere with any other party’s use of the Website,
        including their ability to engage in real time activities through the
        Website.
        <br />
        <br />
        Use any robot, spider or other automatic device, process or means to
        access the Website for any purpose, including monitoring or copying any
        of the material on the Website.
        <br />
        <br />
        Use any manual process to monitor or copy any of the material on the
        Website or for any other unauthorized purpose without our prior written
        consent.
        <br />
        <br />
        Use any device, software, or routine that interferes with the proper
        working of the Website.
        <br />
        <br />
        Introduce any viruses, trojan horses, worms, logic bombs or other
        material which is malicious or technologically harmful.
        <br />
        <br />
        Attempt to gain unauthorized access to, interfere with, damage or
        disrupt any parts of the Website, the server on which the Website is
        stored, or any server, computer or database connected to the Website.
        <br />
        <br />
        Attack the Website via a denial-of-service attack or a distributed
        denial-of-service attack.
        <br />
        <br />
        Otherwise attempt to interfere with the proper working of the Website.
        <br />
        <br />
        User Contributions
        <br />
        <br />
        The Website contains message boards, chat rooms, personal web pages or
        profiles, forums, bulletin boards and other interactive features
        (collectively, “Interactive Services”) that allow users to post, submit,
        publish, display, upload, or transmit (hereinafter, “Post”) content or
        materials (collectively, “User Contributions”) on or through the
        Website.
        <br />
        <br />
        All User Contributions must comply with the Content Standards set out in
        these Terms of Use.
        <br />
        <br />
        Any User Contribution you Post to the site will be considered
        non-confidential and non-proprietary. By providing any User Contribution
        on the Website, you grant us and our affiliates and service providers,
        and each of their and our respective licensees, successors and assigns
        the right to use, reproduce, modify, perform, display, distribute and
        otherwise disclose to third parties any such material for any purpose.
        <br />
        <br />
        You represent and warrant that:
        <br />
        <br />
        You own or control all rights in and to the User Contributions and have
        the right to grant the license granted above to us and our affiliates
        and service providers, and each of their and our respective licensees,
        successors and assigns. If you are not the owner, you agree to provide
        us with evidence of the permission given to you by the owner.
        <br />
        <br />
        All of your User Contributions do and will comply with these Terms of
        Use.
        <br />
        <br />
        You understand and acknowledge that you are responsible for any User
        Contributions you submit or contribute, and you, not the Company, have
        full responsibility for such content, including its legality,
        reliability, accuracy and appropriateness.
        <br />
        <br />
        We are not responsible, or liable to any third party, for the content or
        accuracy of any User Contributions posted by you or any other user of
        the Website.
        <br />
        <br />
        Monitoring and Enforcement; Termination
        <br />
        <br />
        We have the right to:
        <br />
        <br />
        Remove or refuse to post any User Contributions for any or no reason in
        our sole discretion.
        <br />
        <br />
        Take any action with respect to any User Contribution that we deem
        necessary or appropriate in our sole discretion, including if we believe
        that such User Contribution violates the Terms of Use, including the
        Content Standards, infringes any intellectual property right or other
        right of any person or entity, threatens the personal safety of users of
        the Website or the public or could create liability for the Company.
        <br />
        <br />
        Disclose your identity or other information about you to any third party
        who claims that material posted by you violates their rights, including
        their intellectual property rights or their right to privacy.
        <br />
        <br />
        Take appropriate legal action, including without limitation, referral to
        law enforcement, for any illegal or unauthorized use of the Website.
        <br />
        <br />
        Terminate or suspend your access to all or part of the Website for any
        violation of these Terms of Use.
        <br />
        <br />
        Without limiting the foregoing, we have the right to fully cooperate
        with any law enforcement authorities or court order requesting or
        directing us to disclose the identity or other information of anyone
        posting any materials on or through the Website. YOU WAIVE AND HOLD
        HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS
        FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING
        PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS
        TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW
        ENFORCEMENT AUTHORITIES.
        <br />
        <br />
        However, we do not undertake to review all material before it is posted
        on the Website, and cannot ensure prompt removal of objectionable
        material after it has been posted. Accordingly, we assume no liability
        for any action or inaction regarding transmissions, communications or
        content provided by any user or third party. We have no liability or
        responsibility to anyone for performance or nonperformance of the
        activities described in this section.
        <br />
        <br />
        Content Standards
        <br />
        <br />
        These content standards apply to any and all User Contributions and use
        of Interactive Services. User Contributions must in their entirety
        comply with all applicable federal, state, local and international laws
        and regulations. Without limiting the foregoing, User Contributions must
        not:
        <br />
        Contain any material which is defamatory, obscene, indecent, abusive,
        offensive, harassing, violent, hateful, inflammatory or otherwise
        objectionable.
        <br />
        Promote sexually explicit or pornographic material, violence, or
        discrimination based on race, sex, religion, nationality, disability,
        sexual orientation or age.
        <br />
        Infringe any patent, trademark, trade secret, copyright, or other
        intellectual property or other rights of any other person.
        <br />
        Violate the legal rights (including the rights of publicity and privacy)
        of others or contain any material that could give rise to any civil or
        criminal liability under applicable laws or regulations or that
        otherwise may be in conflict with these Terms of Use and our Privacy
        Policy.
        <br />
        Promote any illegal activity, or advocate, promote or assist any
        unlawful act.
        <br />
        Cause annoyance, inconvenience or needless anxiety or be likely to
        upset, embarrass, alarm or annoy any other person.
        <br />
        Impersonate any person, or misrepresent your identity or affiliation
        with any person or organization.
        <br />
        Give the impression that they emanate from or are endorsed by us or any
        other person or entity, if this is not the case.
        <br />
        Copyright and Trademark Infringement
        <br />
        If you believe that any User Contributions violate your copyright or
        trademark, please send an email to support@shirtly.com with the
        following: (a) your physical or electronic signature; (b) identification
        of the copyrighted/trademark work you believe to have been infringed or,
        if the claim involves multiple works on the Website, a representative
        list of such works; (c) identification of the material you believe to be
        infringing in a sufficiently precise manner to allow us to locate that
        material; (d) adequate information by which we can contact you
        (including your name, postal address, telephone number, and, if
        available, email address); (e) a statement that you have a good faith
        belief that use of the copyrighted material is not authorized by the
        copyright owner, its agent, or the law; (f) a statement that the
        information in the written notice is accurate; and (g) a statement,
        under penalty of perjury, that you are authorized to act on behalf of
        the copyright owner.
        <br />
        It is the policy of the Company to terminate the user accounts of repeat
        infringers.
        <br />
        Reliance on Information Posted
        <br />
        The information presented on or through the Website is made available
        solely for general information purposes. We do not warrant the accuracy,
        completeness or usefulness of this information. Any reliance you place
        on such information is strictly at your own risk. We disclaim all
        liability and responsibility arising from any reliance placed on such
        materials by you or any other visitor to the Website, or by anyone who
        may be informed of any of its contents.
        <br />
        This Website includes content provided by third parties, including
        materials provided by other users, bloggers and third-party licensors,
        syndicators, aggregators or reporting services. All statements or
        opinions expressed in these materials, and all articles and responses to
        questions and other content, other than the content provided by the
        Company, are solely the opinions and the responsibility of the person or
        entity providing those materials. These materials do not necessarily
        reflect the opinion of the Company. We are not responsible, or liable to
        you or any third party, for the content or accuracy of any materials
        provided by any third parties.
        <br />
        Changes to the Website
        <br />
        We may update the content on this Website from time to time, but its
        content is not necessarily complete or up-to-date. Any of the material
        on the Website may be out of date at any given time, and we are under no
        obligation to update such material.
        <br />
        Information About You and Your Visits to the Website
        <br />
        All information we collect on this Website is subject to our Privacy
        Policy. By using the Website, you consent to all actions taken by us
        with respect to your information in compliance with the Privacy Policy.
        <br />
        Online Purchases and Other Terms and Conditions
        <br />
        You agree that any order you place through the Website or our
        Application (as that term is defined in our End User License Agreement,
        which is incorporated herein by reference) is an offer to buy, under
        these Terms, all products listed in your order. All orders must be
        accepted by us or we will not be obligated to sell the products to you.
        We may choose not to accept any orders in our sole discretion. In
        purchasing goods through this Website, you agree to comply with all
        applicable laws and regulations of the various states and of the United
        States, including all export regulations.
        <br />
        All prices posted on this Website are subject to change without notice.
        Posted prices do not include taxes or charges for shipping and handling.
        We are not responsible for pricing, typographical or other errors in any
        offer by us and we reserve the right to cancel any orders arising from
        such errors.
        <br />
        WE WARRANT THAT DURING THE WARRANTY PERIOD, THE PRODUCTS PURCHASED FROM
        THE SITE WILL BE FREE FROM DEFECTS IN MATERIALS AND WORKMANSHIP. WE
        LIMIT THE DURATION AND REMEDIES OF ALL IMPLIED WARRANTIES, INCLUDING
        WITHOUT LIMITATION THE WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
        PARTICULAR PURPOSE, TO THE DURATION OF THIS LIMITED WARRANTY. SOME
        STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS,
        SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU. OUR RESPONSIBILITY FOR
        DEFECTIVE PRODUCTS IS LIMITED TO REPAIR, REPLACEMENT, OR REFUND AS SET
        FORTH IN THE WARRANTY STATEMENT BELOW.
        <br />
        We offer a limited warranty that starts on the date of your purchase and
        lasts for thirty (30) days (“Warranty Period”). With respect to any
        defective products during the Warranty Period, we will, in our sole
        discretion, either: (i) replace such products free of charge or (ii)
        refund the purchase price of such products. We will also pay for
        shipping and handling fees to return the replacement product to you if
        we elect to replace the defective products. To obtain warranty service,
        you must e-mail our Customer Service Department at support@shirtly.com
        during the Warranty Period.
        <br />
        THE REMEDIES DESCRIBED ABOVE ARE YOUR SOLE AND EXCLUSIVE REMEDIES AND
        OUR ENTIRE OBLIGATION AND LIABILITY FOR ANY BREACH OF THIS LIMITED
        WARRANTY. OUR LIABILITY WILL UNDER NO CIRCUMSTANCES EXCEED THE ACTUAL
        AMOUNT PAID BY YOU FOR THE DEFECTIVE PRODUCT THAT YOU HAVE PURCHASED
        THROUGH THE SITE, NOR WILL WE UNDER ANY CIRCUMSTANCES BE LIABLE FOR ANY
        LOSS OF PRODUCTION, WORK, DATA, USE, BUSINESS, GOODWILL, REPUTATION,
        REVENUE OR PROFIT, ANY DIMINUTION IN VALUE, COSTS OF REPLACEMENT GOODS
        OR SERVICES, OR ANY] CONSEQUENTIAL, INCIDENTAL, SPECIAL OR PUNITIVE
        DAMAGES OR LOSSES, WHETHER DIRECT OR INDIRECT.
        <br />
        <br />
        Links from the Website
        <br />
        If the Website contains links to other sites and resources provided by
        third parties, these links are provided for your convenience only. This
        includes links contained in advertisements, including banner
        advertisements and sponsored links. We have no control over the contents
        of those sites or resources, and accept no responsibility for them or
        for any loss or damage that may arise from your use of them. If you
        decide to access any of the third party websites linked to this Website,
        you do so entirely at your own risk and subject to the terms and
        conditions of use for such websites.
        <br />
        Geographic Restrictions
        <br />
        The owner of the Website is based in the Commonwealth of New York in the
        United States. Access to the Website may not be legal by certain persons
        or in certain countries. If you access the Website from outside the
        United States and Canada, you do so on your own initiative and are
        responsible for compliance with local laws.
        <br />
        Disclaimer of Warranties
        <br />
        You understand that we cannot and do not guarantee or warrant that files
        available for downloading from the internet or the Website will be free
        of viruses or other destructive code. You are responsible for
        implementing sufficient procedures and checkpoints to satisfy your
        particular requirements for anti-virus protection and accuracy of data
        input and output, and for maintaining a means external to our site for
        any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS
        OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR
        OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
        EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO
        YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
        WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY
        WEBSITE LINKED TO IT.
        <br />
        YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED
        THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT AND
        ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN
        “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND,
        EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED
        WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO
        THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR
        AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE
        COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS
        THAT THE WEBSITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH
        THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED,
        THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES
        IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE
        WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL
        OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
        <br />
        THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
        OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
        WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR
        PARTICULAR PURPOSE.
        <br />
        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW.
        <br />
        Limitation on Liability
        <br />
        IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE
        PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR
        DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
        CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES
        LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY
        SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES,
        INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR
        PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN
        AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS,
        LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL,
        LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH
        OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
        <br />
        THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW.
        <br />
        Indemnification
        <br />
        You agree to defend, indemnify, and hold harmless the Company, its
        affiliates, licensors and service providers, and its and their
        respective officers, directors, employees, contractors, agents,
        licensors, suppliers, successors and assigns from and against any
        claims, liabilities, damages, judgments, awards, losses, costs, expenses
        or fees (including reasonable attorneys’ fees) arising out of or
        relating to your violation of these Terms of Use or your use of the
        Website, including, but not limited to, your User Contributions, any use
        of the Website’s content, services and products other than as expressly
        authorized in these Terms of Use or your use of any information obtained
        from the Website.
        <br />
        Governing Law and Jurisdiction
        <br />
        All matters relating to the Website and these Terms of Use and any
        dispute or claim arising therefrom or related thereto (in each case,
        including non-contractual disputes or claims), shall be governed by and
        construed in accordance with the internal laws of the Commonwealth of
        New York without giving effect to any choice or conflict of law
        provision or rule (whether of the Commonwealth of New York or any other
        jurisdiction).
        <br />
        Any legal suit, action or proceeding arising out of, or related to,
        these Terms of Use or the Website shall be instituted exclusively in the
        appropriate federal court or state court located in the Commonwealth of
        New York. You waive any and all objections to the exercise of
        jurisdiction over you by such courts and to venue in such courts.
        <br />
        Limitation on Time to File Claims
        <br />
        ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO
        THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR
        AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR
        CLAIM IS PERMANENTLY BARRED.
        <br />
        Waiver and Severability
        <br />
        No waiver of by the Company of any term or condition set forth in these
        Terms of Use shall be deemed a further or continuing waiver of such term
        or condition or a waiver of any other term or condition, and any failure
        of the Company to assert a right or provision under these Terms of Use
        shall not constitute a waiver of such right or provision.
        <br />
        If any provision of these Terms of Use is held by a court or other
        tribunal of competent jurisdiction to be invalid, illegal or
        unenforceable for any reason, such provision shall be eliminated or
        limited to the minimum extent such that the remaining provisions of the
        Terms of Use will continue in full force and effect.
        <br />
        Entire Agreement
        <br />
        The Terms of Use, our Privacy Policy, and our End User License Agreement
        constitute the sole and entire agreement between you and the Company
        with respect to the Website and supersede all prior and contemporaneous
        understandings, agreements, representations and warranties, both written
        and oral, with respect to the Website.
        <br />
        <br />
        <b>Product Substitution Policy</b>
        <br />
        <br />
        To process your orders on time, we have selected substitute items that
        will be used in cases where an item is out of stock from our suppliers.
        Substitute items have been selected with quality and closeness to our
        primary items in mind. Our commitment is to fulfill all orders within
        our committed time frame and this policy ensures this will take place.
        We will not communicate product substitutions and recommend you check
        our new Live Inventory reports (found in your navigation side bar) which
        will give you real time stock levels of our primary items, prior to
        placing your orders.
        <br />
        <br />
        Your Comments and Concerns
        <br />
        This website is operated by Shirtly.
        <br />
        All notices of copyright infringement claims should be sent to the
        copyright agent designated in our Copyright Policy in the manner and by
        the means set forth therein.
        <br />
        All other feedback, comments, requests for technical support and other
        communications relating to the Website should be directed to:
        support@shirtly.com.
        <br />
      </Paper>
      <Footer links={[]} />
    </Container>
  );
};

export default Terms;
