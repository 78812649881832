import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import CustomerInformation from 'pages/order-details/CustomerInformation';
import Button from 'components/Button';
import COLORS from 'shared/constants/colors';
import Checkbox from 'components/CheckBox';
import { USD_TO_CAD_CONVERSION_RATE } from 'constants/currency';
import {
  Container,
  ShippingPricing,
  CheckboxWrapper,
} from 'styles/pages/order-details/shippingInfo';
import ErrorMessage from 'components/ErrorMessage';

const ShippingInfo = ({
  order,
  shippingCostInfo = {},
  onOrderSummary,
  orderPrintProviders,
  onPrintProviderChange,
}) => {
  const currency = useSelector((state) => state?.currency?.value);
  let shippingCost = shippingCostInfo.cost;

  return (
    <Container>
      <CustomerInformation orderDetail={order} showEdit={false} />
      <ShippingPricing>
        <h1>Shipping Speeds and Pricing</h1>
        <div>
          <p>
            Shirtly takes {shippingCostInfo.minDays}-{shippingCostInfo.maxDays}
            &nbsp;business days to fulfill your order(s), in addition to
            shipping times. Shipping times and costs vary based on the shipping
            method and the location to which your order(s) will be delivered.
          </p>
          <ErrorMessage>
            Shirtly offers Print-on-Demand + drop shipping services. During
            order submission, from time to time shipping services from our
            shipping partners may not be able to service the shipping option
            selected. In the case when standard and/or chosen shipping option is
            not available for the destination, shipping fees may be subject to
            change. Should this happen, you will be contacted by Shirtly Client
            Support Team for further steps to be taken to ensure timely delivery
            of your order.
          </ErrorMessage>

          {orderPrintProviders?.printProviders?.length > 1 && (
            <CheckboxWrapper>
              <div className="title">Select Shipping Methods</div>
              {orderPrintProviders?.printProviders?.map((p, i) => {
                const shippingDestination =
                  order?.CountryString !== 'US' && order?.CountryString !== 'CA'
                    ? 'Intl'
                    : order?.CountryString;

                const shippingCostInfo = _.find(
                  p?.ShippingCosts,
                  (c) => c?.ShippingDestination === shippingDestination
                );

                return (
                  <Checkbox
                    disabled={
                      order?.Status === 'InProduction' ||
                      order?.Status === 'Shipped'
                    }
                    key={`checkbox${i}`}
                    label={shippingCostInfo?.Title}
                    checked={i === orderPrintProviders?.index}
                    onChange={() => {
                      onPrintProviderChange(i, shippingCostInfo?.carrierCode);
                    }}
                  />
                );
              })}
            </CheckboxWrapper>
          )}

          <h5 style={{ fontSize: 20 }}>
            ${' '}
            {currency === 'USD'
              ? Number(shippingCost).toFixed(2)
              : (Number(shippingCost) * USD_TO_CAD_CONVERSION_RATE).toFixed(2)}
            &nbsp;{currency}
          </h5>
          <Button
            containerStyle={{
              backgroundColor: '#1594db',
              color: COLORS.WHITE,
              padding: '10px 16px',
              textTransform: 'capitalize',
              fontSize: '16px',
            }}
            onClick={onOrderSummary}
            variant="text"
            title="Continue to order summary"
          />
        </div>
      </ShippingPricing>
    </Container>
  );
};

export default ShippingInfo;
