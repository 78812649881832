import styled from "styled-components";

const Container = styled.div`
  margin: 20px 10px;
`;

const Filter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  > div {
    button {
      border-radius: 6px;
      width: 109px;
    }
  }
  @media (max-width: 550px) {
    display: grid;
    grid-gap: 10px;
    justify-content: normal;
    > div {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const FilterButton = styled.button`
  background: #1594db;
  color: white;
  font-family: "Inter700";
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  :disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  grid-gap: 10px;
`;

const Card = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 14px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  border: 1px dashed #ccc;
  cursor: pointer;
  .check-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 7px;
    top: 7px;
  }
  .uncheck-icon {
    background-color: #f4f4f4;
    color: #f4f4f4;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 7px;
    top: 7px;
  }
`;

const CardFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  svg {
    cursor: pointer;
    color: #2280c1;
  }
`;

export {
  Container,
  Filter,
  FilterButton,
  CardWrapper,
  Card,
  CardFooter,
  CheckboxWrapper,
};
