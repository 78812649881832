import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from 'components/TextField';
import Switch from 'components/Switch';
import CloudinaryWidget from 'components/CloudinaryWidget';
import FooterClone from 'pages/home/Footer';
import { updateSection } from 'actions/webPages';
import {
  Card,
  CardTitle,
  AddSectionButton,
  AddSectionHeader,
  SectionNavbar,
  TwoBoxWrapper,
  ThreeBoxWrapper,
} from 'styles/pages/web-pages';

const Footer = ({ data }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.profile?.token);
  const [bannerData, setBannerData] = useState(null);

  useEffect(() => {
    if (data && data?.find((d) => d?.name === 'Footer')) {
      const d = data?.find((d) => d?.name === 'Footer');
      setBannerData(() => d);
    }
  }, [data]);

  const { components, navVisibility, name } = bannerData || {};
  const {
    BackgroundColor = '',
    Email = '',
    BackgroundImage = '',
    Image = '',
    Cards = [],
    PrintProviders = [],
  } = components || {};

  return (
    <Card>
      <div>
        <FooterClone data={bannerData || {}} />
      </div>

      <SectionNavbar>
        <CardTitle style={{ border: 'none' }}>{name}</CardTitle>
        <Switch
          handleChange={(e) =>
            setBannerData({
              ...bannerData,
              navVisibility: e.target.checked,
            })
          }
          checked={navVisibility || false}
        />
      </SectionNavbar>

      <TwoBoxWrapper>
        <TextField
          label="Background Color"
          size="small"
          value={BackgroundColor}
          onChange={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                BackgroundColor: v,
              },
            })
          }
        />
        <TextField
          label="Email"
          size="small"
          value={Email}
          onChange={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                Email: v,
              },
            })
          }
        />
      </TwoBoxWrapper>

      <TwoBoxWrapper>
        <CloudinaryWidget
          label="Background Image"
          value={BackgroundImage}
          setValue={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                BackgroundImage: v,
              },
            })
          }
          folderPath="Shirtly/Web_Pages/LandingPage/Footer"
        />
        <CloudinaryWidget
          label="Image"
          value={Image}
          setValue={(v) =>
            setBannerData({
              ...bannerData,
              components: {
                ...bannerData.components,
                Image: v,
              },
            })
          }
          folderPath="Shirtly/Web_Pages/LandingPage/Footer"
        />
      </TwoBoxWrapper>

      <CardTitle style={{ marginTop: 10 }}>Card Settings</CardTitle>
      {Cards?.map((r, i) => (
        <TwoBoxWrapper key={`button${i}`}>
          <TextField
            label="Card Title"
            size="small"
            value={r?.CardTitle || ''}
            onChange={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Cards: bannerData.components.Cards?.map((b, j) =>
                    i === j ? { ...b, CardTitle: v } : b
                  ),
                },
              })
            }
          />
          <TextField
            label="Card Link"
            size="small"
            value={r?.CardLink || ''}
            onChange={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  Cards: bannerData.components.Cards?.map((b, j) =>
                    i === j ? { ...b, CardLink: v } : b
                  ),
                },
              })
            }
          />
        </TwoBoxWrapper>
      ))}

      <CardTitle style={{ marginTop: 10 }}>Print Provider Settings</CardTitle>
      {PrintProviders?.map((r, i) => (
        <ThreeBoxWrapper key={`button${i}`}>
          <TextField
            label="Card Title"
            size="small"
            value={r?.PlantCountry || ''}
            onChange={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  PrintProviders: bannerData.components.PrintProviders?.map(
                    (b, j) => (i === j ? { ...b, PlantCountry: v } : b)
                  ),
                },
              })
            }
          />
          <TextField
            label="Card Link"
            size="small"
            value={r?.PlantAddress || ''}
            onChange={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  PrintProviders: bannerData.PrintProviders.Cards?.map((b, j) =>
                    i === j ? { ...b, PlantAddress: v } : b
                  ),
                },
              })
            }
          />

          <CloudinaryWidget
            label="Background Image"
            value={r?.PlantFlag}
            setValue={(v) =>
              setBannerData({
                ...bannerData,
                components: {
                  ...bannerData.components,
                  PrintProviders: bannerData.components.PrintProviders?.map(
                    (b, j) => (i === j ? { ...b, PlantFlag: v } : b)
                  ),
                },
              })
            }
            folderPath="Shirtly/Web_Pages/LandingPage/Footer"
          />
        </ThreeBoxWrapper>
      ))}

      <AddSectionHeader>
        <AddSectionButton
          title="Update"
          containerStyle={{
            backgroundColor: '#1594db',
            color: '#fff',
            fontWeight: 'bold',
            marginTop: 20,
          }}
          onClick={async () => {
            const payload = {
              ...bannerData,
              page: 'LandingPage',
            };

            await dispatch(updateSection({ data: payload, token }));
          }}
        />
      </AddSectionHeader>
    </Card>
  );
};

export default Footer;
