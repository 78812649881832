import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SubHeader from "components/SubHeader";
import { AUTH_LOGOUT } from "actions/auth";
import { sendEmail } from "actions/orders";
import ErrorMessage from "components/ErrorMessage";
import EmailConfirmedNote from "pages/ip-program/EmailConfirmedNote";
import AddArtWorkDialog from "pages/ip-program/AddArtWorkDialog";
import {
  savedIpArtFiles,
  deleteIpArtFile,
  decisionIPArtFile,
} from "actions/user";
import IpProgramFilter from "pages/ip-program/Filter";
import { clearMocks } from "actions/designer";
import ArtFilesTable from "pages/ip-program/table";
import Pagination from "components/Pagination";
import { getCountries } from "actions/countries";
import { Container, PaginationWrapper } from "styles/pages/ip-program";

const take = 10;

const IpProgram = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth);
  const [artWorkDialog, setArtWorkDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [artFiles, setArtFiles] = useState(null);
  const countries = useSelector((state) => state?.countries?.data);
  const [user, setUser] = useState(null);
  const isAdmin = auth?.profile?.emailid === "support@shirtly.com";

  useEffect(() => {
    if (!auth?.profile?.IPUser && !isAdmin) {
      dispatch({ type: AUTH_LOGOUT });
    }
  }, [auth, dispatch, isAdmin]);

  const fetchData = async (skip = 0) => {
    const response = await dispatch(
      savedIpArtFiles({
        emailId: user?.User || auth?.profile?.emailid,
        skip,
        take,
      })
    );

    setArtFiles(response);
  };

  useEffect(() => {
    fetchData((currentPage - 1) * take);
  }, [dispatch, currentPage]);

  useEffect(() => {
    reloadPage();
  }, [user]);

  useEffect(() => {
    const loadCountries = async () => {
      if (!countries?.length) {
        dispatch(getCountries());
      }
    };
    loadCountries();
  }, [dispatch, countries]);

  const reloadPage = () => {
    setArtWorkDialog(() => false);
    if (currentPage === 1) {
      fetchData();
    } else {
      setCurrentPage(1);
    }
  };

  return (
    <Container>
      {!isAdmin && <EmailConfirmedNote />}
      <SubHeader
        title="IP Program"
        rightCornerButtons={
          isAdmin
            ? []
            : [
                {
                  title: "Add Art Work",
                  onClick: () => setArtWorkDialog(true),
                },
              ]
        }
      />

      <ErrorMessage className="error">
        Art file verification is an ongoing process. As more trademarked art
        files are added, your files will be re verified in order to protect
        everyone.
      </ErrorMessage>

      {isAdmin && (
        <IpProgramFilter
          reloadPage={reloadPage}
          setArtFiles={setArtFiles}
          user={user}
          setUser={(v) => setUser(() => v)}
        />
      )}

      <ArtFilesTable
        artFiles={artFiles?.artfiles}
        onApprove={async (data) => {
          const payload = {
            asset_id: data?.asset_id,
            approved: true,
          };
          await dispatch(decisionIPArtFile(payload));

          await dispatch(
            sendEmail({
              user: data?.emailId,
              subject: `Artwork Approved (${data?.patentNumber})`,
            })
          );

          reloadPage();
        }}
        onReject={async (data) => {
          const payload = {
            asset_id: data?.asset_id,
            approved: false,
          };
          await dispatch(decisionIPArtFile(payload));
          await dispatch(
            sendEmail({
              user: data?.emailId,
              subject: `Artwork Rejected (${data?.patentNumber})`,
            })
          );
          reloadPage();
        }}
        onDelete={async (p) => {
          await dispatch(deleteIpArtFile({ asset_id: p?.asset_id }));
          await dispatch(clearMocks({ publicId: p.public_id }));
          reloadPage();
        }}
      />
      <PaginationWrapper>
        <Pagination
          totalCount={artFiles?.artfilesCount}
          take={take}
          page={currentPage}
          onChange={(e, v) => setCurrentPage(v)}
        />
      </PaginationWrapper>
      {artWorkDialog && (
        <AddArtWorkDialog
          open={artWorkDialog}
          setOpen={() => setArtWorkDialog(false)}
          reload={reloadPage}
        />
      )}
    </Container>
  );
};

export default IpProgram;
