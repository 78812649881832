import styled from 'styled-components';

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid #e6e6e6;
  padding-bottom: 5px;
  margin-bottom: 20px;
`;

const Tab = styled.div`
  font-size: 16px;
  line-height: 28px;
  font-family: 'Inter600';
  color: #18191f;
  opacity: 0.6;
  margin-right: 30px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  gap: 7px;
  &.active {
    opacity: 1;
    position: relative;

    ::after {
      content: '';
      display: inline-block;
      height: 6px;
      width: 100%;
      background: #ffd363;
      position: absolute;
      bottom: -8px;
      left: 0;
    }
  }
`;
export { TabsWrapper, Tab };
