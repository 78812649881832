import React from 'react';
import _ from 'lodash';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Container } from 'styles/components/Stepper';

const useSteperStyleStyles = makeStyles({
  root: {
    backgroundColor: '#e2e2e2',
    zIndex: 1,
    color: '#9a9a9a',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #e2e2e2 ',
    height: '55px',
    width: '55px',
    fontFamily: 'Inter500',
    fontSize: '16px',
  },
  active: {
    backgroundColor: '#ffd363',
    color: '#fff',
    border: '1px solid #ffd363 ',
  },
  completed: {
    backgroundColor: '#ffd363',
    color: '#fff',
    border: '1px solid #ffd363 ',
    opacity: 0.8,
  },
});

function SteperStyle(props) {
  const classes = useSteperStyleStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}>
      {props.icon}
    </div>
  );
}

const CustomStepper = ({ activeStep, steps, onChange }) => {
  const clickEnabled = _.isFunction(onChange);
  return (
    <Container>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step
            key={label}
            onClick={() => _.isFunction(onChange) && onChange(index)}
            className={clickEnabled ? 'pointer' : ''}>
            <StepLabel
              clickEnabled={clickEnabled}
              StepIconComponent={SteperStyle}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Container>
  );
};

export default CustomStepper;
