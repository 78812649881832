import React, { useState, useEffect } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from 'components/Button';
import {
  Container,
  ProductInformation,
  SelectCategories,
  Update,
  SubHeading,
} from 'styles/pages/edit-products/editProductInformation';

const openCloudinaryWidget = (folder, cb) => {
  const myWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: 'big-oven-tees-inc',
      show_powered_by: false,
      uploadPreset: 'tnedst8q',
      sources: ['local', 'camera', 'url'],
      folder,
      clientAllowedFormats: ['png', 'jpeg', 'jpg'],
    },
    (error, result) => {
      if (!error && result && result.event === 'success') {
        cb({
          public_id: result?.info?.public_id,
          secure_url: result?.info?.secure_url,
        });
        myWidget.close();
      }
    }
  );
  myWidget.open();
};

const EditProductAdditionalImages = ({
  product = { Product: {} },
  addAdditionalImages,
}) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (!images?.length && product?.AdditionalImages?.length) {
      setImages(product?.AdditionalImages);
    }
  }, [product]);

  return (
    <Container>
      <ProductInformation>
        <SubHeading>
          Product Additional Images
          <AddCircleIcon
            style={{ float: 'right', cursor: 'pointer', color: '#1594db' }}
            onClick={() => {
              openCloudinaryWidget(
                `Shirtly/Shirtly_Products_Mock/Images/mockups/${product?.Product?.Name}/AdditionalImages`,
                (image) => setImages([...images, image])
              );
            }}
          />
        </SubHeading>
      </ProductInformation>

      <SelectCategories>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
            marginBottom: 40,
          }}>
          {images?.map((image, index) => (
            <div
              key={`image${index}`}
              style={{
                position: 'relative',
                margin: '0 auto',
                width: 100,
                height: 100,
                border: '2px dashed #ccc',
                borderRadius: 10,
                padding: 10,
                display: 'grid',
                alignItems: 'center',
              }}>
              <DeleteIcon
                style={{
                  position: 'absolute',
                  color: 'red',
                  cursor: 'pointer',
                  right: 0,
                  top: 5,
                }}
                onClick={() => {
                  const data = images.filter((t, i) => i !== index);
                  setImages(data);
                  const payload = {
                    ID: product?.Product?.ID,
                    additionalImages: data,
                  };
                  addAdditionalImages(payload, { publicId: image?.public_id });
                }}
              />
              <img
                src={image?.secure_url}
                style={{
                  width: '100%',
                }}
              />
            </div>
          ))}
        </div>
      </SelectCategories>

      <Update>
        <Button
          disabled={!images?.length}
          title='Update'
          onClick={() => {
            const payload = {
              ID: product?.Product?.ID,
              additionalImages: images,
            };
            addAdditionalImages(payload);
          }}
        />
      </Update>
    </Container>
  );
};

export default EditProductAdditionalImages;
