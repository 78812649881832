import styled, { css } from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import COLORS from 'shared/constants/colors';

const dot = {
  position: 'absolute',
  backgroundColor: '#1594DB',
  borderRadius: '50%',
  width: '14px',
  height: '14px',
  border: '2px solid #fff',
};

const Container = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  grid-area: artwork;
  padding: 15px;
  border-radius: 5px;
  background-color: ${COLORS.WHITE};
  width: 100%;
  max-width: 100vw;
  overflow-x: auto;
  position: relative;
  margin-bottom: 15px;
  @media (min-width: 1290px) {
    position: sticky;
    top: 0;
    min-height: 600px;
  }
`;
const Wrapper = styled.div`
  position: relative;
  transform: translate3d(0, 0, 0);
  width: 565px;
  height: 565px;
  margin-top: 10px;

  img {
    width: 100%;
    margin: auto;
    bottom: 0;
    /* ${(props) =>
      props.isSub &&
      css`
        height: auto !important;
      `} */
  }
`;

const OuterEnvelope = styled.div`
  position: absolute;
  margin: 0 auto;
  /* ${(props) =>
    props.isSub &&
    css`
      box-shadow: 4px 4px 10px #d9d9d9, -4px -4px 10px #ffffff;
    `} */
`;

const InnerEnvelope = styled.div`
  height: 100%;
  width: 100%;
  transition: all 0.25s ease-out 0s;
  border: 1px dashed #1594db;
  canvas,
  .canvas-container {
    width: 100% !important;
    height: 100% !important;
  }

  &:hover {
    background: rgba(204, 204, 204, 0.2);
  }

  ${(props) =>
    props.previewImage &&
    css`
      border: 1px dashed transparent;
      &:hover {
        background: transparent;
      }
    `}
`;

const BrowseLibraryButton = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: ${COLORS.BLUE};
  font-size: 17px;
  font-weight: bold;
  flex-direction: column;
`;
const ResizeDrag = styled.div`
  border: 2px solid #1594db;
  pointer-events: none;
  height: 100%;
  position: relative;
  ${(props) =>
    props.previewImage &&
    css`
      border: 2px solid transparent;
    `}
`;

const TopLeftCorner = styled.div`
  ${dot}
  top : -8px;
  left: -8px;
`;

const TopMiddleCorner = styled.div`
  ${dot}
  top : -8px;
  left: 0;
  right: 0;
  margin: auto;
`;

const TopRightCorner = styled.div`
  ${dot}
  top : -8px;
  right: -8px;
`;

const ArtImage = styled.img`
  height: 100%;
`;

const BottomLeftCorner = styled.div`
  ${dot}
  bottom : -8px;
  left: -8px;
`;

const BottomMiddleCorner = styled.div`
  ${dot}
  bottom : -8px;
  left: 0;
  right: 0;
  margin: auto;
`;

const BottomRightCorner = styled.div`
  ${dot}
  bottom : -8px;
  right: -8px;
`;

const CenterRightCorner = styled.div`
  ${dot}
  top : 0;
  bottom: 0;
  margin: auto;
  right: -8px;
`;

const CenterLeftCorner = styled.div`
  ${dot}
  top : 0;
  bottom: 0;
  margin: auto;
  left: -8px;
`;
const StyledCloseIcon = styled(CloseIcon)`
  color: ${COLORS.WHITE};
  font-size: 50px;
  position: absolute;
  top: -11px;
  left: -11px;
  background: red;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  padding: 3px;
`;

const ImageInch = styled.div`
  font-size: 20px;
  color: white;
  font-weight: bold;
  padding: 4px;
  background: #1594db;
  margin-top: 14px;
  width: 150px;
  border-radius: 5px;
  text-align: center;
`;

const StyledVisibilityOffIcon = styled(VisibilityOffIcon)`
  position: absolute;
  top: -11px;
  right: -11px;
  background: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const PrintableAreaAlign = styled.div`
  > div {
    background: #000;
    &:first-child {
      padding: 4px;
      color: ${COLORS.WHITE};
    }
  }
`;
const AlignIcon = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 2px;
  color: ${COLORS.WHITE};
  padding: 4px 0;
  svg {
    font-size: 18px;
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  grid-gap: 15px;
  flex-wrap: wrap;
  button {
    text-transform: capitalize;
    font-family: 'Inter500';
    box-shadow: none;
    :hover {
      box-shadow: none;
    }
  }
`;

const LibraryText = styled.div`
  cursor: pointer;
  text-align: center;
  :hover {
    text-decoration: underline;
  }
`;

const AdvanceToolBrowseLibraryButton = styled.div`
  display: flex;
  background: black;
  color: white;
  height: 40px;
  padding: 0 10px;
  align-items: center;
  justify-content: space-around;
  svg {
    cursor: pointer;
  }
`;

const PlatenDimensionInches = styled.div`
  text-align: center;
  background: ${(props) => (props?.selected ? 'rgb(21, 148, 219)' : '#e0e0e0')};
  color: ${(props) => (props?.selected ? 'rgb(255, 255, 255)' : '#000')};
  font-family: Inter500;
  border-radius: 4px;
  font-size: 12px;
  padding: 5px;
`;

export {
  LibraryText,
  Container,
  Wrapper,
  OuterEnvelope,
  InnerEnvelope,
  BrowseLibraryButton,
  ResizeDrag,
  ArtImage,
  TopLeftCorner,
  TopMiddleCorner,
  TopRightCorner,
  BottomLeftCorner,
  BottomMiddleCorner,
  BottomRightCorner,
  CenterRightCorner,
  CenterLeftCorner,
  StyledCloseIcon,
  StyledVisibilityOffIcon,
  PrintableAreaAlign,
  AlignIcon,
  ButtonWrapper,
  ImageInch,
  AdvanceToolBrowseLibraryButton,
  PlatenDimensionInches,
};
