import styled from 'styled-components';

const Card = styled.div`
  max-width: 100px !important;
  height: 100px;
  flex-shrink: 0;
  position: relative;
  transition: all ease-out 0.5s;
  background: #fff;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  margin: 0 10px;
  box-shadow: 4px 4px 10px #d9d9d9, -4px -4px 10px #ffffff;

  display: grid;
  cursor: pointer;
  @media (max-width: 767px) {
    margin: 0 auto;
  }
  > div {
    z-index: 0;
    margin: 4px;
    background: #fff;
    padding: 0.5em 0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
`;

const CardWrapper = styled.div`
  /* outline: none; */
`;

const Title = styled.h2`
  font-weight: 100;
  font-size: 24px;
  color: #676a6c;
  border-bottom: 2px solid #666;
  padding-bottom: 10px;
`;

const Wrapper = styled.div`
  padding: 20px 30px;
  border-bottom: 2px solid #a1a1a1;
  .slick-prev,
  .slick-next {
    display: block !important;
  }
  .slick-next:before,
  .slick-prev:before {
    color: #1594db;
    opacity: 1;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.5;
  }
`;

const StockWrapper = styled.div`
  margin: 30px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
`;
const StockContainer = styled.div`
  text-align: center;
`;
const StockTitle = styled.h2`
  font-size: 22px;
`;
const NoDataFound = styled.h2`
  color: red;
`;

const Quantity = styled.h2`
  font-size: 24px;
`;

const ColorName = styled.h2`
  font-size: 18px;
  margin: 0;
`;

const AutocompleteWrapper = styled.div`
  margin-bottom: 20px;
`;

export {
  Card,
  CardWrapper,
  Title,
  Wrapper,
  StockWrapper,
  StockContainer,
  StockTitle,
  NoDataFound,
  Quantity,
  ColorName,
  AutocompleteWrapper,
};
