import styled from 'styled-components';
const Container = styled.div`
  color: #fff;
  margin-top: 20px;
`;

const Wrapper = styled.div`
  max-width: 1140px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 30px 15px;
  margin: 0 auto;
  @media (max-width: 740px) {
    text-align: center;
    justify-content: center;
  }
  @media (min-width: 1200px) {
    padding: 60px 15px;
  }
`;

const Address = styled.div`
  img {
    width: 30px;
  }
  p {
    margin: 0;
    font-size: 14px;
    line-height: 24px;
  }
`;

const AddressBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  @media (max-width: 500px) {
    grid-gap: 20px;
    text-align: left;
  }
`;

const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    color: #fff;
    font-size: 16px;
    font-family: 'Inter400';
    margin: 10px 0;
  }
  img {
    width: 100%;
    max-width: 130px;
  }
`;

const Links = styled.div`
  padding-top: 46px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  a {
    color: #fff;

    :last-child {
      padding-left: 0;
    }
    padding: 10px 30px 10px 0;

    :hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 740px) {
    padding: 20px 0;
    a {
      padding: 10px;
    }
  }
`;

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
  /* span {
    margin: 0 8px;
    :first-child {
      margin-left: 0;
    }
    :last-child {
      margin-right: 0;
    }
  } */
`;

const Terms = styled.div`
  background-color: #141414;
  > div {
    max-width: 1140px;
    padding: 0 15px;
    width: 100%;
    margin: 0 auto;
    min-height: 57px;
    display: flex;
    align-items: center;

    p {
      margin: 0;
      text-align: center;
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
  @media (max-width: 740px) {
    > div {
      justify-content: center;
    }
  }
`;

export {
  Container,
  Wrapper,
  Address,
  LogoSection,
  AddressBox,
  Links,
  SocialLinks,
  Terms,
};
