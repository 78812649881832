import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #1594db;
  color: #ffffff;
  min-height: 0;
  padding: 2em;
  border-radius: 4px;
  justify-content: center;
  box-shadow: inset 50px 50px 100px #1e7ec2, inset -50px -50px 100px #299ceb;
  @media (min-width: 1200px) {
    min-height: 188px;
  }
`;

const Title = styled.h2`
  font-size: 2.35em;
  font-family: 'Inter700';
`;

export { Container, Title };
