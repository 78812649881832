import React from 'react';
import StatsBox from 'pages/dashboard/Stats/StatsBox';
import { Container } from 'styles/pages/dashboard/stats';

const Stats = ({ ordersCount, isAdmin }) => (
  <Container>
    {isAdmin ? (
      <div>
        <StatsBox
          title="Total Orders"
          stats={ordersCount?.TotalOrders || 0}
          color=" #1594DB"
        />
        <StatsBox
          title="Total Shipped"
          stats={ordersCount?.Shipped || 0}
          color="#57B894"
        />
        <StatsBox
          title="Total In-Production"
          stats={ordersCount?.InProduction || 0}
          color="#FFD363"
        />
        <StatsBox
          title="Total Cancelled"
          stats={ordersCount?.Cancelled || 0}
          color="#D44F6A"
        />
      </div>
    ) : (
      <React.Fragment>
        <div>
          <StatsBox
            title="Total Orders"
            stats={ordersCount?.TotalOrders || 0}
            color=" #1594DB"
          />

          <StatsBox
            title="Pending Details"
            stats={ordersCount?.PendingDetails || 0}
            color=" #c27808"
          />
          <StatsBox
            title="Awaiting Payment"
            stats={ordersCount?.AwaitingPayment || 0}
            color="#9915db"
          />

          <StatsBox
            title="In-Production"
            stats={ordersCount?.InProduction || 0}
            color="#FFD363"
          />
        </div>
        <div>
          <StatsBox
            title="Total Shipped"
            stats={ordersCount?.Shipped}
            color="#57B894"
          />
          <StatsBox
            title="Total Cancelled"
            stats={ordersCount?.Cancelled || 0}
            color="#D44F6A"
          />
          <StatsBox
            title="Voided"
            stats={ordersCount?.Voided || 0}
            color="#920b0b"
          />
          <StatsBox
            title="Stores Connected"
            stats={ordersCount?.StoreConnected || 0}
            color="#075d2e"
          />
        </div>
      </React.Fragment>
    )}
  </Container>
);

export default Stats;
