import React from "react";
import AdvanceTool from "components/ArtWork/AdvanceTool";
import { Container } from "styles/components/ArtWork";

const ArtWork = ({
  orderLine = {},
  onDrop,
  setCoordinates,
  onPreview,
  setOrderLine,
  onFabricDrop,
  onFabricRemove,
  product,
  plus,
  dtgPlusProcess,
  printType,
}) => {
  const advanceTool = () => (
    <Container>
      <AdvanceTool
        printType={printType}
        orderLine={{ ...orderLine, aiGenerateImages: true }}
        onFabricDrop={onFabricDrop}
        setCoordinates={setCoordinates}
        onFabricRemove={onFabricRemove}
        onPreview={onPreview}
        setOrderLine={setOrderLine}
        product={product}
        plus={plus}
        dtgPlusProcess={dtgPlusProcess}
        onDrop={onDrop}
        isAiGeneratedMockUps={true}
      />
    </Container>
  );

  return <Container>{advanceTool()}</Container>;
};

export default ArtWork;
