import styled from 'styled-components';
import COLORS from 'shared/constants/colors';

const Container = styled.div`
  padding-bottom: 20px;
`;

const Wrapper = styled.div`
  padding: 23px;
`;

const Row = styled.div`
  display: flex;
  grid-gap: 20px;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  align-items: center;

  > h3 {
    font-weight: 600;
    font-size: 18px;
    margin: 0;
    color: #1594db;
  }
  > button {
    color: ${COLORS.BLUE};
  }
`;

const CloudWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 5px;
  width: 100%;
  position: relative;
  > div {
    width: 100%;
  }
  input {
    padding-right: 97px;
  }
  .upload-icon {
    cursor: pointer;
    color: #ffd363;
    position: absolute;
    right: 9px;
  }
  .logo {
    position: absolute;
    right: 47px;
    width: 34px;
    height: auto;
    max-height: 34px;
  }
  .download-icon {
    cursor: pointer;
    color: #ffd363;
    position: absolute;
    right: 9px;
  }
`;

const FieldWrapper = styled.div`
  flex: 1;
`;

const Update = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 35px;
  > button {
    background-color: rgb(21, 148, 219);
    color: rgb(255, 255, 255);
    box-shadow: none;
    text-transform: none;
    border-radius: 6px;
    height: 48px;
    font-family: 'Inter500';
    font-size: 16px;
    margin-left: 15px;
    :hover {
      background-color: rgb(21, 148, 219);
    }
  }
`;

const FourBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
  margin-bottom: 30px;
  @media (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;
const ThreeBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  margin-bottom: 30px;
  @media (max-width: 699px) {
    grid-template-columns: 1fr;
  }
`;
const TextFieldWrapperIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  .MuiFormControl-root {
    width: 100%;
  }
  input {
    padding-right: 44px;
  }
  .delete-icon {
    position: absolute;
    right: 9px;
    cursor: pointer;
    color: #ff0000;
  }
`;
export {
  Container,
  Wrapper,
  Row,
  Heading,
  CloudWrapper,
  FieldWrapper,
  Update,
  FourBoxWrapper,
  ThreeBoxWrapper,
  TextFieldWrapperIcon,
};
