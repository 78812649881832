import styled from 'styled-components';

const Container = styled.div`
  padding: 15px;
`;

const ItemGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0;
  transition: all ease-out 0.25s;
  column-gap: 40px;
  row-gap: 40px;
  border-radius: 4px;

  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(6, 1fr);
  }
`;

const Item = styled.div`
  border-radius: 2px;
  transform: scale(1);
  padding: 1em;
  position: relative;
  background: #fff;
  box-shadow: 4px 4px 10px #d9d9d9, -4px -4px 10px #ffffff;
  transition: all ease-out 0.25s;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const ProductLink = styled.div`
  button {
    background: #000;
    color: #fff;
    width: 100%;
    :hover {
      background: #000;
      color: #fff;
      opacity: 0.5;
    }
  }
`;
const ProductImage = styled.div`
  color: transparent;
  display: inline-block;
  width: 100%;
  margin: 1em 0;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  button {
    background-color: #1594db;
    color: #fff;
    :hover {
      background-color: #1594db;
      color: #fff;
    }
  }
`;

const StyledTab = styled.div`
  display: flex;
  grid-gap: 10px;
  img {
    width: 50px;
    height: 50px;
  }
`;

export {
  Container,
  ItemGroup,
  Item,
  ProductLink,
  ProductImage,
  Pagination,
  StyledTab,
};
