import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from 'components/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CaptureImages from 'pages/order-details/CaptureImages';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: 'green',
    },
  },
  completed: {
    '& $line': {
      borderColor: 'green',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#eaeaf0',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: 'white',
    zIndex: 1,
    fontSize: 24,
    backgroundColor: 'green',
    padding: 5,
    borderRadius: '50%',
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}>
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  stepper: { marginBottom: 20, borderRadius: 10 },
  button: {
    paddingTop: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 20,
  },
}));

function getSteps() {
  return [
    'Bar Code Printed',
    'Under Process',
    'Processed',
    'Quality Checked',
    'Shipped',
  ];
}

export default function CustomizedSteppers({ orderDetail, machineStatus }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  const steps = getSteps();

  React.useEffect(() => {
    if (
      machineStatus?.barcodePrinted === 'true' ||
      orderDetail?.TrackingNumber
    ) {
      setActiveStep(1);
    } else {
      return;
    }

    if (
      machineStatus?.barcodePrinted === 'true' ||
      orderDetail?.TrackingNumber
    ) {
      setActiveStep(2);
    } else {
      return;
    }

    if (machineStatus?.printed === 'true' || orderDetail?.TrackingNumber) {
      setActiveStep(3);
    } else {
      return;
    }

    if (machineStatus?.shipped === 'true' || orderDetail?.TrackingNumber) {
      setActiveStep(5);
    }
  }, [orderDetail, machineStatus]);

  return (
    <div className={classes.root}>
      {/* {orderDetail?.captureImages?.length > 0 && (
        <div className={classes.button}>
          <Button
            title='Product Printed Preview'
            onClick={() => setOpen(true)}
            containerStyle={{
              textTransform: 'capitalize',
              backgroundColor: '#1594db',
              color: '#fff',
            }}
          />
        </div>
      )} */}

      <Stepper
        className={classes.stepper}
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='md'
        scroll='paper'
        fullWidth={true}>
        <DialogTitle>Product Printed Preview</DialogTitle>
        <DialogContent>
          <CaptureImages order={orderDetail} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
