import styled from 'styled-components';

const Container = styled.div`
  padding: 0 20px;
`;

const LogoSection = styled.div`
  margin: 33px 0 28px;
  padding: 1px 0;
  background: linear-gradient(
    90deg,
    rgba(192, 204, 218, 0.1) 0%,
    rgba(192, 204, 218, 0.6) 50.38%,
    rgba(192, 204, 218, 0.1) 100%
  );
`;
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 28px 0;
  background: #f7f8fa;
  img,
  svg {
    margin: 0 15px;
    width: 14%;
  }
`;

const StoresDetail = styled.div``;
const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #e6e6e6;
  p {
    line-height: 32px;
    font-size: 16px;
    margin: 0;
    padding-bottom: 10px;
    padding-right: 60px;
    position: relative;
    font-family: 'Inter600';

    ::after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 5px;
      background: #ffd363;
      position: absolute;
      bottom: -3px;
      right: 0;
    }
  }
`;
const ViewButton = styled.div`
  /* svg {
    cursor: pointer;
    margin-left: 10px;
    color: #d8d8d8;
  } */
  .MuiToggleButtonGroup-root {
    button {
      margin-left: 10px;
      color: #cecfd1;
    }
  }
  .MuiToggleButton-root {
    padding: 11px 0;
    border: none;
    :hover {
      background-color: transparent;
    }
  }
  .MuiToggleButton-root.Mui-selected {
    color: #0000008a;
    background-color: transparent;
    :hover {
      background-color: transparent;
    }
  }
`;
const StoreSection = styled.div`
  padding-block: 40px;
`;
const GridView = styled.div`
  grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
  display: grid;
  grid-gap: 36px 40px;
`;

export {
  Container,
  LogoSection,
  LogoWrapper,
  StoresDetail,
  TitleSection,
  ViewButton,
  StoreSection,
  GridView,
};
