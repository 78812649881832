import React, { useState } from 'react';
import moment from 'moment';
import DatePicker from 'components/DatePicker';
import Button from 'components/Button';
import SubHeader from 'components/SubHeader';
import StockTable from 'pages/order-stock/Table';
import {
  Head,
  PaginationWrapper,
  Container,
  DateWrapper,
} from 'styles/pages/order-stock';
import { dateFilterAllSubmittedOrders } from 'actions/orders';
import { useDispatch, useSelector } from 'react-redux';
import StockModal from 'pages/order-stock/StockModal';
import Pagination from 'components/Pagination';

const take = 1000;
const options = [
  'S&S Active Wear (Canada)',
  'Alpha Broder (Canada)',
  'SANMAR (Canada)',
  'S&S Active Wear (United States)',
  'Alpha Broder (United States)',
  'SANMAR (United States)',
];

const filterOrder = ({ providerName, orders, supplierName, products }) => {
  const filterOrders = orders?.filter(
    (p) => p?.ShirtlyOrderData?.PrintProvider === providerName
  );

  let newData = [];

  for (let i = 0; i < filterOrders?.length; i++) {
    const orderLines =
      filterOrders[i]?.ShirtlyOrderData?.OrderLines[0].OrderLineItems;

    let newOrderLines = [];
    for (let j = 0; j < orderLines?.length; j++) {
      const orderLine = orderLines[j];
      const printProviders = products?.find(
        (p) => p?.Product?.ID === orderLine.ProductID
      )?.PrintProviders;

      const supplier = printProviders?.filter((p) => {
        if (!p?.supplier) {
          return false;
        }

        if (p?.ProviderName === providerName && p?.supplier === supplierName) {
          return true;
        }
        return false;
      });

      if (supplier?.length) {
        newOrderLines.push(orderLine);
      }
    }

    if (newOrderLines?.length) {
      newData.push({
        ...filterOrders[i],
        ShirtlyOrderData: {
          ...filterOrders[i]?.ShirtlyOrderData,
          OrderLines: [
            {
              ...filterOrders[i]?.ShirtlyOrderData?.OrderLines[0],
              OrderLineItems: newOrderLines,
            },
          ],
        },
      });
    }
  }

  return {
    orders: newData,
    orderCounts: { TotalOrders: newData?.length },
  };
};

const OrderStock = () => {
  const products = useSelector((state) => state?.products?.data);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCountrySupplier, setCountrySupplier] = useState(undefined);
  const [stockModalData, setStockModalData] = useState(undefined);

  const disabled = selectedCountrySupplier
    ? selectedCountrySupplier != options[0] &&
      selectedCountrySupplier != options[3]
      ? true
      : false
    : true;

  return (
    <Container>
      <SubHeader title='Order Stock' />
      <Head>
        <DateWrapper>
          <DatePicker
            label='Start Date'
            value={startDate}
            onChange={(v) => setStartDate(v)}
          />
        </DateWrapper>
        {/* <DatePicker
          label='End Date'
          value={endDate}
          onChange={(v) => setEndDate(v)}
        /> */}
        <Button
          title='Fetch Order Data'
          onClick={async () => {
            // if (!startDate || !endDate) {
            //   return;
            // }
            if (!startDate) {
              return;
            }

            setCurrentPage(1);
            const response = await dispatch(
              dateFilterAllSubmittedOrders({
                skip: 0,
                take,
                startDate: moment(startDate).subtract(1, 'days'),
                // endDate: moment(endDate).add(1, 'days'),
                endDate: moment(startDate).add(1, 'days'),
              })
            );
            if (response?.error) {
              return;
            }
            setData(response);
            setCountrySupplier(undefined);
          }}
        />

        <Button
          disabled={disabled}
          title='Add To Order Stock Cart'
          onClick={() => {
            let finalOrderLineItems = [];

            for (let i = 0; i < data?.orders?.length; i++) {
              if (
                data?.orders[i]?.ShirtlyOrderData?.Status !== 'InProduction'
              ) {
                continue;
              }
              const orderLineItems =
                data?.orders[i]?.ShirtlyOrderData?.OrderLines[0]
                  ?.OrderLineItems;

              let newOrderLineItems = [];

              for (let j = 0; j < orderLineItems?.length; j++) {
                const printProviders = products?.find(
                  (p) => p?.Product?.ID === orderLineItems[j].ProductID
                )?.PrintProviders;

                const supplier = printProviders?.find(
                  (p) =>
                    p?.ProviderName ===
                    data?.orders[i]?.ShirtlyOrderData?.PrintProvider
                );

                newOrderLineItems.push({
                  ...orderLineItems[j],
                  supplier: supplier?.supplier,
                  productCode: supplier?.productCode,
                  printProvider:
                    data?.orders[i]?.ShirtlyOrderData?.PrintProvider,
                });
              }

              finalOrderLineItems = [
                ...finalOrderLineItems,
                ...newOrderLineItems,
              ];
            }
            setStockModalData(finalOrderLineItems);
          }}
        />
      </Head>

      {data?.orders && (
        <StockTable
          orders={data?.orders}
          options={options}
          selectedCountrySupplier={selectedCountrySupplier}
          setCountrySupplier={(v) => {
            setCountrySupplier(v);

            if (v === options[0]) {
              const newData = filterOrder({
                orders: data?.orders,
                providerName: 'CA',
                supplierName: 'S&S ACTIVE WEAR',
                products,
              });
              setData(newData);
            } else if (v === options[1]) {
              const newData = filterOrder({
                orders: data?.orders,
                providerName: 'CA',
                supplierName: 'ALPHA BRODER',
                products,
              });
              setData(newData);
            } else if (v === options[2]) {
              const newData = filterOrder({
                orders: data?.orders,
                providerName: 'CA',
                supplierName: 'SANMAR',
                products,
              });
              setData(newData);
            } else if (v === options[3]) {
              const newData = filterOrder({
                orders: data?.orders,
                providerName: 'US',
                supplierName: 'S&S ACTIVE WEAR',
                products,
              });
              setData(newData);
            } else if (v === options[4]) {
              const newData = filterOrder({
                orders: data?.orders,
                providerName: 'US',
                supplierName: 'ALPHA BRODER',
                products,
              });
              setData(newData);
            } else if (v === options[5]) {
              const newData = filterOrder({
                orders: data?.orders,
                providerName: 'US',
                supplierName: 'SANMAR',
                products,
              });
              setData(newData);
            }
          }}
        />
      )}

      {data?.orderCounts?.TotalOrders > 30 && (
        <PaginationWrapper>
          <Pagination
            totalCount={data?.orderCounts?.TotalOrders}
            take={take}
            page={currentPage}
            onChange={async (e, v) => {
              setCurrentPage(v);
              const response = await dispatch(
                dateFilterAllSubmittedOrders({
                  skip: (v - 1) * take,
                  take,
                  startDate: moment(startDate).subtract(1, 'days'),
                  // endDate: moment(endDate).add(1, 'days'),
                })
              );
              if (response?.error) {
                return;
              }
              setData(response);
              setCountrySupplier(undefined);
            }}
          />
        </PaginationWrapper>
      )}

      {stockModalData && (
        <StockModal
          open={!!stockModalData}
          data={stockModalData}
          setOpen={() => setStockModalData(undefined)}
          selectedCountrySupplier={selectedCountrySupplier}
          options={options}
        />
      )}
    </Container>
  );
};

export default OrderStock;
