const imagesBaseURL =
  "https://images.shirtly.com/image/upload/v1609952813/Shirtly/Shirtly_Products_Mock";
const baseURL = "https://shirtly-app.herokuapp.com";
// const baseURL = "https://9e50-216-185-74-146.ngrok-free.app";

const requests = {
  getInventory: "/GetInventoryDto",
  getTags: "/GetTags",
  getColors: "/GetColorGroups",
  // getProducts: '/GetProductsFiltered',
  getAllProducts: "/GetAllProducts",
  getProduct: "/GetOneProduct",
  getStores: "/GetStoreInfo",
  getOrders: "/GetOrders",
  createOrder: "/CreateOrder ",
  getOrderDetail: "/GetOrderDetails?orderId=",
  findGlobalOrder: "/FindGlobalOrder",
  getCountries: "/GetCountry",
  machineOrderDetails: "/MachineOrderDetails",
  deleteOrder: "/DeleteOrder",
  addlineItem: "/LineItem ",
  editOrderCustomerInformation: "/EditOrderCustomerInformation",
  updateSavedOrderData: "/UpdateSavedOrderData",
  updateStatus: "/UpdateStatus",
  saveOrderData: "/SaveOrderData",
  removeLineItem: "/RemoveLineItem ",
  getFulfillmentCost: "/GetFulfillmentCost",
  getDesigns: "/GetDesigns",
  getBackgroundBurnDesigns: "/GetBackgroundBurnDesigns",
  getPlusArtFiles: "/getPlusArtFiles",
  getMocks: "/GetMocks",
  clearArtWork: "/ClearArtWork",
  submitOrderToCanada: "/submitOrderToCanada",
  submitOrderToUSA: "/submitOrderToUSA",
  submitOrderToEU: "/submitOrderToEU",
  submitOrderToNY: "/submitOrderToNY",
  submitOrderToAU: "/submitOrderToAU",
  stripeCharge: "/stripeCharge",
  getShopifyOrders: "/getShopifyOrders",
  getEtsyOrders: "/getEtsyOrders",
  etsyOrders: "/etsyOrders",
  getAllCards: "/getAllCards",
  login: "/Login",
  IPLogin: "/IPLogin",
  signUp: "/SignUp",
  userData: "/UserData",
  updateUserInfo: "/updateUserInfo",
  notifyNewUser: "/NotifyNewUser",
  etsyLoginUrl: "/etsyLoginUrl",
  getFinalEtsyCred: "getFinalEtsyCred",
  removeStore: "/RemoveStore",
  getOrderById: "/getOrderById",
  removeCard: "/removeCard",
  deactivateUser: "/deactivateUser",
  updatePassword: "/updatePassword",
  forgotPassword: "/ForgotPassword",
  updateCost: "/UpdateCost",
  generateMocks: "/GenerateMocks",
  getSyncedProducts: "/getSyncedProducts",
  removeSyncedProducts: "/removeSyncedProducts",
  getPreDesigns: "/GetPreDesigns",
  GetPhraseDesigns: "/GetPhraseDesigns",
  allSubmittedOrders: "/AllSubmittedOrders",
  searchAllSubmittedOrders: "/SearchAllSubmittedOrders",
  updateChargeInformation: "/UpdateChargeInformation",
  orderSubmitNotification: "/OrderSubmitNotification",
  marketPlaceOrderDetails: "/MarketPlaceOrderDetails",
  getAllSubmittedUsers: "/GetAllSubmittedUsers",
  getSearchedSubmittedUsers: "/GetSearchedSubmittedUsers",
  updateUserStatus: "/UpdateUserStatus",
  userSubmittedOrder: "/UserSubmittedOrder",
  filterAllSubmittedOrders: "/FilterAllSubmittedOrders",
  cancelOrderFromMachine: "/CancelOrderFromMachine",
  calaculateTax: "/CalaculateTax",
  chargeInformation: "/ChargeInformation",
  paypalChargeInformation: "/paypalChargeInformation",
  editProduct: "/EditProductInformation",
  editSizeInformation: "/EditSizeInformation",
  saveArtFiles: "/SaveArtFiles",
  downloadArtFile: "/DownloadArtFile",
  editColorsInformation: "/EditColorsInformation",
  editDesignerSettings: "/EditDesignerSettings",
  editPrintProvidersInformation: "/EditPrintProvidersInformation",
  deleteProduct: "/DeleteProduct",
  paypalCharge: "/paypalCharge",
  getPaypalTransactionId: "/getTransactionId",
  getAllDTGPlus: "/GetAllDTGPlus",
  editDTGPlusInformation: "/EditDTGPlusInformation",
  updateDTGPlusInfo: "/UpdateDTGPlusInfo",
  deleteDTGPlusInfo: "/DeleteDTGPlusInfo",
  orderShippedNotification: "/OrderShippedNotification",
  getTrackingCANumber: "/getTrackingCANumber",
  getTrackingUSNumber: "/getTrackingUSNumber",
  generateArtboard: "/generateArtboard",
  editArtworkSettings: "/EditArtworkSettings",
  dateFilterAllSubmittedOrders: "/DateFilterAllSubmittedOrders",
  allOrderReport: "/AllOrderReport",
  allOrderReportForChart: "/AllOrderReportForChart",
  allUserOrderReport: "/AllUserOrderReport",
  editProductAdditionalImages: "/EditProductAdditionalImages",
  clearAdditionalImages: "/ClearAdditionalImages",
  getSubmittedUsers: "/GetSubmittedUsers",
  technoInventoryCA: "/technoInventoryCA",
  technoInventoryUSA: "/technoInventoryUSA",
  ssactivewearUSproducts: "/ssactivewearUSproducts",
  ssactivewearCAproducts: "ssactivewearCAproducts",
  alphaInventoryCA: "/alphaInventoryCA",
  alphaInventoryUS: "/alphaInventoryUS",
  getSLAData: "/getSLAData",
  technoInventoryCApaymentProfile: "/technoInventoryCApaymentProfile",
  technoInventoryUSApaymentProfile: "/technoInventoryUSApaymentProfile",
  technoInventoryCASubmitOrder: "/technoInventoryCASubmitOrder",
  technoInventoryUSASubmitOrder: "/technoInventoryUSASubmitOrder",
  saveSSActiveWearCAStockOrderData: "/SaveSSActiveWearCAStockOrderData",
  saveSSActiveWearUSAStockOrderData: "/SaveSSActiveWearUSAStockOrderData",
  getStockSubmittedOrders: "/getStockSubmittedOrders",
  DuplicateOrder: "/DuplicateOrder",
  getAllSubmittedUsersCSV: "/GetAllSubmittedUsersCSV",
  uploadArtToCloudinary: "/uploadArtToCloudinary",
  editAdditionalTechniquesArtSettings: "/EditAdditionalTechniquesArtSettings",
  editPrintAreas: "/EditPrintAreas",
  allSubmittedOrdersForProduct: "AllSubmittedOrdersForProduct",
  checkAppVersion: "/CheckAppVersion",
  orderDurasoftNotification: "/OrderDurasoftNotification",
  orderAOPNotification: "/OrderAOPNotification",
  allWebPages: "/AllWebPages",
  webPage: "/WebPage",
  updateSection: "/UpdateSection",
  createNotification: "/CreateNotification",
  editNotification: "/EditNotification",
  getAllNotifications: "/GetAllNotifications",
  deleteNotification: "/DeleteNotification",
  checkUserStatus: "/CheckUserStatus",
  clearMocks: "/ClearMocks",
  backgroundRemove: "/backgroundRemove",
  setBackgroundBurnQuota: "/setBackgroundBurnQuota",
  bcAllOrders: "/bcAllOrders",
  bcThisOrder: "/bcThisOrder",
  wooAuth: "/wooAuth",
  wooAllOrders: "/wooAllOrders",
  getShopifyProducts: "/getShopifyProducts",
  getWooProducts: "/getWooProducts",
  syncProduct: "/syncProduct",
  syncedProducts: "/syncedProducts",
  getShopifyCountProducts: "/getShopifyCountProducts",
  syncedProductsCount: "/syncedProductsCount",
  getShopifyMoreProducts: "/getShopifyMoreProducts",
  getWooMoreProducts: "/getWooMoreProducts",
  syncedProduct: "/syncedProduct",
  updateSyncedProduct: "/updateSyncedProduct",
  getThisProduct: "/getThisProduct",
  getShopifyOrdersCount: "/getShopifyOrdersCount",
  getShopifyMoreOrders: "/getShopifyMoreOrders",
  wooMoreOrders: "/wooMoreOrders",
  automationToggle: "/automationToggle",
  setPrintProvider: "/setPrintProvider",
  bulkStripeCharge: "/bulkStripeCharge",
  closeShopifyOrder: "/closeShopifyOrder",
  closeWooOrder: "/closeWooOrder",
  closeBcOrder: "/closeBcOrder",
  allSyncedProducts: "/allSyncedProducts",
  saveCard: "/saveCard",
  deleteUnSyncedOrders: "/deleteUnSyncedOrders",
  resaleCertificate: "/resaleCertificate",
  bigCartelAllOrders: "/bigCartelAllOrders",
  closeBigCartelOrder: "/closeBigCartelOrder",
  changeOrderStatus: "/changeOrderStatus",
  sendEmail: "/sendEmail",
  deleteBulkOrders: "/deleteBulkOrders",
  printPreviewOrders: "/printPreviewOrders",
  IPSignUp: "/IPSignUp",
  sendIPConfirmationEmail: "/sendIPConfirmationEmail",
  SaveIpArtFiles: "/SaveIpArtFiles",
  SavedIpArtFiles: "/SavedIpArtFiles",
  DeleteIpArtFile: "/DeleteIpArtFile",
  decisionIPArtFile: "/DecisionIpArtFile",
  searchIpArtFile: "/SearchIpArtFile",
  getIpUsers: "/GetIpUsers",
  verifyArtFile: "/VerifyArtFile",
  adminVerifyArtFile: "/AdminVerifyArtFile",
  getDesignsByPublicId: "/GetDesignsByPublicId",
  searchDesigns: "/SearchDesigns",
  googleFonts: "/getGoogleFonts",
  getAdvancedDesigns: "/GetAdvancedDesigns",
  saveAdvanceArtFiles: "/SaveAdvanceArtFiles",
  deleteAdvanceArtFiles: "/DeleteAdvanceArtFiles",
  packingUpdate: "/PackingUpdate",
  saveAllCosts: "/SaveAllCosts",
  yotiCredentials: "/YotiCredentials",
  userYotiVerified: "/UserYotiVerified",
  yotiCheckStatus: "/YotiCheckStatus",
  yotiVerificationComplete: "/YotiVerificationComplete",
  addDashboardSection: "/AddDashboardSection",
  updateDashboardSection: "/UpdateDashboardSection",
  deleteDashboardSection: "/DeleteDashboardSection",
  checkJWTtoken: "/CheckJWTtoken",
  allCustomGraphicsUsers: "/AllCustomGraphicsUsers",
  getAllCustomGraphics: "/GetAllCustomGraphics",
  getCustomGraphicsTags: "/GetCustomGraphicsTags",
  getCustomGraphics: "/GetCustomGraphics",
  getSSActiveWearProductData: "/getSSActiveWearProductData",
  editProductSubstituteInformation: "/EditProductSubstituteInformation",
  updateScreenPrintingInfo: "/UpdateScreenPrintingInfo",
  getAllScreenPrinting: "/GetAllScreenPrinting",
  deleteScreenPrintingInfo: "/DeleteScreenPrintingInfo",
  getShipstationCarriers: "/getShipstationCarriers",
  getShipstationRates: "/getShipstationRates",
  editSKUInformation: "/EditSKUInformation",
  getAUSplantStock: "/getAUSplantStock",
  updatedResaleCertificate: "/updatedResaleCertificate",
  orderTuffPrintTransferNotification: "/OrderTuffPrintTransferNotification",
  orderGiftMessageNotification: "/OrderGiftMessageNotification",
  generateImage: "/v3/generateImage",
  getAIDesigns: "/GetAIDesigns",
  checkAIDescriptionForBrand: "/v3/checkAIDescriptionForBrand",
  checkImageForBrand: "/v3/checkImageForBrand",
};

export { baseURL, requests, imagesBaseURL };
