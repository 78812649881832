import styled from "styled-components";

const Container = styled.div`
  position: relative;
  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  .MuiDialogTitle-root {
    .MuiTypography-h6 {
      font-family: "Inter700";
      color: #1594db;
      text-align: center;
    }
  }
  .MuiDialogContent-root,
  .MuiDialogContentText-root {
    color: #000;
    font-family: "Inter400";
    font-size: 16px;
  }

  .MuiDialogActions-root {
    button {
      background-color: rgb(21, 148, 219);
      color: rgb(255, 255, 255);
      margin: 5px;
      box-shadow: none;
      text-transform: none;
      border-radius: 6px;
      height: 48px;
      font-family: Inter500;
      font-size: 16px;
      :disabled {
        opacity: 0.5;
      }
    }
  }

  &.create-notification {
    .rdw-editor-main {
      padding: 15px;
    }
    .public-DraftStyleDefault-ltr {
      margin: 0;
    }
    .rdw-editor-wrapper {
      margin-top: 25px;
      border-radius: 4px;
      border: 1px solid #c4c4c4;
    }
    .rdw-editor-toolbar {
      border: none;
      margin-bottom: 0;
      border-bottom: 0;
    }
  }
  &.add-store {
    .MuiDialogTitle-root {
      padding-bottom: 0;
    }
    .MuiDialogContent-root {
      h6 {
        margin: 0;
        font-size: 14px;
        line-height: 32px;
      }
      text-align: center;
    }
    .MuiDialogActions-root {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0 30px;
      margin: 0 50px;

      img {
        width: 64px;
        height: 64px;
        margin-right: 30px;
      }

      > div {
        display: flex;
        align-items: center;
        margin: 15px 0;
        flex-wrap: wrap;
        justify-content: center;

        :first-child {
          button {
            color: #ff5a00;
            border: 1px solid #ff5a00;
          }
        }
        :nth-child(2) {
          button {
            color: #5e8d3d;
            border: 1px solid #5e8d3d;
          }
        }
        :nth-child(3) {
          button {
            color: #121118;
            border: 1px solid #121118;
          }
        }
        :nth-child(4) {
          button {
            color: #7f54b3;
            border: 1px solid #7f54b3;
          }
        }

        :nth-child(5) {
          button {
            color: #222222;
            border: 1px solid #222222;
          }
        }
      }

      button {
        background-color: transparent;
        color: rgb(255, 255, 255);
        margin: 5px;
        box-shadow: none;
        text-transform: none;
        border-radius: 6px;
        height: 48px;
        font-family: Inter500;
        font-size: 16px;
        width: 300px;
      }

      @media (max-width: 800px) {
        grid-template-columns: 1fr;
      }

      @media (max-width: 405px) {
        > div {
          flex-direction: column;
        }
        img {
          margin-right: 0;
        }
      }
    }
  }
  &.retrieve-orders {
    width: 88vw;
    max-width: 600px;
  }
  &.about-us {
  }
  &.edit-dtg-plus {
    /* .MuiDialogTitle-root {
      padding-bottom: 0;
    } */
    .MuiDialogContent-root {
      h6 {
        margin: 0;
        font-size: 14px;
        line-height: 32px;
      }
      text-align: center;
    }
    .MuiDialogActions-root {
      padding: 8px 24px;
      button {
        margin: 0;
      }
    }
  }

  &.edit-product-dialog {
    .MuiDialogTitle-root {
      border-bottom: 2px solid #efefef;
      .MuiTypography-h6 {
        display: grid;
        grid-template-columns: 1fr 240px;
        align-items: center;
        text-align: left;
      }
    }
    .MuiDialogContent-root {
      max-height: calc(100vh - 200px);
    }
    @media (max-width: 550px) {
      .MuiDialogTitle-root {
        .MuiTypography-h6 {
          grid-template-columns: 1fr;
          grid-gap: 10px;
        }
      }
    }
  }
`;

export { Container };
