import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { dateFilterAllSubmittedOrders } from 'actions/orders';
import { getSubmittedUsers } from 'actions/user';
import Filters from 'pages/sales-report/Filters';
import Breadcrumb from 'pages/sales-report/Breadcrumb';
import { Container, PaginationWrapper } from 'styles/pages/sales-report';
import SalesTable from 'pages/sales-report/table';
import Pagination from 'components/Pagination';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'shared/constants/routes';

const SalesReport = () => {
  const location = useLocation();
  const isAdmin = location?.pathname === ROUTES.ADMIN_SALES_REPORT.LINK;
  const dispatch = useDispatch();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await dispatch(getSubmittedUsers());
      setUsers(response?.users);
    };
    if (!users?.length) {
      fetchUsers();
    }
  }, [dispatch, users]);

  useEffect(() => {
    if (!isAdmin) {
      setUser({ User: emailid });
    }
  }, [isAdmin, emailid]);

  return (
    <Container>
      <Breadcrumb />
      <Filters
        isAdmin={isAdmin}
        startDate={startDate}
        endDate={endDate}
        users={users}
        user={user}
        onChange={(key, value) => {
          if (key === 'startDate') {
            setStartDate(value);
          } else if (key === 'endDate') {
            setEndDate(value);
          } else {
            setUser(value);
          }
        }}
        generateReport={async () => {
          setCurrentPage(1);
          const response = await dispatch(
            dateFilterAllSubmittedOrders({
              skip: 0,
              take: 10,
              startDate: moment(startDate).subtract(1, 'days'),
              endDate: moment(endDate),
              user: user?.User,
            })
          );
          if (response?.error) {
            return;
          }
          setData(response);
        }}
        data={data}
      />
      {data?.orders && <SalesTable data={data?.orders} />}

      {data?.orderCounts?.TotalOrders > 10 && (
        <PaginationWrapper>
          <Pagination
            totalCount={data?.orderCounts?.TotalOrders}
            take={10}
            page={currentPage}
            onChange={async (e, v) => {
              setCurrentPage(v);
              const response = await dispatch(
                dateFilterAllSubmittedOrders({
                  skip: (v - 1) * 10,
                  take: 10,
                  startDate: moment(startDate).subtract(1, 'days'),
                  endDate: moment(endDate),
                  user: user?.User,
                })
              );
              if (response?.error) {
                return;
              }
              setData(response);
            }}
          />
        </PaginationWrapper>
      )}
    </Container>
  );
};

export default SalesReport;
