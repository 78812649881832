import React, { useState, useEffect } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import TextField from "components/TextField";
import Button from "components/Button";
import { ROUTES } from "shared/constants/routes";
import { checkUserStatus } from "actions/user";
import CryptoJS from "crypto-js";
import Checkbox from "components/CheckBox";
import CardImage from "assets/images/cardImage.png";
import DeleteIcon from "@material-ui/icons/Delete";
import StripeLogo from "assets/images/stripe.jpeg";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { getStores } from "actions/stores";
import ErrorMessage from "components/ErrorMessage";
import {
  Container,
  PayableAmount,
  CardField,
  Footer,
  Cvv,
  PaymentOption,
  SavedCard,
  Body,
  DeleteCard,
  CardWrapper,
  StripeImage,
} from "styles/components/Stripe";
import { USD_TO_CAD_CONVERSION_RATE } from "constants/currency";

// const PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM });

const Stripe = ({
  order,
  open = false,
  hide,
  cards,
  payableAmount,
  removeCards,
  showSaveCardCheckbox = true,
  multiple = false,
  vatIncluded = false,
  marketplace,
}) => {
  const dispatch = useDispatch();
  const [selectedPaymentOption, setPaymentOption] = useState(undefined);
  const [paymentInCanadian, setPaymentInCanadian] = useState(false);
  const [isVatIncluded, setVatIncluded] = useState(vatIncluded);
  const [showDisclaimer, setDisclaimer] = useState(true);
  const currency = useSelector((state) => state?.currency?.value);
  // const [userData, setUserData] = useState(null);

  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const fullName = useSelector((state) => state?.auth?.profile?.FullName);

  useEffect(() => {
    // setPaymentOption(
    //   !!cards?.length && marketplace !== "Shirtly"
    //     ? "SavedPaymentOptions"
    //     : marketplace !== "Shirtly"
    //     ? "OtherPaymentOptions"
    //     : "Paypal"
    // );

    setPaymentOption(
      !!cards?.length && marketplace !== "Shirtly"
        ? "SavedPaymentOptions"
        : "OtherPaymentOptions"
    );
  }, [cards]);

  // useEffect(() => {
  //   const getUserData = async () => {
  //     if (!userData) {
  //       const data = await dispatch(checkUserStatus(emailid));
  //       setUserData(() => data);
  //     }
  //   };
  //   getUserData();
  // }, [dispatch, emailid, userData]);

  useEffect(async () => {
    await dispatch(getStores(emailid));
  }, [dispatch, emailid]);

  const [state, setState] = useState({
    cardNumber: undefined,
    cardExpMonth: undefined,
    cardExpYear: undefined,
    cvv: undefined,
    iossNumber: undefined,
  });

  const [saveCard, setSaveCard] = useState(false);

  const disabled =
    !state?.cardNumber ||
    state?.cardNumber?.trim()?.length < 12 ||
    !state?.cardExpMonth ||
    state?.cardExpMonth?.trim()?.length < 2 ||
    !state?.cardExpYear ||
    state?.cardExpYear?.trim()?.length < 2 ||
    !state?.cvv ||
    state?.cvv?.trim()?.length < 3;

  const payableAmountForMultipleInCad = (orders) => {
    let cost = [];
    for (let i = 0; i < orders?.length; i++) {
      cost.push((orders[i] * USD_TO_CAD_CONVERSION_RATE).toFixed(2));
    }
    return cost.join(", ");
  };

  return (
    <React.Fragment>
      {showDisclaimer ? (
        <Dialog
          maxWidth="xl"
          open={showDisclaimer}
          onClose={() => _.isFunction(hide) && hide()}
        >
          <Container>
            <PayableAmount>
              <h2>International Orders</h2>
            </PayableAmount>
            <Body style={{ fontSize: 20, lineHeight: "35px" }}>
              <p>
                Tracked shipping option is not available for all International
                destinations. Service restrictions applied by Canada Post may
                prevent us to generate a functional tracking number for the
                order. Any order effected by these restrictions cannot be
                tracked by us and will not be refunded in case of loss in
                transit.
              </p>
              <p>
                For complete list of countries and the services offered, please
                review the following links. Pleas note, only Tracked Packet -
                International options allows tracking for an international
                destination.
              </p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.canadapost-postescanada.ca/cpc/doc/en/aboutus/service-alerts/destinations-to-which-service-is-suspended.pdf"
              >
                https://www.canadapost-postescanada.ca/cpc/doc/en/aboutus/service-alerts/destinations-to-which-service-is-suspended.pdf
              </a>
            </Body>
            <Button
              title="Proceed To Payment"
              containerStyle={{
                backgroundColor: "#1594db",
                color: "#fff",
                float: "right",
                marginBottom: 20,
                marginRight: 20,
                boxShadow: "none",
                textTransform: "capitalize",
                fontSize: 16,
                height: 48,
                borderRadius: 6,
              }}
              onClick={() => setDisclaimer(false)}
            />
          </Container>
        </Dialog>
      ) : (
        <Dialog
          maxWidth="xl"
          open={open}
          onClose={() => _.isFunction(hide) && hide()}
        >
          <Container>
            {isVatIncluded ? (
              <PayableAmount>
                <h2>Enter IOSS Number</h2>
              </PayableAmount>
            ) : (
              <PayableAmount>
                <h2>Amount Payable</h2>
                {multiple ? (
                  <h2 style={{ maxWidth: 500, overflowWrap: "anywhere" }}>
                    {paymentInCanadian || currency === "CAD"
                      ? payableAmountForMultipleInCad(payableAmount)
                      : payableAmount.join(", ")}
                    &nbsp;
                    {paymentInCanadian ? "CAD" : "USD"}
                  </h2>
                ) : (
                  <h2>
                    {paymentInCanadian || currency === "CAD"
                      ? (payableAmount * USD_TO_CAD_CONVERSION_RATE).toFixed(2)
                      : payableAmount}
                    &nbsp;
                    {paymentInCanadian ? "CAD" : "USD"}
                  </h2>
                )}
              </PayableAmount>
            )}

            {isVatIncluded ? (
              <Body>
                <div
                  style={{
                    margin: "20px 0 0 0",
                    flexDirection: "row",
                    display: "flex",
                    gridGap: 20,
                  }}
                >
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="IOSS Number"
                    value={state?.iossNumber || ""}
                    onChange={(iossNumber) =>
                      setState({ ...state, iossNumber })
                    }
                  />

                  <Button
                    title="Next"
                    disabled={!state?.iossNumber}
                    containerStyle={{
                      opacity: !state?.iossNumber ? 0.5 : 1,
                      backgroundColor: "#1594db",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                    onClick={() => setVatIncluded(false)}
                  />
                </div>
                <ErrorMessage className="warning">
                  <p>
                    Note : If you will not provide IOSS Number, please type
                    <b>'NA'</b> and your customer will get charged on receiving
                    the order. Please click Next to proceed.
                  </p>
                </ErrorMessage>

                <ErrorMessage className="warning">
                  <p>
                    You will get the IOSS Number for this order from your
                    respective E-Commerce platform.
                  </p>
                </ErrorMessage>
              </Body>
            ) : (
              <Body>
                <RadioGroup
                  row
                  value={selectedPaymentOption}
                  onChange={(e) => {
                    setPaymentOption(e.target.value);
                    if (e.target.value === "Paypal") {
                      setPaymentInCanadian(false);
                    }
                  }}
                >
                  <PaymentOption>
                    {!!cards?.length &&
                      (order?.MarketplaceType !== "Shirtly" ||
                        emailid === "support@shirtly.com") && (
                        <FormControlLabel
                          value="SavedPaymentOptions"
                          control={<Radio />}
                          label="Saved Payment Options"
                        />
                      )}
                    {(order?.MarketplaceType !== "Shirtly" ||
                      emailid === "support@shirtly.com") && (
                      <FormControlLabel
                        value="OtherPaymentOptions"
                        control={<Radio />}
                        label="Other Payment Options"
                      />
                    )}
                    {/* {!multiple && (
                      <FormControlLabel
                        value="Paypal"
                        control={<Radio />}
                        label="Paypal"
                      />
                    )} */}
                  </PaymentOption>
                </RadioGroup>
                {selectedPaymentOption !== "Paypal" && currency !== "CAD" && (
                  <div
                    style={{
                      padding: "0px 10px 10px",
                      width: 240,
                      margin: "0 auto",
                    }}
                  >
                    <Checkbox
                      value={paymentInCanadian}
                      onChange={() => setPaymentInCanadian(!paymentInCanadian)}
                      label="Pay in Canadian (CAD)"
                    />
                  </div>
                )}

                <ErrorMessage className="warning">
                  <p>
                    Note: Our current processing timeline is 3 to 7 business
                    days. The production time is from 8 AM to 1 PM EST. Any
                    order placed after 4 PM EST will be considered the next
                    business day and processing timeline will be applied
                    appropriately.
                  </p>
                  <p>
                    Durasoft Decoration Fulfillment times: 8 – 12 business days.
                  </p>
                  <p>DTG Decoration Fulfillment times 3 – 7 business days.</p>
                </ErrorMessage>

                {selectedPaymentOption === "SavedPaymentOptions" ? (
                  <div>
                    <h2>Select card to pay</h2>
                    {cards?.map &&
                      cards.map((card, index) => {
                        const regex = /\b(?:\d{4}[ -]?){3}(?=\d{4}\b)/gm;
                        const mask = `**** **** **** `;
                        const cardNo = CryptoJS.AES.decrypt(
                          card.cardNumber,
                          "shirtly"
                        ).toString(CryptoJS.enc.Utf8);

                        return (
                          <CardWrapper
                            key={`card${index}`}
                            style={{ display: "flex" }}
                          >
                            <SavedCard
                              onClick={() => {
                                hide({
                                  cardDetails: card,
                                  paymentInCanadian:
                                    paymentInCanadian || currency === "CAD",
                                  iossNumber: state?.iossNumber,
                                });
                              }}
                            >
                              <img src={CardImage} alt="CardImage" />
                              <div>
                                <h3>{cardNo.replace(regex, mask)}</h3>
                                <span>Saved Card</span>
                              </div>
                            </SavedCard>

                            {_.isFunction(removeCards) && (
                              <DeleteCard>
                                <DeleteIcon
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => removeCards(card)}
                                />
                              </DeleteCard>
                            )}
                          </CardWrapper>
                        );
                      })}
                  </div>
                ) : selectedPaymentOption === "OtherPaymentOptions" ? (
                  <React.Fragment>
                    <CardField>
                      <TextField
                        fullWidth
                        size="small"
                        type="number"
                        placeholder="Card Number"
                        value={state?.cardNumber || ""}
                        onChange={(cardNumber) => {
                          if (cardNumber?.toString()?.trim()?.length <= 16) {
                            setState({ ...state, cardNumber });
                          }
                        }}
                      />
                      <Cvv>
                        <TextField
                          fullWidth
                          size="small"
                          type="number"
                          value={state?.cardExpMonth || ""}
                          placeholder="Exp Month"
                          onChange={(cardExpMonth) => {
                            if (cardExpMonth?.toString()?.trim()?.length <= 2) {
                              setState({ ...state, cardExpMonth });
                            }
                          }}
                        />
                        <TextField
                          fullWidth
                          value={state?.cardExpYear || ""}
                          size="small"
                          type="number"
                          placeholder="Exp Year"
                          onChange={(cardExpYear) => {
                            if (cardExpYear?.toString()?.trim()?.length <= 2) {
                              setState({ ...state, cardExpYear });
                            }
                          }}
                        />
                        <TextField
                          fullWidth
                          size="small"
                          type="number"
                          placeholder="CVV"
                          value={state?.cvv || ""}
                          onChange={(cvv) => {
                            if (cvv?.toString()?.trim()?.length <= 4) {
                              setState({ ...state, cvv });
                            }
                          }}
                        />
                      </Cvv>
                    </CardField>

                    {showSaveCardCheckbox && (
                      <Checkbox
                        value={saveCard}
                        onChange={() => setSaveCard(!saveCard)}
                        label="Save Card for future transaction"
                      />
                    )}
                    <Footer>
                      <Button
                        title="Pay"
                        disabled={disabled}
                        onClick={() => {
                          hide({
                            saveCard,
                            iossNumber: state?.iossNumber,
                            cardDetails: {
                              cardNumber: CryptoJS.AES.encrypt(
                                state.cardNumber,
                                "shirtly"
                              ).toString(),
                              cardExpMonth: CryptoJS.AES.encrypt(
                                state.cardExpMonth,
                                "shirtly"
                              ).toString(),
                              cardExpYear: CryptoJS.AES.encrypt(
                                state.cardExpYear,
                                "shirtly"
                              ).toString(),
                              cvv: CryptoJS.AES.encrypt(
                                state.cvv,
                                "shirtly"
                              ).toString(),
                            },
                            paymentInCanadian:
                              paymentInCanadian || currency === "CAD",
                          });
                        }}
                      />
                    </Footer>
                    <StripeImage>
                      <img src={StripeLogo} alt="stripe-image" />
                    </StripeImage>
                  </React.Fragment>
                ) : (
                  // <PayPalButton
                  // onApprove={(data, actions) => {
                  //   actions.order.capture();
                  //   hide({
                  //     orderId: data?.orderID,
                  //     paypal: true,
                  //     iossNumber: state?.iossNumber,
                  //   });
                  // }}
                  //   createOrder={(data, actions) =>
                  //     actions.order.create({
                  // purchase_units: [
                  //   {
                  //     amount: {
                  //       value: paymentInCanadian
                  //         ? (payableAmount * USD_TO_CAD_CONVERSION_RATE).toFixed(2)
                  //         : payableAmount,
                  //     },
                  //   },
                  // ],
                  //     })
                  //   }
                  // />
                  <>
                    <PayPalScriptProvider
                      options={{
                        "client-id":
                          "AUYtBzJpOsEyfQme15Pt7D4Q5Wo0Ny8bZZ9azUCR372bmH0x2J8oz6fQaGUunIhBroxHDJZ9ViEIMSC1",
                      }}
                    >
                      <PayPalButtons
                        onApprove={(data, actions) => {
                          actions.order.capture();
                          hide({
                            orderId: data?.orderID,
                            paypal: true,
                            iossNumber: state?.iossNumber,
                          });
                        }}
                        createOrder={async (data, actions) => {
                          const userData = await dispatch(
                            checkUserStatus(emailid)
                          );

                          return actions.order.create({
                            intent: "CAPTURE",
                            payer: {
                              name: {
                                given_name: fullName
                                  .split(" ")[0]
                                  ?.replace(/[^\w\s]/gi, ""),
                                surname:
                                  fullName.split(" ")?.length > 1
                                    ? fullName
                                        .split(" ")[1]
                                        ?.replace(/[^\w\s]/gi, "")
                                    : fullName
                                        .split(" ")[0]
                                        ?.replace(/[^\w\s]/gi, ""),
                              },
                              address: {
                                address_line_1:
                                  userData?.User?.AddressLineOne?.replace(
                                    /[^\w\s]/gi,
                                    ""
                                  ) || "",
                                address_line_2:
                                  userData?.User?.AddressLineTwo?.replace(
                                    /[^\w\s]/gi,
                                    ""
                                  ) || "",
                                admin_area_2:
                                  userData?.User?.City?.replace(
                                    /[^\w\s]/gi,
                                    ""
                                  ) || "",
                                admin_area_1:
                                  userData?.User?.StateProvince?.replace(
                                    /[^\w\s]/gi,
                                    ""
                                  ) || "",
                                postal_code:
                                  userData?.User?.ZipPostal?.replace(
                                    /[^\w\s]/gi,
                                    ""
                                  ) || "",
                                country_code:
                                  userData?.User?.Country?.replace(
                                    /[^\w\s]/gi,
                                    ""
                                  ) || "",
                              },
                            },
                            purchase_units: [
                              {
                                payment_descriptor: "Payment Shirtly Inc",
                                invoice_number: `Invoice_Number_${order?.ReceiptID}`,
                                shipping: {
                                  method:
                                    order?.ShippingOptions[0]?.DisplayName?.replace(
                                      /[^\w\s]/gi,
                                      ""
                                    ),
                                  name: {
                                    full_name: order?.BuyerName?.replace(
                                      /[^\w\s]/gi,
                                      ""
                                    ),
                                  },
                                  address: {
                                    address_line_1:
                                      order?.AddressLineOne?.replace(
                                        /[^\w\s]/gi,
                                        ""
                                      ),
                                    address_line_2:
                                      order?.AddressLineTwo?.replace(
                                        /[^\w\s]/gi,
                                        ""
                                      ),
                                    admin_area_2: order?.City?.replace(
                                      /[^\w\s]/gi,
                                      ""
                                    ),
                                    admin_area_1: order?.StateProvince?.replace(
                                      /[^\w\s]/gi,
                                      ""
                                    ),
                                    postal_code: order?.ZipPostal?.replace(
                                      /[^\w\s]/gi,
                                      ""
                                    ),
                                    country_code: order?.CountryString?.replace(
                                      /[^\w\s]/gi,
                                      ""
                                    ),
                                  },
                                },
                                reference_id: order?.ReceiptID?.replace(
                                  /[^\w\s]/gi,
                                  ""
                                ),
                                description: `Shirtly Order for (${order?.ReceiptID})`,
                                amount: {
                                  value:
                                    paymentInCanadian || currency === "CAD"
                                      ? (
                                          payableAmount *
                                          USD_TO_CAD_CONVERSION_RATE
                                        ).toFixed(2)
                                      : payableAmount,
                                  currency_code:
                                    paymentInCanadian || currency === "CAD"
                                      ? "CAD"
                                      : "USD",
                                },
                                payee: {
                                  email: emailid,
                                },
                              },
                            ],
                          });
                        }}
                        onError={() =>
                          alert(
                            "Please remove special characters from the shipping address \nSpecial Characters are : !@#$%^^&&**()./,?>< \nAfter removing special characters kindly reload the application."
                          )
                        }
                      />
                    </PayPalScriptProvider>

                    <ErrorMessage className="warning">
                      <p>
                        Billing Address is used from&nbsp;
                        <ReactRouterLink to={ROUTES.ACCOUNT_SETTINGS.LINK}>
                          Account Settings
                        </ReactRouterLink>
                      </p>
                    </ErrorMessage>
                  </>
                )}
              </Body>
            )}
          </Container>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default Stripe;
