import styled, { css } from 'styled-components';

const Container = styled.div`
  padding: 0 20px;
`;

const Wrapper = styled.div``;
const SearchWrapper = styled.div`
  margin-right: 0;
  margin-left: auto;
  display: grid;
  grid-template-columns: 1fr 260px 260px;
  grid-gap: 20px;
  @media (max-width: 950px) {
    grid-template-columns: 1fr;
  }
`;
const Heading = styled.div`
  font-size: 20px;
  color: #2789c0;
  padding: 10px 0;
  border-radius: 3px;
  margin-bottom: 20px;
  font-family: 'Inter600';
`;
const OrderWrapper = styled.div`
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  display: grid;
  grid-gap: 20px;
`;
const OrderCard = styled.div`
  background: #fff;
  padding: 40px 20px 20px;
  border-radius: 10px;
  position: relative;
  transition: all ease-out 0.25s;
  border: 1px solid transparent;
  overflow: hidden;
  :hover {
    cursor: pointer;
    box-shadow: 0px 10px 10px #d9d9d9, -5px -5px 10px #ffffff;
    background: #f2f2f2;
  }
  > h3 {
    color: #999;
    transition: all ease-out 0.25s;
    padding: 0 4px;
    font-size: 16px;
    margin-top: 0;

    &.status-green {
      margin: 0;
      padding: 5px;
      text-align: center;
      border: 1px dashed green;
      border-radius: 5px;
      color: green;
      margin-top: 10px;
    }
    &.status-orange {
      margin: 0;
      padding: 5px;
      text-align: center;
      border: 1px dashed orange;
      border-radius: 5px;
      color: orange;
      margin-top: 10px;
    }
    &.status-red {
      margin: 0;
      padding: 5px;
      text-align: center;
      border: 1px dashed red;
      border-radius: 5px;
      color: red;
      margin-top: 10px;
    }
  }
  > h5 {
    margin-bottom: 0;
    margin-top: 12px;
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  span {
    font-weight: bold;
    display: inline-flex;
    color: #666;
    align-items: center;
    margin: 3px 0;
    text-transform: uppercase;
    border: 1px solid #f3f3f3;
    padding: 5px 11px;
    border-radius: 20px;
    box-shadow: 4px 4px 7px #c9c9c9, -4px -4px 7px #ffffff;
    font-size: 11px;
    letter-spacing: 1px;
    img {
      width: 15%;
      margin-right: 5px;
      border-radius: 5px;
    }
  }
`;
const Orders = styled.div`
  padding: 15px 0;
`;

const PaginationWrapper = styled.div`
  margin: 20px 0;
`;

const FilterWrapper = styled.div`
  display: flex;
  margin: 20px 0;
  grid-gap: 30px;
`;

const DaysAgo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  padding: 5px;
  border-bottom-right-radius: 10px;
  font-weight: bold;

  ${(props) =>
    props.background &&
    css`
      background: ${props.background};
    `}

  ${(props) =>
    props.background === 'red' &&
    css`
      animation: glow 1s ease-in-out infinite alternate;

      @keyframes glow {
        from {
          text-shadow: 0 0 8px #fff, 0 0 16px #fff, 0 0 24px #ca060c,
            0 0 32px #ca060c, 0 0 40px #ca060c, 0 0 48px #ca060c,
            0 0 56px #ca060c;
        }

        to {
          text-shadow: 0 0 4px #fff, 0 0 8px #ff0000, 0 0 12px #ff0000,
            0 0 16px #ff0000, 0 0 20px #ff0000, 0 0 24px #ff0000,
            0 0 28px #ff0000;
        }
      }
    `}
`;

const ProductsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 594px) {
    > div {
      width: 100%;
      .MuiFormControl-root {
        width: 100%;
      }
    }
  }
`;
const ProductButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 15px;
  button {
    font-size: 14px;
    text-transform: capitalize;
    background-color: #1594db;
    color: #fff;
    :hover {
      background-color: #1594db;
    }
  }
  @media (max-width: 594px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`;
export {
  Container,
  Wrapper,
  SearchWrapper,
  Heading,
  OrderWrapper,
  OrderCard,
  Orders,
  PaginationWrapper,
  FilterWrapper,
  DaysAgo,
  ProductsWrapper,
  ProductButtonWrapper,
};
