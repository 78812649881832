import styled from 'styled-components';

const Head = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 30px;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  right: 20px;
  flex-wrap: wrap;
  button {
    height: 48px;
    color: #fff;
    background-color: #1594db;
    text-transform: capitalize;
    :hover {
      background-color: #1594db;
    }
  }
  @media (max-width: 1100px) {
    position: unset;
    justify-content: flex-start;
  }
`;

const PaginationWrapper = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: flex-end;
`;
const Container = styled.div`
  padding: 0 20px;
  position: relative;
`;

const TableContainer = styled.div`
  margin: 30px 0;
  box-shadow: 4px 4px 10px #d9d9d9, -4px -4px 10px #fff;
  overflow-x: auto;
  border-radius: 10px;
  .MuiTableRow-root {
    background: linear-gradient(60deg, #fff, #eee);
  }
  .MuiTableCell-stickyHeader {
    color: #1594db;
    font-size: 14px;
    font-family: Inter600;
    background-color: #fff;
  }
  .MuiTableCell-body {
    padding: 13px 16px;
    font-size: 14px;
    font-family: Inter400;
  }
`;

const DateWrapper = styled.div`
  button {
    background-color: transparent;
    color: #1594db;
    :hover {
      background-color: transparent;
      color: #1594db;
    }
  }
  .MuiFormControl-marginNormal {
    margin: 0;
  }
  .MuiDialogActions-root {
    background-color: #1594db;
  }
`;
export { Head, PaginationWrapper, Container, TableContainer, DateWrapper };
