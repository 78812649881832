import React from 'react';
import _ from 'lodash';
import Button from 'components/Button';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { Filters } from 'styles/pages/browse-catalog/sideBar.js';

const FiltersHeader = ({ resetFilters }) => (
  <Filters>
    <Button
      onClick={() => _.isFunction(resetFilters) && resetFilters()}
      containerStyle={{
        fontSize: '12px',
        borderRadius: '50px',
        padding: '3px 13px',
        lineHeight: '1.5',
        textTransform: 'inherit',
        color: '#ffffff',
        background: '#1594db',
        border: '1px solid #1594db',
        fontFamily: 'Inter500',
      }}
      startIcon={<RotateLeftIcon />}
      variant='text'
      title='Reset all filters'
    />
  </Filters>
);

export default FiltersHeader;
