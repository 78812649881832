import React from 'react';
import { useHistory } from 'react-router-dom';
import Image from 'components/Image';
import Tooltip from '@material-ui/core/Tooltip';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import { ROUTES } from 'shared/constants/routes';
import Button from 'components/Button';
import {
  ItemGroup,
  Item,
  ProductImage,
  ProductLink,
  Pagination,
} from 'styles/pages/my-stores/sync-products';

import { SuccessIcon } from 'styles/pages/sync-product';

const Products = ({
  products,
  marketPlaceType,
  shopName,
  fetchMoreProducts,
  totalProductsCount,
  syncedProductCount,
}) => {
  const history = useHistory();
  return (
    <React.Fragment>
      <Pagination>
        <Button
          title='Prev'
          onClick={() => fetchMoreProducts(products[0]?.pre_page_info)}
          disabled={!products?.length || !products[0]?.pre_page_info}
        />
        <div>
          Total Synced Products
          <b style={{ fontSize: 20, marginLeft: 5, marginRight: 5 }}>
            {syncedProductCount}
          </b>
          {marketPlaceType !== 'WooCommerce' && (
            <React.Fragment>
              out of
              <b style={{ fontSize: 20, marginLeft: 5 }}>
                {totalProductsCount}
              </b>
            </React.Fragment>
          )}
        </div>
        <Button
          title='Next'
          disabled={!products?.length || !products[0]?.next_page_info}
          onClick={() => fetchMoreProducts(products[0]?.next_page_info)}
        />
      </Pagination>

      <ItemGroup style={{ marginTop: 15 }}>
        {products.map((item, index) => {
          const productImage =
            marketPlaceType === 'WooCommerce'
              ? item?.images[0]?.src
              : item?.image?.src;
          return (
            <Item
              key={`productsCatalog${index}`}
              style={{ border: item?.synced ? '1px solid green' : 'inherit' }}
              onClick={() => {
                const productId = item?.id;
                history.push(
                  `${ROUTES.MY_STORES_SYNC_PRODUCT.LINK}/${productId}/${marketPlaceType}/${shopName}`
                );
              }}>
              <ProductLink>
                <ProductImage>
                  {!!productImage ? (
                    <Image src={productImage} alt='product-water-mark' />
                  ) : (
                    <WallpaperIcon style={{ color: '#000', fontSize: 50 }} />
                  )}
                </ProductImage>

                {item?.synced && (
                  <Tooltip title='Product already synced'>
                    <SuccessIcon />
                  </Tooltip>
                )}
                <div
                  style={{
                    textAlign: 'center',
                    fontFamily: 'Inter600',
                    fontSize: 16,
                  }}>
                  {item?.title}
                </div>
              </ProductLink>
            </Item>
          );
        })}
      </ItemGroup>
    </React.Fragment>
  );
};

export default Products;
