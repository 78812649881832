import React from "react";
import {
  Dashboard as DashboardIcon,
  Store as StoreIcon,
  MenuBook as MenuBookIcon,
} from "@material-ui/icons";
import SettingsIcon from "@material-ui/icons/Settings";
import CenterFocusWeakIcon from "@material-ui/icons/CenterFocusWeak";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Fireplace from "@material-ui/icons/Fireplace";
import BallotIcon from "@material-ui/icons/Ballot";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import BagIcon from "assets/svg/BagIcon";
import { ROUTES } from "shared/constants/routes";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";

const DRAWER_ITEMS = [
  {
    title: "Dashboard",
    icon: <DashboardIcon />,
    route: ROUTES.DASHBOARD.LINK,
  },
  {
    title: "My Stores",
    icon: <StoreIcon />,
    // route: ROUTES.MY_STORES.LINK,
    childItems: [
      {
        title: ROUTES.MY_STORES.TITLE,
        route: ROUTES.MY_STORES.LINK,
      },
      {
        title: ROUTES.MY_STORES_SYNC_PRODUCTS.TITLE,
        route: ROUTES.MY_STORES_SYNC_PRODUCTS.LINK,
      },
    ],
    // childItems: [
    //   {
    //     title: 'Stores',
    //     route: ROUTES.MY_STORES.LINK,
    //   },
    //   {
    //     title: 'Products',
    //     route: ROUTES.MY_STORES_PRODUCTS.LINK,
    //   },
    // ],
  },
  {
    title: "Orders",
    icon: <BagIcon color="inherit" />,
    route: ROUTES.ORDERS.LINK,
  },
  {
    title: "Product & Pricing",
    icon: <MenuBookIcon />,
    route: ROUTES.CATALOG.LINK,
  },
  {
    title: "Mock-Up Generator",
    icon: <CenterFocusWeakIcon />,
    route: ROUTES.MY_STORES_PRODUCTS.LINK,
    newRelease: true,
  },

  {
    title: ROUTES.BACKGROUND_BURNER.TITLE,
    icon: <Fireplace />,
    route: ROUTES.BACKGROUND_BURNER.LINK,
    newRelease: true,
  },
  {
    icon: <AssessmentIcon />,
    title: ROUTES.USER_SALES_REPORT.TITLE,
    route: ROUTES.USER_SALES_REPORT.LINK,
  },
  {
    icon: <BallotIcon />,
    title: ROUTES.INVENTORY.TITLE,
    route: ROUTES.INVENTORY.LINK,
    inventory: true,
  },
  {
    title: ROUTES.VERIFY_ART_FILES.TITLE,
    route: ROUTES.VERIFY_ART_FILES.LINK,
    icon: <DoneOutlineIcon />,
    newRelease: true,
  },
  {
    title: "Account Settings",
    icon: <SettingsIcon />,
    route: ROUTES.ACCOUNT_SETTINGS.LINK,
  },
];

const getDrawerItems = (emailId) => {
  const isAdmin = emailId === "support@shirtly.com";

  const tempAdmin =
    emailId === "mlamothe@docpartners.ca" || emailId === "west@gowest.ai";

  if (isAdmin) {
    return [
      {
        title: "Admin",
        icon: <SupervisorAccountIcon />,
        childItems: [
          {
            title: ROUTES.WEB_PAGES.TITLE,
            route: ROUTES.WEB_PAGES.LINK,
          },
          {
            title: "Users",
            route: ROUTES.ADMIN_USERS.LINK,
          },
          {
            title: ROUTES.ADMIN_IP_PROGRAM.TITLE,
            route: ROUTES.ADMIN_IP_PROGRAM.LINK,
          },
          {
            title: "All Orders",
            route: ROUTES.WAREHOUSE.LINK,
          },

          {
            title: "Settings",
            route: ROUTES.ADMIN_SETTINGS.LINK,
          },
          {
            title: ROUTES.ADMIN_DTG_PLUS.TITLE,
            route: ROUTES.ADMIN_DTG_PLUS.LINK,
          },
          {
            title: ROUTES.ADMIN_SCREEN_PRINTING.TITLE,
            route: ROUTES.ADMIN_SCREEN_PRINTING.LINK,
          },
          {
            title: "Products",
            route: ROUTES.ADMIN_EDIT_PRODUCT.LINK,
          },
          {
            title: ROUTES.ADMIN_SALES_REPORT.TITLE,
            route: ROUTES.ADMIN_SALES_REPORT.LINK,
          },
          {
            title: ROUTES.ORDER_STOCK.TITLE,
            route: ROUTES.ORDER_STOCK.LINK,
          },
          {
            title: ROUTES.SUBMITTED_ORDER_STOCK.TITLE,
            route: ROUTES.SUBMITTED_ORDER_STOCK.LINK,
          },
          {
            title: ROUTES.FULFILLMENT_DAYS.TITLE,
            route: ROUTES.FULFILLMENT_DAYS.LINK,
          },
          {
            title: ROUTES.NOTIFICATIONS.TITLE,
            route: ROUTES.NOTIFICATIONS.LINK,
          },
        ],
      },

      {
        title: ROUTES.AI_MOCK_UP_GENERATOR.TITLE,
        route: ROUTES.AI_MOCK_UP_GENERATOR.LINK,
        newRelease: true,
      },

      ...DRAWER_ITEMS,
    ];
  }

  if (tempAdmin) {
    return [
      {
        title: ROUTES.AI_MOCK_UP_GENERATOR.TITLE,
        route: ROUTES.AI_MOCK_UP_GENERATOR.LINK,
        newRelease: true,
      },

      ...DRAWER_ITEMS,
    ];
  }

  return DRAWER_ITEMS;
};

export { DRAWER_ITEMS, getDrawerItems };
