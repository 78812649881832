import styled from 'styled-components';

const Container = styled.div`
  padding: 0 20px;
  position: relative;
`;

const FilterWrapper = styled.div`
  padding: 0 0 15px;
  display: flex;
  grid-gap: 40px;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 6px;
  right: 19px;
  width: 100%;
  flex-wrap: wrap;
  width: fit-content;
  .MuiSvgIcon-root {
    color: #1594db;
  }
  @media (max-width: 1340px) {
    position: unset;
  }
  @media (max-width: 850px) {
    justify-content: flex-start;
  }
`;

const PaginationWrapper = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: flex-end;
`;

const TableWrapper = styled.div`
  .container {
    margin: 30px 0;
    box-shadow: 4px 4px 10px #d9d9d9, -4px -4px 10px #fff;
    border-radius: 10px;
  }
`;

export { Container, FilterWrapper, PaginationWrapper, TableWrapper };
