import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getProduct } from 'actions/products';
import Header from 'pages/product-detail/Header';
import SubHeader from 'components/SubHeader';
import Section from 'pages/product-detail/Section';
import ProductSummary from 'pages/product-detail/ProductSummary';
import Loader from 'components/Loader';
import { ROUTES } from 'shared/constants/routes';
import { Container, Wrapper, ErrorHeading } from 'styles/pages/product-detail';

const ProductDetail = () => {
  const products = useSelector((state) => state?.products?.data);
  const [data, setData] = useState({});
  const [fetched, setFetched] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const getProductData = async () => {
      const data = await dispatch(getProduct({ id }));
      setFetched(true);
      const colorsVisible = data?.data?.Colors?.filter(
        (c) => c?.visible !== false
      );
      if (data?.data) {
        setData({
          ...data?.data,
          Product: {
            ...data.data.Product,
            MarketingImagePath: colorsVisible[0]?.Image,
          },
        });
      }
    };

    getProductData();
  }, [dispatch, id]);

  if (!products?.length) return <Loader open={true} />;

  return (
    <Container>
      <SubHeader
        title={ROUTES.PRODUCT_DETAIL.TITLE}
        links={[
          {
            title: ROUTES.HOME.TITLE,
            to: ROUTES.HOME.LINK,
          },
          {
            title: ROUTES.CATALOG.TITLE,
            to: ROUTES.CATALOG.LINK,
          },
          {
            title: ROUTES.BROWSE_CATALOG.TITLE,
            to: ROUTES.BROWSE_CATALOG.LINK,
          },
        ]}
        currentPageTitle={ROUTES.PRODUCT_DETAIL.TITLE}
      />

      <Wrapper>
        <Header data={data} />
        {fetched && !!Object.keys(data).length && (
          <React.Fragment>
            <ProductSummary data={data} />
            <Section
              data={data}
              onImageSelect={(url) => {
                setData({
                  ...data,
                  Product: {
                    ...data.Product,
                    MarketingImagePath: url,
                  },
                });
              }}
              onColorSelect={(color) =>
                setData({
                  ...data,
                  Product: {
                    ...data.Product,
                    MarketingImagePath: color?.Image,
                  },
                })
              }
            />
          </React.Fragment>
        )}
      </Wrapper>

      {fetched && !!!Object.keys(data).length && (
        <ErrorHeading>No Data Found</ErrorHeading>
      )}
    </Container>
  );
};

export default ProductDetail;
