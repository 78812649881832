import React from 'react';
import { StatsContainer, Title, Stats } from 'styles/pages/dashboard/stats';

const StatsBox = ({ stats, title, color = '#000000' }) => (
  <StatsContainer>
    <Title style={{ color }}>{title}</Title>
    <Stats>{stats}</Stats>
  </StatsContainer>
);

export default StatsBox;
