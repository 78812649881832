import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';

const Container = styled.div`
  display: flex;
  padding: 0 20px;
  @media (max-width: 1035px) {
    flex-direction: column;
  }
`;
const UsersTableWrapper = styled.div`
  min-width: 411px;
  max-width: 411px;
  padding-top: 20px;
  @media (max-width: 1035px) {
    max-width: 100%;
  }
`;
const TabsDataWrapper = styled.div`
  width: calc(100vw - 466px);
  padding-left: 25px;
  padding-top: 20px;
  @media (max-width: 1035px) {
    width: 100%;
    padding-left: 0;
  }
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fff;
  align-items: center;
  h2 {
    font-weight: normal;
    margin: 0;
    flex: 1;
  }
  > div {
    flex: 1;
  }
`;
const SearchWrapper = styled.div`
  max-width: 500px;
  width: 100%;
  margin-left: 15px;
`;
const Clear = styled.div`
  background-color: #1594db;
  color: #ffffff;
  border-radius: 3px;
  padding: 9px 13px;
`;

const UserEmail = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 14px;
  > div {
    padding-left: 5px;
    p {
      margin: 0;
      :last-child {
        font-size: 13px;
      }
    }
    .user-name {
      font-size: 15px;
      font-family: 'Inter600';
    }
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  .MuiSwitch-colorPrimary.Mui-checked {
    color: #ffd363;
  }
  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #ffd363;
  }
`;

const SideBarHeader = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #eaeaea;
  h2 {
    font-family: 'Inter600';
    font-size: 16px;
    color: #18191f;
    margin: 0;
    span {
      font-size: 13px;
      font-family: 'Inter800';
    }
  }
  p {
    margin: 0;
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
    color: #18191f;
    cursor: pointer;
    background: #ffd363;
    padding: 5px 14px;
    border-radius: 15px;
    white-space: nowrap;
  }
`;
const HeaderText = styled.div``;
const SideBarBody = styled.div`
  max-height: calc(100vh - 245px);
  overflow: auto;
`;
const SideBarFooter = styled.div``;
const AdminUserContainer = styled.div`
  min-width: 407px;
`;
const Impersonate = styled.div`
  background: #ffd363;
  padding: 2px 10px;
  border-radius: 15px;
  margin-bottom: 5px;
  cursor: pointer;
`;

const UserDetails = styled.div`
  margin-bottom: 15px;
  > div {
    font-size: 13px;
    font-family: 'Inter500';
    :first-child {
      font-size: 15px;
      font-family: 'Inter600';
    }
  }
`;

const Filters = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  p {
    text-align: center;
    background: #ffd363;
    padding: 5px 10px;
    border-radius: 15px;
    margin: 10px 0;
    cursor: pointer;
    font-weight: bold;
  }
`;

const StyledTableCell = styled(TableCell)`
  button {
    color: white;
    margin-left: 5px;
    padding: 5px;
    font-size: 10px;
  }
  .approve {
    background-color: green;
  }

  .decline {
    background-color: red;
  }
`;

export {
  Container,
  Header,
  SearchWrapper,
  Clear,
  UserEmail,
  SwitchWrapper,
  SideBarHeader,
  SideBarBody,
  SideBarFooter,
  AdminUserContainer,
  HeaderText,
  TabsDataWrapper,
  UsersTableWrapper,
  Impersonate,
  UserDetails,
  Filters,
  StyledTableCell,
};
