const COLORS = {
  BODY_TEXT: '#676a6c',
  ORANGE: '#f8ac59',
  WHITE: '#ffffff',
  GREY: '#888888',
  LIGHT_GREY: '#f3f3f4',
  BLUE: '#1594db',
  LIGHTER_GRAY: '#ccc',
  STATE_GRAY: '#efefef',
  DARK_GRAY: '#555555',
  DARKER_GRAY: '#ebebec',
  GREEN: '#1ab394',
  DARK_BLUE: '#1594db',
  NAVY_BLUE: '#2772AE',
  SKY_BLUE: '#DCF2FF',
  RED: '#ea5050',
  LIGHT_RED: '#ed5565',
  BLACK: '#283948',
};

export default COLORS;
