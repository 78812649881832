import React from 'react';
import SubHeader from 'components/SubHeader';
import { ROUTES } from 'shared/constants/routes';
import Step1Image from 'assets/images/connect-big-commerce1.jpeg';
import Step2Image from 'assets/images/connect-big-commerce2.jpeg';
import {
  Container,
  Wrapper,
  Step,
  Title,
} from 'styles/pages/how-to-connect-big-commerce';

const HowToConnectBigCommerce = () => (
  <Container>
    <SubHeader
      title={ROUTES.HOW_TO_CONNECT_BIG_COMMERCE.TITLE}
      links={[
        {
          title: ROUTES.MY_STORES.TITLE,
          to: ROUTES.MY_STORES.LINK,
        },
      ]}
      currentPageTitle='Connect To BigCommerce'
    />

    <Wrapper>
      <Title>
        You can connect your BigCommerce store to Shirtly within few minutes!
        Follow the below steps:
      </Title>
      <Step>
        <span className='step'>Step 1 :</span>
        <span className='line'>
          Go to&nbsp;
          <a href='https://www.bigcommerce.com/apps/'>
            https://www.bigcommerce.com/apps/
          </a>
        </span>
      </Step>

      <Step>
        <span className='step'>Step 2 :</span>
        <span className='line'>Search for Shirtly App.</span>
      </Step>

      <Step>
        <span className='step'>Step 3 :</span>
        <span className='line'>Install and authorize the Shirtly App.</span>
        <div>
          <img src={Step1Image} alt='step1' />
        </div>
      </Step>

      <Step>
        <span className='step'>Step 4 :</span>
        <span className='line'>
          Once installed, your BigCommerce store is connected to Shirtly. Please
          login to Shirtly App with provided username and temporary
          password..&nbsp;
          <b>
            We highly recommend you to change the password on Shirtly App after
            login with temporary credentials.&nbsp;
          </b>
          If you are already registered with Shirtly App with the same Email you
          have on BigCommerce then you will Login with the same credentials.
        </span>
        <div>
          <img src={Step2Image} alt='step2' />
        </div>
      </Step>

      <Step>
        <span className='step'>Step 5 :</span>
        <span className='line'>
          Sign up or sign in to your Shirtly account.
        </span>
      </Step>

      <Step>
        <span className='step'>Step 6 :</span>
        <span className='line'>
          Your BigCommerce store should appear in the Shirtly dashboard.
        </span>
      </Step>
    </Wrapper>
  </Container>
);

export default HowToConnectBigCommerce;
