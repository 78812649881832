import styled from 'styled-components';

const Container = styled.div`
  margin-top: 20px;
`;

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid #e6e6e6;
  padding-bottom: 5px;
  margin-bottom: 20px;
`;

const Tab = styled.div`
  font-size: 16px;
  line-height: 32px;
  font-family: 'Inter600';
  color: #18191f;
  opacity: ${(props) => (props.selected ? 1 : 0.6)};
  margin-right: 30px;
  position: ${(props) => (props?.selected ? 'relative' : 'unset')};
  cursor: pointer;
  ::after {
    display: ${(props) => (props?.selected ? 'inline-block' : 'none')};
    content: '';
    /* display: inline-block; */
    height: 6px;
    width: 100%;
    background: #ffd363;
    position: absolute;
    bottom: -8px;
    left: 0;
  }
  @media (max-width: 650px) {
    font-size: 13px;
  }
`;

export { Container, TabWrapper, Tab };
