import moment from 'moment';

const options = {
  chart: {
    type: 'column',
  },
  title: {
    text: '',
  },
  credits: { enabled: false },
  xAxis: {
    categories: [],
    title: {
      text: 'Dates',
      enabled: true,
      style: {
        color: '#686868',
        fontSize: '16px',
        fontFamily: 'Inter600',
      },
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Orders',
      style: {
        color: '#686868',
        fontSize: '16px',
        fontFamily: 'Inter600',
      },
    },
  },
  tooltip: {
    headerFormat: '<table>',
    pointFormat: `<tr><td style="padding:0"><b>{point.y} orders</b></td></tr>`,
    footerFormat: '</table>',
    shared: true,
    useHTML: true,
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  legend: {
    enabled: false,
  },
};

const currentYear = moment().format('YYYY');
const months = [
  {
    label: `January ${currentYear}`,
    value: '0',
  },
  {
    label: `February ${currentYear}`,
    value: '1',
  },
  {
    label: `March ${currentYear}`,
    value: '2',
  },
  {
    label: `April ${currentYear}`,
    value: '3',
  },
  {
    label: `May ${currentYear}`,
    value: '4',
  },
  {
    label: `June ${currentYear}`,
    value: '5',
  },
  {
    label: `July ${currentYear}`,
    value: '6',
  },
  {
    label: `August ${currentYear}`,
    value: '7',
  },
  {
    label: `September ${currentYear}`,
    value: '8',
  },
  {
    label: `October ${currentYear}`,
    value: '9',
  },
  {
    label: `November ${currentYear}`,
    value: '10',
  },
  {
    label: `December ${currentYear}`,
    value: '11',
  },
];

export { options, months };
