import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Container,
  ProductInformation,
  Update,
  SubHeading,
  SubHeadingWrapper,
  FieldWrapper,
} from "styles/pages/edit-products/editProductInformation";
import Tooltip from "@material-ui/core/Tooltip";
import CloudinaryWidget from "components/CloudinaryWidget";
import Button from "components/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "components/TextField";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const EditPrintAreas = ({ product, updatePrintArea }) => {
  const [areas, setAreas] = useState([]);

  useEffect(() => {
    const tempArea = product?.PrintAreas || [];

    if (!tempArea?.length) {
      if (product?.Product?.MarketingImagePath) {
        tempArea.push({
          printAreaName: "Front",
          printAreaMarketingImage: product?.Product?.MarketingImagePath,
          editable: true,
          designerSettings: product?.DesignerSettings || {},
        });
      }

      if (product?.Product?.MarketingBackImagePath) {
        tempArea.push({
          printAreaName: "Back",
          printAreaMarketingImage: product?.Product?.MarketingBackImagePath,
          editable: true,
          designerSettings: product?.BackDesignerSettings || {},
        });
      }
    }

    setAreas(tempArea);
  }, []);

  const disabled = !!areas?.filter(
    (a) => !a?.printAreaName || !a?.printAreaMarketingImage
  )?.length;

  return (
    <Container style={{ border: "none" }}>
      <SubHeadingWrapper>
        <SubHeading>Print Areas</SubHeading>

        <Tooltip title="Add Print Area">
          <AddCircleIcon
            onClick={() =>
              setAreas([
                ...areas,
                {
                  editable: true,
                  printAreaName: "",
                  printAreaMarketingImage: "",
                  designerSettings: {},
                },
              ])
            }
            style={{
              float: "right",
              cursor: "pointer",
              color: "#1594db",
              fontSize: 30,
            }}
          />
        </Tooltip>
      </SubHeadingWrapper>

      <ProductInformation>
        {areas?.map((area, i) => {
          return (
            <FieldWrapper key={`EditPrintArea${i}`}>
              <TextField
                label="Area Name"
                size="small"
                value={area?.printAreaName}
                disabled={!area?.editable}
                onChange={(printAreaName) =>
                  setAreas(
                    areas?.map((a, j) => {
                      if (i !== j) {
                        return a;
                      }
                      return {
                        ...a,
                        printAreaName,
                      };
                    })
                  )
                }
              />

              <CloudinaryWidget
                disabled={false}
                label="Area Marketing Image"
                value={area?.printAreaMarketingImage}
                setValue={(printAreaMarketingImage) =>
                  setAreas(
                    areas?.map((a, j) => {
                      if (i !== j) {
                        return a;
                      }

                      const split = printAreaMarketingImage.split("/upload/");
                      // const value = `${split[0]}/upload/w_1000,h_1000,c_pad/${split[1]}`;

                      return {
                        ...a,

                        printAreaMarketingImage:
                          !!printAreaMarketingImage && split?.length > 0
                            ? `${split[0]}/upload/${split[1]}`
                            : "",

                        // printAreaMarketingImage:
                        //   printAreaMarketingImage?.includes('c_pad')
                        //     ? printAreaMarketingImage
                        //     : value,
                      };
                    })
                  )
                }
                folderPath={`Shirtly/Shirtly_Products_Mock/Images/mockups/${product?.Product?.Name}`}
              />

              <DeleteIcon
                style={{
                  color: "red",
                  cursor: "pointer",
                  opacity: area?.editable ? 1 : 0.5,
                }}
                onClick={() => {
                  if (area.editable) {
                    const newAreas = areas?.filter((a, j) => i !== j);
                    setAreas(newAreas);
                  }
                }}
              />
            </FieldWrapper>
          );
        })}
        <Update style={{ margin: "30px 0 0 0" }}>
          <Button
            title="Update"
            disabled={disabled}
            onClick={() => {
              let additionalTechArtSettings = _.clone(
                product?.AdditionalTechniquesArtSettings
              );

              if (additionalTechArtSettings?.length > 0) {
                for (let i = 0; i < additionalTechArtSettings?.length; i++) {
                  let artSettings = [];
                  for (let j = 0; j < areas?.length; j++) {
                    artSettings.push({
                      areaName: areas[j].printAreaName,
                      areaSettings: areas[j].designerSettings,
                      image: areas[j].printAreaMarketingImage,
                    });
                  }
                  additionalTechArtSettings[i].artSettings = artSettings;
                }
              }

              updatePrintArea(areas, additionalTechArtSettings);
            }}
          />
        </Update>
      </ProductInformation>
    </Container>
  );
};

export default EditPrintAreas;
