import styled from 'styled-components';
import COLORS from 'shared/constants/colors';

const Container = styled.div`
  padding: 0 20px;
`;
const Wrapper = styled.div``;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px;
  background: #fff;
  align-items: center;
  h2 {
    font-size: 24px;
    font-weight: normal;
    margin: 0;
  }
`;
const Settings = styled.div`
  padding: 20px 0;
`;

const Setting = styled.div`
  background-color: ${COLORS.WHITE};
  padding: 15px 20px 20px 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  .flex-wrapper {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 750px) {
      grid-template-columns: 1fr;
    }
  }
  > div {
    margin-bottom: 15px;
  }
  > button {
    background-color: ${COLORS.BLUE};
    color: ${COLORS.WHITE};
    padding: 6px 12px;
    text-transform: capitalize;
    width: 70px;
    :hover {
      background-color: ${COLORS.BLUE};
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
  }
`;

const Title = styled.div`
  margin-bottom: 5px;
  font-weight: 700;
`;
const TermsConditions = styled.div`
  background-color: ${COLORS.WHITE};
  padding: 15px 20px 20px 20px;
  border: 1px solid #e7eaec;
  margin-bottom: 20px;
  > button {
    background-color: ${COLORS.BLUE};
    color: ${COLORS.WHITE};
    padding: 6px 12px;
    text-transform: capitalize;
    width: 70px;
    :hover {
      background-color: ${COLORS.BLUE};
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
  }
`;
export {
  Container,
  Wrapper,
  Header,
  Settings,
  Setting,
  Title,
  TermsConditions,
};
