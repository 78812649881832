import React from 'react';
import _ from 'lodash';
import Button from 'components/Button';

const CreateBasicOrder = ({ handleNext }) => (
  <Button
    containerStyle={{
      backgroundColor: '#1594DB',
      fontSize: '16px',
      borderRadius: '6px',
      padding: '12px',
      lineHeight: '1.5',
      color: '#fff',
      textTransform: 'capitalize',
      fontFamily: 'Inter500',
      width: '212px',
      boxShadow: 'none',
    }}
    title='Create Basic Order'
    onClick={() => _.isFunction(handleNext) && handleNext()}
  />
);

export default CreateBasicOrder;
