import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SubHeader from 'components/SubHeader';
import { ROUTES } from 'shared/constants/routes';
import PrintTypeFields from 'pages/fulfillment-days/printTypeFields';
import Products from 'pages/fulfillment-days/Products';
import {
  editProducts,
  editDTGPlusInformation,
  getAllProducts,
} from 'actions/products';
import { Container } from 'styles/pages/fulfillment-days';
import { DECORATING_TECHNIQUES } from 'pages/fulfillment-days/data';
import UpdateSuccessDialog from 'pages/fulfillment-days/UpdateSuccessDialog';

const FulfillmentDays = () => {
  const dispatch = useDispatch();
  const allProducts = useSelector((state) => state?.products?.data);
  const [products, setProducts] = useState(undefined);
  const [decoratingTech, setDecoratingTech] = useState(DECORATING_TECHNIQUES);
  const [modal, setModal] = useState(false);
  const [productIndex, setProductIndex] = useState(0);

  useEffect(() => {
    if (!products || products?.length == 0) {
      setProducts(allProducts);
    }
  }, [allProducts]);

  const handleSubmit = async () => {
    const selectedProducts = products?.filter((p) => p?.selected);
    const selectedDecoratingTech = decoratingTech?.filter(
      (d) => d?.maxFulfillmentDays > 0 || d?.minFulfillmentDays > 0
    );

    setModal(true);
    for await (let product of selectedProducts) {
      setProductIndex((state) => state + 1);
      const defaultPrintType = product?.Product?.PrintType;
      const additionalTechs = product?.AdditionalTechniquesArtSettings;
      await new Promise(async (r) => {
        let additionalTechPayload = [];
        for await (let decTech of selectedDecoratingTech) {
          const displayName = decTech?.DisplayName;
          const maxFulfillmentDays = decTech?.maxFulfillmentDays;
          const minFulfillmentDays = decTech?.minFulfillmentDays;
          await new Promise(async (r1) => {
            if (displayName.toUpperCase() === defaultPrintType.toUpperCase()) {
              const payload = {
                productData: {
                  ...product?.Product,
                  defaultMaximumFulfillDays: maxFulfillmentDays,
                  defaultMinimumFulfillDays: minFulfillmentDays,
                },
              };

              await dispatch(editProducts(payload, false));
              r1();
            } else {
              for (let i = 0; i < additionalTechs?.length; i++) {
                if (
                  additionalTechs[i]?.name.toUpperCase() ===
                  displayName.toUpperCase()
                ) {
                  additionalTechPayload.push({
                    ...additionalTechs[i],
                    maxFulfillmentDays: maxFulfillmentDays,
                    minFulfillmentDays: minFulfillmentDays,
                  });
                }
              }

              r1();
            }
          });
        }

        if (additionalTechPayload?.length > 0) {
          const payload = {
            ID: product?.Product?.ID,
            additionalTechniquesArtSettings: additionalTechPayload,
            dtgPlusData: product?.DTGPlus,
            remainingDecorativeTechniques:
              product?.RemainingDecorativeTechniques,
          };
          await dispatch(editDTGPlusInformation(payload, false));
          r();
        } else {
          r();
        }
      });
    }

    await dispatch(getAllProducts());
    setProductIndex(0);
  };

  return (
    <Container>
      <SubHeader
        title={ROUTES.FULFILLMENT_DAYS.TITLE}
        links={[
          {
            title: ROUTES.HOME.TITLE,
            to: ROUTES.HOME.LINK,
          },
        ]}
        currentPageTitle={ROUTES.FULFILLMENT_DAYS.TITLE}
      />

      <PrintTypeFields
        setDecoratingTech={setDecoratingTech}
        decoratingTech={decoratingTech}
        products={products}
        handleSubmit={handleSubmit}
      />

      <Products products={products} setProducts={setProducts} />

      {modal && (
        <UpdateSuccessDialog
          open={modal}
          setOpen={(v) => setModal(v)}
          totalProducts={products?.filter((p) => p?.selected)?.length}
          currentIndex={productIndex}
        />
      )}
    </Container>
  );
};

export default FulfillmentDays;
