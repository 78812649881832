import styled from 'styled-components';

const Container = styled.div`
  padding: 10px;
  margin: 20px 0;
`;

const ChartHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: 20px;
  .MuiFormControl-marginDense {
    max-width: 200px;
    justify-self: flex-end;
  }
  .MuiSelect-select.MuiSelect-select {
    text-align: end;
    font-size: 14px;
    font-family: 'Inter600';
    color: #686868;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiSelect-select:focus {
    background-color: transparent;
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-family: 'Inter600';
`;

const Wrapper = styled.div`
  display: grid;
  /* grid-template-columns: 4fr 1fr; */
  grid-gap: 20px;

  @media (max-width: 1050px) {
    grid-template-columns: 2fr 2fr;
  }

  @media (max-width: 500px) {
    display: block;
  }
`;

export { Container, ChartHeader, Title, Wrapper };
