import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import TextField from 'components/TextField';
import Select from 'components/Select';
import Button from 'components/Button';
import COLORS from 'shared/constants/colors';
import ImageSection from 'pages/account-settings/ImageSection';

import {
  Container,
  Form,
  Submit,
  TextSection,
} from 'styles/pages/account-setting/generalInfoForm';

const GeneralInfoForm = ({ user, countries, update }) => {
  const [state, setState] = useState(undefined);
  const [statesList, setStateList] = useState(undefined);

  useEffect(() => {
    if (!state && user) {
      const {
        FullName,
        ZipPostal,
        Country,
        Company,
        Email,
        AddressLineOne,
        AddressLineTwo,
        City,
        StateProvince,
        PasswordHash,
      } = user?.User || {};

      const states = _.find(countries, (o) => o.code2 === Country)?.states;

      setState({
        name: FullName,
        email: Email,
        company: Company,
        address1: AddressLineOne,
        address2: AddressLineTwo,
        city: City,
        state: StateProvince,
        country: Country,
        zip: ZipPostal,
        userId: Email,
        password: PasswordHash,
      });

      setStateList(states);
    }
  }, [setStateList]);

  const disabled =
    !state?.name?.trim().length ||
    !state?.company?.trim().length ||
    !state?.address1?.trim().length ||
    !state?.city?.trim().length ||
    !state?.country?.trim().length ||
    !state?.zip?.trim().length;

  return (
    <Container>
      <TextSection>
        <Form>
          <TextField
            label='Name'
            size='small'
            value={state?.name || ''}
            onChange={(name) =>
              setState({
                ...state,
                name,
              })
            }
          />
          <TextField
            label='Company'
            size='small'
            value={state?.company || ''}
            onChange={(company) =>
              setState({
                ...state,
                company,
              })
            }
          />
          <TextField
            label='Address 1'
            size='small'
            value={state?.address1 || ''}
            onChange={(address1) =>
              setState({
                ...state,
                address1,
              })
            }
          />
          <TextField
            label='Address 2'
            size='small'
            value={state?.address2 || ''}
            onChange={(address2) =>
              setState({
                ...state,
                address2,
              })
            }
          />
          <Select
            label='Country'
            options={countries}
            labelKey='name'
            valueKey='code2'
            value={{ code2: state?.country || '' }}
            onChange={(CountryString) => {
              const states = _.find(
                countries,
                (o) => o.code2 === CountryString
              )?.states;
              setStateList(states);

              setState({
                ...state,
                country: CountryString,
                state: null,
              });
            }}
          />
          <Select
            label='State/Province'
            labelKey='name'
            valueKey='code'
            options={statesList}
            value={{ code: state?.state || '' }}
            onChange={(StateProvince) =>
              setState({
                ...state,
                state: StateProvince,
              })
            }
          />
          <TextField
            label='City'
            size='small'
            value={state?.city || ''}
            onChange={(city) =>
              setState({
                ...state,
                city,
              })
            }
          />
          <TextField
            label='Zip'
            size='small'
            value={state?.zip || ''}
            onChange={(zip) =>
              setState({
                ...state,
                zip,
              })
            }
          />
        </Form>
        <Submit>
          <Button
            containerStyle={{
              backgroundColor: '#1594DB',
              fontSize: '16px',
              borderRadius: '6px',
              padding: '12px',
              lineHeight: '1.5',
              color: COLORS.WHITE,
              fontFamily: 'Inter500',
              width: '100%',
              textTransform: 'capitalize',
            }}
            onClick={() => update(state)}
            variant='text'
            title='Update'
            disabled={disabled}
          />
        </Submit>
      </TextSection>
      <ImageSection />
    </Container>
  );
};

export default GeneralInfoForm;
