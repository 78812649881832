import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import Button from 'components/Button';
import { useHistory } from 'react-router-dom';
import Color from 'components/Color';
import ProductTags from 'pages/browse-catalog/ProductTags';
import { ROUTES } from 'shared/constants/routes';
import WarningIcon from '@material-ui/icons/Warning';
import Image from 'components/Image';
import Tooltip from '@material-ui/core/Tooltip';
import USAFlag from 'assets/images/usa-flag.png';
import CADFlag from 'assets/images/canada-flag.png';
import EUFlag from 'assets/images/eu-flag.png';
import AUFlag from 'assets/images/aus-flag.jpeg';
import PremiumIcon from 'assets/images/premium-icon.png';
import { USD_TO_CAD_CONVERSION_RATE } from 'constants/currency';
import {
  Container,
  ItemGroup,
  Item,
  ProductLink,
  ProductImage,
  Description,
  Text,
  Amount,
  Colors,
  NoProdcuts,
  FlagWrapper,
  Premium,
  ItemWrapper,
} from 'styles/pages/browse-catalog/details.js';

const Details = ({ resetFilters, products }) => {
  const history = useHistory();
  const currency = useSelector((state) => state?.currency?.value);

  

  return (
    <Container>
      {!!products?.length ? (
        <ItemGroup>
          {products.map((item, index) => {
            const product = item.Product;
            const colors = item.Colors;
            const printProviders = _.uniqBy(
              item?.PrintProviders,
              'ProviderName'
            );
            const premium = item?.Product?.premiumProduct;

            const cost =
              item?.Sizes[0]?.ColoredPrice +
              (item?.Sizes[0][`${item?.Product?.PrintType}Price`] || 0);

            return (
              <ItemWrapper>
                <Item
                  premium={premium}
                  key={`productsCatalog${index}`}
                  onClick={() =>
                    history.push(`${ROUTES.PRODUCT_DETAIL.LINK}/${product.ID}`)
                  }
                >
                  <ProductTags tags={product.ProductTags} />
                  <FlagWrapper>
                    {printProviders?.map((printProvider, index) => (
                      <Tooltip
                        arrow={true}
                        key={`printProvider${index}`}
                        title={`${printProvider.ProviderName} Fulfillment Plant`}
                        placement="top"
                      >
                        <img
                          alt="products"
                          style={{
                            top: 3,
                            left: index === 0 ? 4 : index * 35,
                          }}
                          src={
                            printProvider.ProviderName === 'CA'
                              ? CADFlag
                              : printProvider.ProviderName === 'US' ||
                                printProvider.ProviderName === 'NY'
                              ? USAFlag
                              : printProvider.ProviderName === 'EU'
                              ? EUFlag
                              : AUFlag
                          }
                          width="30"
                        />
                      </Tooltip>
                    ))}
                  </FlagWrapper>
                  <ProductLink>
                    <ProductImage premium={premium}>
                      <Image
                        src={product.MarketingImagePath}
                        alt={product.DisplayName}
                      />
                    </ProductImage>
                    <Description>
                      <div>
                        <Text premium={premium}>{product.DisplayName}</Text>
                        <Amount premium={premium}>
                          from $
                          {currency === 'USD'
                            ? cost?.toFixed(2)
                            : (
                                Number(cost) * USD_TO_CAD_CONVERSION_RATE
                              ).toFixed(2)}
                          &nbsp;{currency}
                        </Amount>
                      </div>
                    </Description>
                    <Colors premium={premium}>
                      {colors.map((color, i) =>
                        color?.visible === false ? null : (
                          <Color
                            key={`productColor${i}`}
                            title={color.Name}
                            code={color.HexCode}
                          />
                        )
                      )}
                    </Colors>
                  </ProductLink>
                  {premium && (
                    <Premium>
                      <img src={PremiumIcon} alt="premium" />
                    </Premium>
                  )}
                </Item>
              </ItemWrapper>
            );
          })}
        </ItemGroup>
      ) : (
        <NoProdcuts>
          <WarningIcon color="error" />
          <h1>No results matched your browsing filters</h1>
          <Button
            onClick={() => _.isFunction(resetFilters) && resetFilters()}
            containerStyle={{
              fontSize: '12px',
              borderRadius: '50px',
              padding: '5px 10px',
              color: '#fff',
              backgroundColor: '#2280c1',
            }}
            title="Reset all filters"
          />
        </NoProdcuts>
      )}
    </Container>
  );
};

export default Details;
