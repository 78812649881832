import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  backgroundRemove,
  getUserInformation,
  setBackgroundBurnQuota,
} from "actions/user";
import Checkbox from "components/CheckBox";
import { ROUTES } from "shared/constants/routes";
import SubHeader from "components/SubHeader";
import Button from "components/Button";
import COLORS from "shared/constants/colors";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Flare from "@material-ui/icons/Flare";
import ProcessDialog from "pages/background-burner/ProcessDialog";
import { objectsData } from "pages/background-burner/data";
import {
  Container,
  Wrapper,
  ButtonWrapper,
  ObjectsWrapper,
  ObjectTitle,
  Upload,
  Message,
} from "styles/pages/background-burner";

const BackgroundBurner = () => {
  const dispatch = useDispatch();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const [artwork, setArtwork] = useState(null);
  const [processModal, setProcessModal] = useState(false);
  const [objects, setObjects] = useState({ default: true });
  const [user, setUser] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const response = await dispatch(getUserInformation(emailid));
      setUser(response);
    };

    if (!user) {
      getData();
    }
  }, [user]);

  return (
    <Container>
      <SubHeader
        title={ROUTES.BACKGROUND_BURNER.TITLE}
        links={[
          {
            title: ROUTES.DASHBOARD.TITLE,
            to: ROUTES.DASHBOARD.LINK,
          },
        ]}
        currentPageTitle={ROUTES.BACKGROUND_BURNER.TITLE}
      />

      <Wrapper>
        {user?.backgroundBurnerQuota === 0 ? (
          <p>Your quota is already completed for this month</p>
        ) : (
          <>
            <p className="title-message">
              Shirtly AI provides you ability to remove background
            </p>

            <p className="usage">
              Available Usage :&nbsp;
              <span>{user ? user?.backgroundBurnerQuota || 20 : 0}</span>
              &nbsp;photos
            </p>

            <Upload>
              {!artwork?.secure_url && (
                <>
                  <p>Upload image to burn the background</p>
                  <Button
                    title="Upload"
                    containerStyle={{
                      backgroundColor: COLORS.DARK_BLUE,
                      color: COLORS.WHITE,
                      fontWeight: "normal",
                      textTransform: "none",
                      borderRadius: "6px",
                      fontSize: "16px",
                      lineHeight: "1.5",
                      boxShadow: "none",
                    }}
                    startIcon={<CloudUploadIcon />}
                    onClick={() => {
                      const myWidget = window.cloudinary.createUploadWidget(
                        {
                          cloudName: "big-oven-tees-inc",
                          show_powered_by: false,
                          uploadPreset: "tnedst8q",
                          folder: `Shirtly/${emailid}/BackgroundBurnFiles`,
                          clientAllowedFormats: ["png", "jpeg", "jpg"],
                          sources: ["local", "camera", "url"],
                        },
                        (error, result) => {
                          if (!error && result && result.event === "success") {
                            setArtwork(result?.info);
                          }
                        }
                      );
                      myWidget.open();
                    }}
                  />
                </>
              )}
              {artwork?.secure_url && (
                <img
                  src={artwork?.secure_url}
                  alt="background-burner"
                  width="250"
                />
              )}
            </Upload>

            {artwork?.secure_url && (
              <>
                <ObjectTitle>
                  Please select following as the foreground object to keep when
                  you remove the background
                </ObjectTitle>
                <ObjectsWrapper>
                  {objectsData?.map((o) => (
                    <Checkbox
                      key={o?.key}
                      checked={objects[o?.key] || false}
                      onChange={() => {
                        if (o?.key === "default") {
                          setObjects(() => ({
                            default: !objects?.default,
                          }));
                        } else {
                          setObjects(() => ({
                            ...objects,
                            default: false,
                            [o?.key]: !objects[o?.key],
                          }));
                        }
                      }}
                      label={o?.title}
                    />
                  ))}
                </ObjectsWrapper>
                <ButtonWrapper>
                  <Button
                    title="Burn Background"
                    onClick={async () => {
                      const quota = await dispatch(
                        setBackgroundBurnQuota({
                          userId: emailid,
                          month: moment().format("MMM"),
                        })
                      );
                      setUser(() => ({
                        ...user,
                        backgroundBurnerQuota: quota?.backgroundBurnerQuota,
                      }));

                      const selectedValues = _.pickBy(
                        objects,
                        (o) => o === true
                      );

                      await dispatch(
                        backgroundRemove({
                          publicId: artwork?.public_id,
                          tags: objects?.default
                            ? "fine_edges"
                            : Object.keys(selectedValues).join(),
                        })
                      );

                      setProcessModal(true);
                    }}
                    startIcon={<Flare />}
                  />
                </ButtonWrapper>
              </>
            )}
          </>
        )}
        <Message>
          <p>
            For the best results using the photo Background Burner, we recommend
            the following:
          </p>
          <ul>
            <li>Minimum resolution of 300 DPI at final size.</li>
            <li>A well lite photo with a clear in-focus subject(s).</li>
            <li>JPG or PNG File Format.</li>
            <li>50MB Maximum file size.</li>
          </ul>
          <p>Things to Avoid</p>
          <ul>
            <li>Dark or Low light photos</li>
            <li>Blurry or Out of Focus Subject(s)</li>
            <li>
              Enlarging small elements [image resolution is not enhanced. If the
              image looks poor in the mock-up, it will look poor on the final
              product].
            </li>
            <li>Copyrighted / Trademarked images</li>
          </ul>
          <p>Users are limited to 20 background burns per month.</p>
        </Message>
      </Wrapper>

      {processModal && (
        <ProcessDialog
          open={processModal}
          setOpen={() => setProcessModal(false)}
        />
      )}
    </Container>
  );
};

export default BackgroundBurner;
