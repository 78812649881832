import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Select from 'components/Select';
import { allUserOrderReport, allOrderReportForChart } from 'actions/orders';
import { options, months } from 'pages/dashboard/chart-data';
import Notifications from 'pages/dashboard/Notifications';
import {
  Container,
  ChartHeader,
  Title,
  Wrapper,
} from 'styles/pages/dashboard/orderChart';

const OrdersChart = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const emailId = useSelector((state) => state?.auth?.profile?.emailid);
  const ordersChartData = useSelector((state) => state?.ordersChartData);
  const isAdmin = emailId === 'support@shirtly.com';
  const [data, setData] = useState(options);
  const [month, setMonth] = useState(String(moment().month()));

  useEffect(() => {
    const getData = async () => {
      const now = moment();
      const then = moment.unix(ordersChartData?.timestamp);
      const delta = now.diff(then, 'minutes');

      const request = isAdmin
        ? ordersChartData?.data &&
          ordersChartData.month === Number(month) &&
          delta < 30
          ? ordersChartData?.data
          : await dispatch(
              allOrderReportForChart({
                startDate: moment()
                  .month(month)
                  .startOf('month')
                  .subtract(1, 'days'),
                endDate: moment().month(month).endOf('month'),
              })
            )
        : await dispatch(
            allUserOrderReport({
              startDate: moment()
                .month(month)
                .startOf('month')
                .subtract(1, 'days'),
              endDate: moment().month(month).endOf('month'),
              user: emailId,
            })
          );

      let ordersData = [];
      let dates = [];
      const daysInMonth = moment().month(month).daysInMonth();

      let etsyOrders = [];
      let shopifyOrders = [];
      let wooOrders = [];
      let bigOrders = [];
      let bigCartelOrders = [];
      let shirtlyOrders = [];

      for (let i = 1; i <= daysInMonth; i++) {
        const date = moment().date(i).month(month).format('YYYY-MM-DD');
        dates.push(i);
        shirtlyOrders.push(
          request?.allOrders?.filter(
            (d) =>
              d?.OrderSubmitToProductionDate === date &&
              d?.MarketPlaceType === 'Shirtly'
          )?.length
        );

        bigCartelOrders.push(
          request?.allOrders?.filter(
            (d) =>
              d?.OrderSubmitToProductionDate === date &&
              d?.MarketPlaceType === 'BigCartel'
          )?.length
        );

        bigOrders.push(
          request?.allOrders?.filter(
            (d) =>
              d?.OrderSubmitToProductionDate === date &&
              d?.MarketPlaceType === 'BigCommerce'
          )?.length
        );

        wooOrders.push(
          request?.allOrders?.filter(
            (d) =>
              d?.OrderSubmitToProductionDate === date &&
              d?.MarketPlaceType === 'WooCommerce'
          )?.length
        );

        etsyOrders.push(
          request?.allOrders?.filter(
            (d) =>
              d?.OrderSubmitToProductionDate === date &&
              d?.MarketPlaceType === 'Etsy'
          )?.length
        );

        shopifyOrders.push(
          request?.allOrders?.filter(
            (d) =>
              d?.OrderSubmitToProductionDate === date &&
              d?.MarketPlaceType === 'Shopify'
          )?.length
        );
      }

      setData(() => ({
        ...data,
        xAxis: { ...data?.xAxis, categories: dates },
        yAxis: {
          ...data?.yAxis,
          stackLabels: {
            enabled: true,
            style: {
              fontWeight: 'bold',
              color:
                (Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color) ||
                'gray',
            },
          },
        },
        plotOptions: {
          ...data?.plotOptions,
          column: {
            ...data?.plotOptions?.column,
            stacking: 'normal',
          },
        },
        series: [
          {
            name: 'Shirtly',
            data: shirtlyOrders,
            color: '#1594db',
            borderColor: '#1594db',
          },
          {
            name: 'BigCartel',
            data: bigCartelOrders,
            color: '#222222f2',
            borderColor: '#222222f2',
          },
          {
            name: 'BigCommerce',
            data: bigOrders,
            color: '#343140',
            borderColor: '#343140',
          },
          {
            name: 'WooCommerce',
            data: wooOrders,
            color: '#674399',
            borderColor: '#674399',
          },
          {
            name: 'Shopify',
            data: shopifyOrders,
            color: '#008060',
            borderColor: '#008060',
          },
          {
            name: 'Etsy',
            data: etsyOrders,
            color: '#f1641e',
            borderColor: '#f1641e',
          },
        ],
        legend: {
          enabled: true,
        },
        tooltip: {
          ...data.tooltip,
          pointFormat: `<b>{point.series.name} : {point.y} orders</b><br/>`,
        },
      }));
    };
    getData();
  }, [dispatch, month]);

  useEffect(() => {
    if (data?.series) {
      const container = ref.current.container.current;
      // container.style.width = 'calc(100vw - 75px)';
      ref.current.chart.reflow();
    }
  }, [data]);

  return (
    <Wrapper>
      <Container>
        {data?.series ? (
          <>
            <ChartHeader>
              <Title>Order Submitted Monthly</Title>
              <Select
                labelKey="label"
                valueKey="value"
                value={{ value: month }}
                options={months}
                onChange={(v) => setMonth(() => v)}
              />
            </ChartHeader>
            <HighchartsReact
              highcharts={Highcharts}
              ref={ref}
              options={data}
              constructorType="chart"
              containerProps={{ style: { width: '100%' } }}
            />
          </>
        ) : (
          <ChartHeader>
            <Title>Loading data...</Title>
          </ChartHeader>
        )}
      </Container>
      {/* <Container>
        <Notifications />
      </Container> */}
    </Wrapper>
  );
};

export default OrdersChart;
