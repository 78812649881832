import styled from 'styled-components';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

const Container = styled.div`
  padding: 15px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'buyernotes'
    'artwork'
    'oli';
  align-items: start;
  column-gap: 20px;
  @media (min-width: 1290px) {
    grid-template-columns: 1fr minmax(auto, 600px);
    grid-template-rows: repeat(auto-fit, minmax(0px, auto));
    grid-template-areas: 'oli artwork';
    margin-bottom: 15px;
  }
`;

const ProductSummary = styled.div`
  grid-area: oli / oli / oli / oli;
`;

const NoProductFound = styled.h3`
  text-align: center;
  color: red;
  font-size: 22px;
`;
const Card = styled.div`
  border-radius: 5px;
  padding: 15px;
  background-color: rgb(255, 255, 255);
  margin-top: 20px;
  opacity: ${(props) => props?.opacity};
  cursor: pointer;
  position: relative;
`;

const CardFields = styled.div`
  font-size: 13px;
  width: 100%;
  display: flex;
  gap: 15px 10px;
  flex-wrap: wrap;
  margin-top: 20px;
  > div {
    min-width: 110px;
    flex: 1 1 0%;
  }
`;

const FooterWrapper = styled.div`
  margin-top: 15px;
  button {
    background-color: #1594db;
    color: #fff;
    font-size: 12px;
    float: right;
    :hover {
      background-color: #1594db;
      color: #fff;
      opacity: 0.5;
    }
  }
`;

const SuccessIcon = styled(CheckCircleIcon)`
  position: absolute;
  right: -8px;
  top: -8px;
  color: green;
  border-radius: 50%;
  background-color: white;
`;

const ErrorIcon = styled(ReportProblemIcon)`
  position: absolute;
  right: -8px;
  top: -8px;
  color: red;
  border-radius: 50%;
`;

const ProductInfo = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 25px;
  flex: 1;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 20px;
  .key {
    font-size: 20px;
    padding-right: 5px;
    font-family: 'Inter600';
    color: #1594db;
  }

  .value {
    font-size: 20px;
    color: #000000;
    font-family: 'Inter400';
  }
  img.product-image {
    width: 200px !important;
    height: 200px !important;
  }

  img.logo {
    max-width: 250px;
  }

  @media (max-width: 650px) {
    grid-template-columns: auto;
    justify-content: center;
  }
`;

const CardTitle = styled.div`
  margin: 20px 0 0;
  font-weight: bold;
  font-size: 20px;
  border-bottom: 1px solid #676a6c;
  padding-bottom: 10px;
`;

const VariantDes = styled.div`
  color: #000;
  margin-bottom: 10px;

  .key {
    padding-right: 5px;
    font-family: 'Inter600';
    color: #1594db;
  }

  .value {
    color: #000000;
    font-family: 'Inter400';
    margin-right: 20px;
  }
`;

export {
  Container,
  NoProductFound,
  Wrapper,
  ProductSummary,
  Card,
  CardFields,
  FooterWrapper,
  SuccessIcon,
  ErrorIcon,
  ProductInfo,
  CardTitle,
  VariantDes,
};
