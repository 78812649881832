import styled from 'styled-components';

const FlagImage = styled.img`
  max-width: 196px;
  padding: 0 25px;
  margin: 0 auto;
  height: auto;
  object-fit: cover;
  transition: all ease-out 0.25s;
  cursor: pointer;
`;

const FlagSection = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 28px;
  font-family: 'Inter400';
  flex-direction: column;
`;
const BoldText = styled.p`
  max-width: 70%;
  margin: 0 auto;
  font-size: 16px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
  line-height: 1.1;
  color: #000;
  font-family: 'Inter400';
`;
const Text = styled.p`
  max-width: 70%;
  margin: 0 auto;
  font-size: 16px;
  text-align: center;
  color: #000;
  font-family: 'Inter400';
`;

const CountryModalContainer = styled.div`
  h3 {
    font-family: 'Inter400';
    color: #000;
    font-size: 16px;
    font-weight: normal;
  }
  .MuiDialogTitle-root {
    h6 {
      font-family: 'Inter700';
      color: #1594db;
    }

    .MuiIconButton-root {
      background: #000;
      color: #fff;
      width: 28px;
      height: 28px;
      top: 12px;
      right: 12px;
      .MuiSvgIcon-root {
        width: 18px;
        height: 18px;
      }
      .MuiDialogTitle-root {
        margin: 0;
      }
      .MuiDialogContent-root {
        border: none;
      }
    }
  }
`;

const Flags = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Flag = styled.div`
  cursor: pointer;
  padding: 20px 10px;
  box-shadow: ${(props) =>
    props?.selected
      ? `0px 5px 5px -3px rgb(0 0 0 / 20%),
  0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)`
      : 'none'};
  font-weight: ${(props) => (props?.selected ? 'bold' : 'normal')};
  border-radius: 20px;
  :hover {
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
      0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    font-weight: bold;
  }

  p {
    margin: 0;
  }
`;

export {
  FlagImage,
  FlagSection,
  BoldText,
  Text,
  CountryModalContainer,
  Flags,
  Flag,
};
