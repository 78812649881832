import styled from 'styled-components';

const Container = styled.div`
  padding: 0 20px;
  background-color: #f7f8fa;
`;

const Wrapper = styled.div``;

const Step = styled.div`
  font-size: 14px;
  font-family: 'Inter500';
  color: #18191f;
  padding-bottom: 10px;
  .step {
    color: #1594db;
    font-family: 'Inter600';
    padding-right: 5px;
  }

  .line {
    a {
      color: #18191f;
    }
  }
`;

const Title = styled.p`
  font-family: 'Inter600';
  font-size: 16px;
`;

export { Container, Wrapper, Step, Title };
