import styled from 'styled-components';

const Container = styled.div`
  margin: 5px 0 16px;
  > div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -7px;
  }
  /* @media (max-width: 810px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  } */
`;
const StatsContainer = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 25px;
  flex: 1;
  margin: 7px;
  min-width: 220px;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
`;
const Title = styled.p`
  font-size: 16px;
  font-family: 'Inter600';
  margin: 0;
  line-height: 28px;
`;
const Stats = styled.p`
  font-size: 26px;
  font-family: 'Inter700';
  margin: 0;
  line-height: 32px;
  color: #18191f;
`;
export { Container, StatsContainer, Title, Stats };
