import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import StorefrontIcon from '@material-ui/icons/Storefront';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'shared/constants/routes';
import { Container } from 'styles/pages/dashboard/orderChart';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 136deg, #71c3f1 0%, #1594db 50%, #1594db 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 136deg, #71c3f1 0%, #1594db 50%, #1594db 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, #71c3f1 0%, #1594db 50%, #1594db 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, #71c3f1 0%, #1594db 50%, #1594db 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <DoneOutlineIcon />,
    2: <StorefrontIcon />,
    3: <ShoppingBasketIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const getSteps = () => [
  'Sign Up for Shirtly',
  'Connect your Store',
  'Your Orders',
];

const UserStepper = () => {
  const steps = getSteps();
  const history = useHistory();

  return (
    <Container>
      <Stepper
        alternativeLabel
        activeStep={getSteps()?.length}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, i) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              onClick={() => {
                if (i === 1) {
                  history.push(ROUTES.MY_STORES.LINK);
                } else if (i === 2) {
                  history.push(ROUTES.ORDERS.LINK);
                }
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Container>
  );
};

export default UserStepper;
