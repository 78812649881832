import styled from 'styled-components';
import COLORS from 'shared/constants/colors';

const Container = styled.div`
  max-width: 1200px;
  position: relative;
  @media (max-width: 700px) {
    width: 100%;
  }
  .close-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    max-width: 160px;
    width: 100%;
    display: block;
    margin: 15px 0;
  }

  h4 {
    font-size: clamp(1.5rem, 2.5vw, 2.25rem);
    margin: 0;
    font-family: 'Inter800';
    margin-bottom: 20px;
  }

  h4.ipProgram {
    font-size: 20px;
  }

  Button {
    border-radius: 20px;
    padding: 0.5em 1em;
    background: #237aab;
  }
`;
const FieldsGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  button {
    border-radius: 6px;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 20px;
    @media (max-width: 435px) {
      :nth-child(4) {
        grid-template-columns: 1fr;
        grid-gap: 15px;
      }
    }
  }
  p {
    margin-bottom: 0;
  }

  button {
    background-color: #1594db;
    font-size: 15px;
    border-radius: 6px;
    padding: 8px 12px;
    line-height: 1.5;
    color: ${COLORS.WHITE};
    font-weight: 700;
    text-transform: capitalize;
    :hover {
      background-color: #1594db;
    }
  }
`;

const CreateAccount = styled.a`
  font-size: 15px;
  color: #272742;
  text-decoration: underline;
  margin-top: 5px;
  cursor: pointer;
  &:hover {
    color: #ccc;
  }
`;
const ForgetPassword = styled.a`
  padding: 15px 0;
  color: #2789c0;
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
  text-decoration: underline;
  font-size: 15px;
  &:hover {
    color: #ccc;
  }
`;

const Column = styled.div`
  display: grid;
  width: 100%;
  @media (min-width: 868px) {
    grid-template-columns: 1fr 1fr;
  }
`;
const Fields = styled.div`
  padding: 30px;
  @media (min-width: 1080px) {
    padding: 30px 80px;
  }
`;
const Description = styled.div`
  background-color: #1594db;
  background-size: 250px;
  padding: 35px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;

  h4 {
    color: #fff;
    margin: 0;
    font-size: 22px;
    margin-bottom: 7px;
    font-family: 'Inter800';
    line-height: 30px;
  }
  p {
    font-size: 18px;
    margin-top: 0;
    color: #fff;
    font-family: 'Inter400';
  }
  @media (max-width: 868px) {
    display: none;
  }
`;

const IpDescription = styled.div`
  background-color: #1594db;
  background-size: 250px;
  padding: 35px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;

  h4.title {
    text-align: center;
    font-size: 20px;
    margin-bottom: 22px;
    text-transform: uppercase;
  }

  h4 {
    color: #fff;
    margin: 0;
    font-size: 15px;
    margin-bottom: 7px;
    font-family: 'Inter800';
    line-height: 17px;
  }
  p {
    font-size: 14px;
    margin-top: 0;
    color: #fff;
    font-family: 'Inter400';
  }
  @media (max-width: 868px) {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  max-width: 350px;
  margin: 0 auto;
  img {
    width: 100%;
    height: auto;
  }
`;
const Terms = styled.div`
  display: flex;
  align-items: center;
  p {
    margin: 0;
    margin-left: -10px;
    font-size: 1rem;

    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;

    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

export {
  Container,
  Header,
  Body,
  CreateAccount,
  ForgetPassword,
  Column,
  Fields,
  Description,
  Terms,
  FieldsGroup,
  ImageWrapper,
  IpDescription,
};
