import React, { useState, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AUTH_LOGOUT } from 'actions/auth';
import { INITIAL_SYNC_PRODUCTS } from 'actions/sync-products';
import { INITIATE_STORES } from 'actions/stores';
import Drawer from 'components/Drawer';
import NotificationsDrawer from 'components/NotificationsDrawer';
import CountryModal from 'components/Header/CountryModal';
import Avatar from '@material-ui/core/Avatar';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuBarIcon from 'assets/svg/MenuBarIcon';
import PersonIcon from '@material-ui/icons/Person';
import StyledBadge from 'components/Header/StyledBadge';
import { getAllNotifications } from 'actions/notifications';
import {
  Nav,
  Wrapper,
  Logo,
  Menu,
  UserName,
  CurrencyMenu,
  MobileMenu,
  ProfileImage,
  ProfileName,
  NotificationIcon,
} from 'styles/components/Header';

const Header = () => {
  const dispatch = useDispatch();
  const currency = useSelector((state) => state?.currency?.value);
  const auth = useSelector((state) => state?.auth);
  const homeWebPage = useSelector((state) => state?.homeWebPage);
  const homeData = homeWebPage?.find((p) => p?.name === 'Header') || {};
  const { components } = homeData;

  const [countryModalVisibility, updateCountryModalVisibility] =
    useState(false);
  const [drawerVisibility, updateDrawerVisibility] = useState(false);
  const toggleCountryModal = () =>
    updateCountryModalVisibility(!countryModalVisibility);
  const toggleDrawer = () => updateDrawerVisibility(!drawerVisibility);
  const profile = useSelector((state) => state?.auth?.profile);

  const [notificationDrawerVisibility, updateNotificationDrawerVisibility] =
    useState(false);
  const toggleNotificationDrawer = () =>
    updateNotificationDrawerVisibility(!notificationDrawerVisibility);

  const [notifications, setNotifications] = useState(null);

  const getNotificationsData = async () => {
    const data = await dispatch(getAllNotifications());
    setNotifications(() => data);
  };

  const { Image } = components || {};

  const ipUser = auth?.profile?.IPUser;

  useEffect(() => {
    getNotificationsData();
  }, [dispatch]);

  return (
    <Fragment>
      <Nav>
        <Wrapper>
          {!ipUser && (
            <MenuBarIcon onClick={toggleDrawer} className="menu-bar-icon" />
          )}
          {Image && <Logo src={Image} alt="logo" />}
        </Wrapper>
        <Wrapper>
          <Menu>
            <NotificationIcon onClick={toggleNotificationDrawer}>
              <StyledBadge
                badgeContent={
                  notifications?.length ? `${notifications?.length}+` : '0'
                }
                color="secondary"
              >
                <Avatar>
                  <NotificationsActiveIcon className="notification-icon" />
                </Avatar>
              </StyledBadge>
            </NotificationIcon>
            {!ipUser && (
              <CurrencyMenu onClick={toggleCountryModal}>
                {currency === 'USD' ? 'US' : 'CA'} Dollar
                <KeyboardArrowDownIcon style={{ color: '#ccc' }} />
              </CurrencyMenu>
            )}
            <UserName>
              <ProfileImage>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ProfileImage>
              <ProfileName>
                <p>Hello</p>
                <p className="full-name">{profile?.FullName}</p>
              </ProfileName>
            </UserName>

            <Avatar>
              <PowerSettingsNewIcon
                className="logout-icon"
                onClick={() => {
                  dispatch({ type: INITIATE_STORES });
                  dispatch({ type: AUTH_LOGOUT });
                  dispatch({ type: INITIAL_SYNC_PRODUCTS });
                }}
              />
            </Avatar>
          </Menu>
          <MobileMenu>
            <ProfileName>
              <p>Hello</p>
              <p className="full-name">{profile?.FullName}</p>
            </ProfileName>
          </MobileMenu>
        </Wrapper>
      </Nav>
      {countryModalVisibility && (
        <CountryModal
          open={countryModalVisibility}
          onClose={toggleCountryModal}
        />
      )}

      {drawerVisibility && (
        <Drawer open={drawerVisibility} toggle={toggleDrawer} />
      )}

      <NotificationsDrawer
        open={notificationDrawerVisibility}
        toggle={toggleNotificationDrawer}
      />
    </Fragment>
  );
};

export default Header;
