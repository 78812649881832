import styled from 'styled-components';

const Container = styled.div`
  padding: 0 15px;
`;
const SearchWrapper = styled.div`
  display: flex;
  grid-gap: 10px;
  align-items: center;
  margin: 15px 0;
  flex-wrap: wrap;
`;

const PaginationWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
`;
const OrderSection = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 10px;
  @media (max-width: 667px) {
    width: 100%;
    align-items: stretch;

    > button {
      flex: 1;
      height: auto !important;
      font-size: 13px;
      width: 100%;
    }
  }
`;
const TableWrapper = styled.div`
  .container {
    margin: 30px 0;
    box-shadow: 4px 4px 10px #d9d9d9, -4px -4px 10px #fff;
    overflow-x: auto;
    border-radius: 10px;
  }
`;

const AutomationWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  > button {
    font-size: 16px;
    background-color: #1594db;
    height: 48px;
    color: #fff;
    text-transform: capitalize;
    margin-left: 15px;
    :hover {
      background-color: #1594db;
      color: #fff;
      opacity: 0.7;
    }
  }
`;

export {
  Container,
  SearchWrapper,
  PaginationWrapper,
  OrderSection,
  TableWrapper,
  AutomationWrapper,
};
