import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getStores } from 'actions/stores';
import ProductTabs from 'pages/my-stores/sync-products/ProductTabs';
import SubHeader from 'components/SubHeader';
import { ROUTES } from 'shared/constants/routes';
import { Container } from 'styles/pages/my-stores/sync-products';

const SyncProducts = () => {
  const dispatch = useDispatch();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);

  useEffect(async () => {
    await dispatch(getStores(emailid));
  }, [dispatch, emailid]);

  return (
    <Container>
      <SubHeader
        title={ROUTES.MY_STORES_SYNC_PRODUCTS.TITLE}
        links={[
          {
            title: ROUTES.DASHBOARD.TITLE,
            to: ROUTES.DASHBOARD.LINK,
          },
          {
            title: ROUTES.MY_STORES.TITLE,
            to: ROUTES.MY_STORES.LINK,
          },
        ]}
        currentPageTitle={ROUTES.MY_STORES_SYNC_PRODUCTS.TITLE}
      />
      <ProductTabs />
    </Container>
  );
};

export default SyncProducts;
