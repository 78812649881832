import styled from 'styled-components';
import COLORS from 'shared/constants/colors';

const PageWrapper = styled.div`
  min-height: calc(100vh - 180px);
  padding: 0 15px;
  background-color: ${COLORS.LIGHT_GREY};
`;

const CatalogMainWrapper = styled.div`
  padding: 25px 0;
  min-height: 80vh;
`;

const CatalogTopBanner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  column-gap: 30px;
  row-gap: 30px;
  align-items: end;
  position: relative;
  @media (min-width: 1200px) {
    grid-template-columns: 0.3fr 0.7fr;
  }
`;

const PromosWrapper = styled.div`
  display: flex;
`;

const CardSection = styled.div`
  display: grid;
  column-gap: 30px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const CategoryWrapper = styled.div``;

const CategoryCard = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  column-gap: 30px;
  row-gap: 30px;
  margin: 1em 0;
`;
const CategoryTitle = styled.h5`
  text-transform: uppercase;
  color: #18191f;
  font-family: 'Inter700';
  text-align: center;
  margin: 2.5em 0;
  font-size: 16px;
`;
const StyleWrapper = styled.div``;
const StyleCard = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  row-gap: 30px;
  column-gap: 30px;
  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
const StyleTitle = styled.h5`
  text-transform: uppercase;
  color: #18191f;
  font-family: 'Inter700';
  text-align: center;
  margin: 2.5em 0;
  font-size: 16px;
`;
export {
  PageWrapper,
  CatalogMainWrapper,
  CatalogTopBanner,
  PromosWrapper,
  CardSection,
  CategoryWrapper,
  CategoryCard,
  CategoryTitle,
  StyleWrapper,
  StyleCard,
  StyleTitle,
};
