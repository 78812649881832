import styled from 'styled-components';

const PaginationContainer = styled.div`
  .MuiPaginationItem-page {
    font-family: 'Inter600';
    color: #161616;
  }
  .MuiPaginationItem-textPrimary.Mui-selected {
    background-color: #1594db;
    font-family: 'Inter600';
  }
  .MuiPaginationItem-textPrimary.Mui-selected:hover,
  .MuiPaginationItem-textPrimary.Mui-selected.Mui-focusVisible {
    background-color: #1594db;
    opacity: 0.5;
    font-family: 'Inter600';
  }
`;

export { PaginationContainer };
