import styled from 'styled-components';
import COLORS from 'shared/constants/colors';

const Container = styled.div`
  max-width: 800px;
  width: 88vw;

  @media (max-width: 800px) {
    width: 100%;
  }
  form {
    width: 100%;
    max-width: 475px;
    margin: 0 auto;
  }
`;

const Body = styled.div`
  padding: 0px 20px 20px;
  .MuiTypography-body1 {
    font-family: 'Inter500';
    font-size: 16px;
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: #1594db;
  }
`;

const CardField = styled.div`
  > div {
    margin-bottom: 15px;
  }
`;

const PayableAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1594db;
  color: #fff;
  padding: 20px 15px;
  border-radius: 5px 5px 0 0;
  h4,
  h2 {
    margin: 0;
  }
`;
const Footer = styled.div`
  button {
    width: 100%;
    background-color: ${COLORS.BLUE};
    color: ${COLORS.WHITE};
    border: none;
    height: 40px;
    font-weight: bold;
    margin-top: 25px;
    border-radius: 3px;
    font-size: 18px;
    cursor: pointer;
    :hover {
      background-color: ${COLORS.BLUE};
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
  }
`;

const Cvv = styled.div`
  display: flex;
  grid-gap: 15px;
`;
const PaymentOption = styled.div`
  width: 100%;
  display: flex;

  label {
    /* color: ${COLORS.BODY_TEXT}; */
    font-weight: bold;
    margin: 15px 0;
    font-size: 20px;
    flex: 1;
  }
  @media (max-width: 560px) {
    flex-direction: column;
    margin-bottom: 10px;
    label {
      margin: 10px 0 0 0;
    }
  }
`;

const SavedCard = styled.div`
  display: flex;
  justify-content: space-between;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  flex: 1;
  img {
    height: 50px;
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    h3 {
      margin-top: 0;
      margin-bottom: 6px;
    }
    span {
      color: ${COLORS.BODY_TEXT};
    }
  }
`;

const CardWrapper = styled.div`
  display: 'flex';
  align-items: center;
`;

const DeleteCard = styled.div`
  margin-left: 10px;
`;
const StripeImage = styled.div`
  img {
    width: 70%;
  }
  margin-top: 15px;
  display: flex;
  justify-content: center;
  border-radius: 3px;
`;

export {
  Container,
  PayableAmount,
  CardField,
  Footer,
  Cvv,
  PaymentOption,
  SavedCard,
  Body,
  DeleteCard,
  CardWrapper,
  StripeImage,
};
