// import React from 'react';
// import Etsa from 'assets/images/etsy.png';
// import Shopify from 'assets/images/shopify.png';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import {
//   Container,
//   Wrapper,
//   IntegrationSection,
//   KeyFeatures,
// } from 'styles/pages/home/integration';

// const Integration = ({ data = {} }) => {
//   const { components } = data;

//   const { BackgroundColor, Title: title, Cards } = components || {};

//   return (
//     <Container id='key-features' style={{ backgroundColor: BackgroundColor }}>
//       <Wrapper>
//         <IntegrationSection>
//           <h4>We integrate seamlessly with</h4>
//           <img src={Etsa} alt='Etsa' />
//           <img src={Shopify} alt='Shopify' />
//         </IntegrationSection>
//         <KeyFeatures>
//           <h2>{title}</h2>
//           <div>
//             {Cards?.map((c) => (
//               <p key={c?.CardTitle}>
//                 <CheckCircleIcon />
//                 {c?.CardTitle}
//               </p>
//             ))}
//           </div>
//         </KeyFeatures>
//       </Wrapper>
//     </Container>
//   );
// };

// export default Integration;

import ShopifyLogo from 'assets/images/shopify.png';
import Etsy from 'assets/images/etsy.png';
import BigCommerceLogo from 'assets/images/big-commerce-lg-logo.png';
import WooCommerceLogo from 'assets/images/woo-commerce.png';
import BigCartelLogo from 'assets/images/big-cartel-logo.png';
import { Container, Wrapper } from 'styles/pages/home/integration';

const Integration = () => (
  <Container>
    <Wrapper>
      <div className='textSection'>
        <h2 className='heading'>We Integrate Seamlessly with</h2>
      </div>
      <div className='logoSection'>
        <div>
          <img src={ShopifyLogo} alt='logo' />
          <img src={Etsy} alt='logo' />
          <img src={WooCommerceLogo} alt='logo' />
          <img src={BigCartelLogo} alt='logo' />
          <img src={BigCommerceLogo} alt='logo' />
        </div>
      </div>
    </Wrapper>
  </Container>
);

export default Integration;
