import styled from 'styled-components';
const Container = styled.div`
  width: 100%;
  .pointer {
    cursor: pointer;
    .MuiStepLabel-root.Mui-disabled {
      cursor: inherit;
    }
  }
  .MuiPaper-root {
    background-color: transparent;
  }
  .MuiStepConnector-alternativeLabel {
    top: 24px;

    .MuiStepConnector-lineHorizontal {
      border-top-style: dotted;
      border-top-width: 8px;
    }
    .MuiStepConnector-line {
      border-color: #c4c4c4;
    }
  }
  .MuiStepLabel-label {
    color: #18191f66;
    font-family: 'Inter500';
  }
  .MuiStepLabel-label.MuiStepLabel-active {
    color: #000000;
    font-family: 'Inter500';
  }
  .MuiStepLabel-label.MuiStepLabel-completed {
    opacity: 0.4;
  }

  .MuiStepConnector-active,
  .MuiStepConnector-completed {
    .MuiStepConnector-lineHorizontal {
      opacity: 0.4;
    }
    .MuiStepConnector-line {
      border-color: #ffd363;
    }
  }
`;

export { Container };
