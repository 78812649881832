import styled from 'styled-components';

const Container = styled.div`
  padding: 15px;

  Button {
    width: 100%;
    border-radius: 6px;
    font-size: 16px;
    text-transform: capitalize;
    color: #ffffff;
    background-color: #1594db;
    margin-top: 20px;
    font-family: 'Inter500';
    :hover {
      background-color: #1594db;
    }
  }
  > h2 {
    color: inherit;
    font-size: 16px;
    color: #1594db;
    font-family: 'Inter600';
  }
  > h5 {
    color: red;
    padding: 5px 0;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 0;
  }
`;

const Information = styled.div`
  display: grid;
  grid-gap: 20px;
  position: relative;
  max-width: 402px;
  width: 100%;
  @media (min-width: 665px) {
    grid-template-columns: 1fr;
    max-width: 100%;
  }
`;
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;
const ImageSection = styled.div`
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
`;

const Flags = styled.div`
  h1 {
    color: #757575;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    margin: 0;
  }
`;

const FlagsWrapper = styled.div`
  flex-direction: row;
  gap: 18px;
  display: flex;
  margin-top: 10px;
`;

const FlagImage = styled.img`
  width: 50px;
  border: ${(props) => (props?.selected ? '1px solid #1594db' : 'none')};
  padding: ${(props) => (props?.selected ? '5px' : '0px')};
  border-radius: ${(props) => (props?.selected ? '4px' : '0px')};
  cursor: pointer;
`;

export {
  Container,
  Information,
  Wrapper,
  ImageSection,
  Flags,
  FlagsWrapper,
  FlagImage,
};
