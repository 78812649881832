import styled from 'styled-components';

const TableContainer = styled.div`
  margin: 30px 0;
  box-shadow: 4px 4px 10px #d9d9d9, -4px -4px 10px #fff;
  overflow-x: auto;
  border-radius: 10px;
  .MuiTableCell-stickyHeader {
    color: #1594db;
    font-size: 14px;
    font-family: Inter600;
    background-color: #fff;
  }
  .MuiTableCell-body {
    padding: 13px 16px;
    font-size: 14px;
    font-family: Inter400;
  }
`;

export { TableContainer };
